import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { setLoaderVisibility } from '../../../redux';
import { CSM_COLUMNS, CSM, ROLES } from '../../../constants';
import { usePagination, useDebounce } from '../../../hooks';
import { popup, getAllCSM } from '../../../utils';
import { Table } from '../../shared';
import ActivateCSMModal from './ActivateCSMModal';
import { OptionIcon } from '../../../assets/svg/OptionIcon';
import { useSelector } from 'react-redux';

export const CsmListing = () => {
    const dispatch = useDispatch();
    const role = useSelector((s) => s.user.role);
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const pagination = usePagination(response.data.length, response.count);

    const debouncedApiCall = useDebounce(() => {
        getAllCSM({
            limit: pagination.limit,
            offset: pagination.offset,
            search: search,
            filter: { status: true },
        })
            .then((res) => {
                if (res?.data) setResponse({ data: res.data, count: res.count });
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search]);

    return (
        <>
            <AdminPageHeader
                heading={CSM.PAGE_HEADING}
                buttonText={CSM.BUTTON_TEXT}
                searchBarPlaceholder={CSM.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />
            <Table
                columns={CSM_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={CSM.PAGE_NAME}
                stickyColumns={['CSM Name']}
                debouncedApiCall={debouncedApiCall}
            />
            {showModal && (
                <ActivateCSMModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    response={response}
                    setResponse={setResponse}
                />
            )}
            {[ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(role) && (
                <span
                    id='action-btn'
                    title='Activate CSMs'
                    aria-label='Activate CSMs'
                    onClick={() => setShowModal(true)}
                    className={`unhide-hc-btn`}
                    style={{
                        marginTop: ` ${pagination.dataCount > 0 ? '6rem' : '4rem'}`,
                    }}
                >
                    <OptionIcon />
                </span>
            )}
        </>
    );
};
