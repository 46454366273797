import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedSafetyMeetings } from '../../../redux';
import { ROLES } from '../../../constants';

const SelectAllHeaderCheckbox = ({ data }) => {
    const checkRef = useRef(null);
    const dispatch = useDispatch();
    const selectedSafetyMeetings = useSelector((s) => s.safetyMeetings.selectedSafetyMeetings);
    const role = useSelector((s) => s?.user?.role);
    const isAllowed = role !== ROLES.SALES_AGENT;

    const onChangeHandler = (e) => {
        if (e.target.checked) {
            dispatch(
                setSelectedSafetyMeetings(
                    data.map((item) => ({
                        id: item.id,
                        s3_key: item.s3_key,
                        file_name: item.file_name,
                    })),
                ),
            );
        } else {
            dispatch(setSelectedSafetyMeetings([]));
        }
    };

    useEffect(() => {
        if (!checkRef.current) return;

        if (selectedSafetyMeetings.length === data.length && data.length !== 0) {
            // All selected
            checkRef.current.indeterminate = false;
            checkRef.current.checked = true;
        } else if (selectedSafetyMeetings.length === 0) {
            // None selected
            checkRef.current.indeterminate = false;
            checkRef.current.checked = false;
        } else {
            // Partially selected
            checkRef.current.indeterminate = true;
            checkRef.current.checked = false;
        }
    }, [selectedSafetyMeetings, data.length]);

    if (!isAllowed) return null;

    return (
        <div className='d-flex justify-content-start ms-1'>
            <span
                className='d-flex  align-items-center'
                title={!checkRef?.current?.checked ? 'Select All' : 'Deselect All'}
                aria-label={'Select or deselect safety meetings'}
            >
                <input
                    type='checkbox'
                    id='select-hc'
                    ref={checkRef}
                    className='cursor-pointer'
                    onChange={(e) => onChangeHandler(e)}
                />
            </span>
        </div>
    );
};

export default SelectAllHeaderCheckbox;
