import React from 'react';

const GreenPlus = ({ className, stroke, size }) => {
    return (
        <svg
            className={className}
            width={size || 16}
            height={size || 16}
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8 3.33325V12.6666'
                stroke={stroke || '#17B1A4'}
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.33325 8H12.6666'
                stroke={stroke || '#17B1A4'}
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default GreenPlus;
