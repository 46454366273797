import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Edit } from '../../../assets/svg';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../constants';

export const EditButton = ({ id, navigateTo }) => {
    const navigate = useNavigate();
    const role = useSelector((s) => s.user.role);
    const showButton = [
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.CSM,
        ROLES.CONTENT_MANAGER,
        ROLES.SALES_AGENT,
    ].includes(role);

    return showButton ? (
        <button
            className='border-0 p-0 bg-white'
            onClick={() => {
                navigate(navigateTo, {
                    state: {
                        id: id,
                    },
                });
            }}
            id='action-btn'
            title='Edit'
        >
            <Edit />
        </button>
    ) : (
        '-'
    );
};
EditButton.propTypes = {
    id: PropTypes.string,
    navigateTo: PropTypes.string,
};
