import React, { useEffect, useState } from 'react';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { DIGITAL_SAFETY_LIBRARY, DIGITAL_SAFETY_LIBRARY_COLUMNS, ROLES } from '../../../constants';
import { Table } from '../../shared';
import { useDebounce, usePagination } from '../../../hooks';
import { UploadDigitalDelivery } from '../../shared/Modal/UploadDigitalDelivery';
import { getAllDigitalDelivery } from '../../../utils';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { useSelector } from 'react-redux';

export const DigitalSafetyLibrary = () => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({
        data: [],
        count: 1,
    });
    const pagination = usePagination(response.data.length, response.count);
    const [search, setSearch] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const role = useSelector((s) => s.user.role);

    const debouncedApiCall = useDebounce(async () => {
        try {
            const res = await getAllDigitalDelivery({
                limit: pagination.limit,
                offset: pagination.offset,
                search: search,
            });
            if (res?.data) setResponse({ data: res.data, count: res.count });
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
        }
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search]);

    return (
        <>
            <AdminPageHeader
                showAddButton={role !== ROLES.SALES_AGENT}
                heading={DIGITAL_SAFETY_LIBRARY.PAGE_HEADING}
                buttonText={DIGITAL_SAFETY_LIBRARY.ADD_BUTTON_TEXT}
                handleAddClick={() => {
                    setShowAddModal(true);
                }}
                AddModal={
                    showAddModal ? (
                        <UploadDigitalDelivery
                            showAddModal={showAddModal}
                            setShowAddModal={setShowAddModal}
                            debouncedApiCall={debouncedApiCall}
                        />
                    ) : (
                        ''
                    )
                }
                backbutton
                showSearchBar
                searchBarPlaceholder={DIGITAL_SAFETY_LIBRARY.FILE_NAME_SEARCH}
                count={pagination.dataCount}
                setSearch={setSearch}
            />

            <Table
                columns={DIGITAL_SAFETY_LIBRARY_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={DIGITAL_SAFETY_LIBRARY.PAGE_HEADING}
                debouncedApiCall={{ response, setResponse, refetch: debouncedApiCall }}
                stickyColumns={['File Name']}
            />
        </>
    );
};
