import React from 'react';
import { DocImage, FeedbackCross } from '../../../assets/svg';

const SafetyMeetingFilesListView = ({ selectedFiles, filesArray, handleRemoveFile, resetForm }) => {
    return filesArray.length > 0 ? (
        <div className='d-flex align-items-center file-upload position-relative'>
            {filesArray.map((file, index) => (
                <React.Fragment key={index}>
                    <div
                        key={index}
                        className='d-flex align-items-start flex-column position-relative'
                        title={`${file.file.name}`}
                    >
                        <DocImage height={56} width={56} />
                        {file.file.name.length < 8 ? (
                            <span title={file.file.name} className='mt-2'>
                                {file.file.name}
                            </span>
                        ) : (
                            <span className='truncate-text mt-2' title={file.file.name}>
                                {file.file.name.slice(0, 4) + '..' + file.file.name.split('.')[1]}
                            </span>
                        )}
                        <span
                            className='position-absolute'
                            style={{
                                top: '-12px',
                                right: '-8px',
                            }}
                            id='action-btn'
                            onClick={() => handleRemoveFile(index)}
                            title='Remove'
                        >
                            <FeedbackCross />
                        </span>
                    </div>
                </React.Fragment>
            ))}
        </div>
    ) : selectedFiles.length > 0 ? (
        <div className='d-flex align-items-center file-upload position-relative'>
            {selectedFiles.map((file, index) => (
                <React.Fragment key={index}>
                    <div
                        key={index}
                        className='d-flex align-items-start flex-column position-relative'
                        title={`${file.name}`}
                    >
                        <DocImage height={56} width={56} />
                        {file?.name.length < 8 ? (
                            <span title={file?.name} className='mt-2'>
                                {file?.name}
                            </span>
                        ) : (
                            <span className='truncate-text mt-2' title={file?.name}>
                                {file?.name.slice(0, 4) + '..' + file?.name.split('.')[1]}
                            </span>
                        )}
                        <span
                            className='position-absolute'
                            style={{
                                top: '-12px',
                                right: '-8px',
                            }}
                            id='action-btn'
                            onClick={() => {
                                handleRemoveFile(index);
                                resetForm();
                            }}
                            title='Remove'
                        >
                            <FeedbackCross />
                        </span>
                    </div>
                </React.Fragment>
            ))}
        </div>
    ) : (
        <></>
    );
};

export default SafetyMeetingFilesListView;
