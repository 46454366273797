import * as React from 'react';

const SpinnerIcon = ({ size, ...props }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 200 200'
        height={size || 25}
        width={size || 25}
        {...props}
    >
        <linearGradient id='a'>
            <stop className='svg__stop-color' offset={0} stopColor='#128C82' stopOpacity={0} />
            <stop className='svg__stop-color' offset={1} stopColor='#128C82' />
        </linearGradient>
        <circle
            className='svg_strokeWidth'
            fill='none'
            stroke='url(#a)'
            strokeWidth={30}
            strokeLinecap='round'
            strokeDasharray='0 44 0 44 0 44 0 44 0 360'
            cx={100}
            cy={100}
            r={70}
            // eslint-disable-next-line react/no-unknown-property
            transform-origin='center'
        >
            <animateTransform
                type='rotate'
                attributeName='transform'
                calcMode='discrete'
                dur={1.5}
                values='360;324;288;252;216;180;144;108;72;36'
                repeatCount='indefinite'
            />
        </circle>
    </svg>
);
export default SpinnerIcon;
