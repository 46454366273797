import { useState } from 'react';

export const usePagination = (dataLength, dataCount, perPageCount = 10) => {
    if (perPageCount && ![10, 20, 50, 100].includes(perPageCount)) {
        throw new Error('Please provide a valid perPageCount(10,20,50,100)');
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(perPageCount || 10);
    const totalPages = Math.ceil(dataCount / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const updateItemsPerPage = (newItemsPerPage) => {
        const currentIndex = (currentPage - 1) * itemsPerPage;
        const newPage = Math.floor(currentIndex / newItemsPerPage) + 1;
        setCurrentPage(newPage);
        setItemsPerPage(newItemsPerPage);
    };

    return {
        handlePrevPage,
        handleNextPage,
        dataLength,
        dataCount,
        currentPage,
        itemsPerPage,
        updateItemsPerPage,
        totalPages,
        limit: itemsPerPage,
        offset: (currentPage - 1) * itemsPerPage + 1,
        setCurrentPage,
    };
};
