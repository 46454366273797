import React, { useEffect, useRef } from 'react';
import { CloseIcon, SearchIcon, SpinnerIcon } from '../../../assets/svg';

const InputWithSearch = ({
    search,
    setSearch,
    placeholder = 'Search...',
    className,
    onChange,
    onClear,
    mainStyle,
    isLoading = false,
}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    return (
        <div
            className={`custom-search-box d-flex align-items-center justify-content-start ${className}`}
            style={mainStyle}
        >
            <button className='bg-white p-0 border-0 d-flex align-items-center'>
                <SearchIcon />
            </button>
            {!!search && !isLoading && (
                <button
                    className=' position-absolute border-0 bg-transparent text-danger'
                    onClick={onClear ? onClear : () => setSearch('')}
                    title='Clear Search'
                    style={{ cursor: 'pointer', right: '0.75rem' }}
                >
                    <CloseIcon size={12} />
                </button>
            )}
            {isLoading && (
                <span className=' position-absolute' style={{ right: '0.75rem' }}>
                    <SpinnerIcon />
                </span>
            )}
            <input
                ref={inputRef}
                type='text'
                maxLength={40}
                className='search-area border-0 w-100 py-1 pe-4'
                placeholder={placeholder}
                value={search}
                onChange={onChange ? onChange : (e) => setSearch(e.target.value)}
                onKeyDown={(e) =>
                    e.key === 'Escape' ? (onClear ? onClear(e) : setSearch('')) : null
                }
            />
        </div>
    );
};

export default InputWithSearch;
