import { useCallback, useState } from 'react';
import useWindowEvent from './useWindowEvent';
import PropTypes from 'prop-types';

const useIsScrollable = (ref) => {
    const [isScrollableHeight, setIsScrollableHeight] = useState(false);
    const [isScrollableWidth, setIsScrollableWidth] = useState(false);

    const checkScrollable = useCallback(() => {
        if (ref?.current) {
            const hasScrollHeight = ref.current.scrollHeight > ref.current.clientHeight;
            const hasScrollWidth = ref.current.scrollWidth > ref.current.clientWidth;
            setIsScrollableHeight(hasScrollHeight);
            setIsScrollableWidth(hasScrollWidth);
        }
    }, [ref]);

    useWindowEvent('resize', checkScrollable);

    return { isScrollableHeight, isScrollableWidth };
};

useIsScrollable.propTypes = {
    ref: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
};

export default useIsScrollable;
