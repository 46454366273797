import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';

const createCustomer = ({ data, headers = {} }) => {
    const url = API.USER;
    return fetchData({ body: { role_type: 'user', ...data }, method: METHODS.POST, url, headers });
};

const customerLogin = ({ data }) => {
    const url = API.CUSTOMER_LOGIN;
    return fetchData({ body: data, method: METHODS.POST, url });
};

const getAllCustomer = ({ offset, limit, search, id = '', role = '' }) => {
    let url = `${API.CUSTOMER_ENDPOINT}?offset=${offset}&limit=${limit}&id=${id}&role=${role}`;
    if (search) url = `${url}&search=${encodeURIComponent(search)}`;
    return fetchData({ method: METHODS.GET, url });
};

const getHiringClients = ({ account_id, platform_id, offset, limit, search }) => {
    let url = `${API.HIRING_CLIENTS}?account_id=${account_id}`;
    if (search) {
        url = `${url}&search=${encodeURIComponent(search)}`;
    }
    if (platform_id) {
        url = `${url}&platform_id=${platform_id}`;
    }
    if (offset && limit) {
        url = `${url}&offset=${offset}&limit=${limit}`;
    }
    return fetchData({ method: METHODS.GET, url });
};

const getCustomerByID = ({ customerID, fetchPlatform, fetchHiringClient, fetchCSM }) => {
    let url = `${API.CUSTOMER_ENDPOINT}/${customerID}?platform=${fetchPlatform}&hiringClient=${fetchHiringClient}&csm=${fetchCSM}`;
    return fetchData({ method: METHODS.GET, url });
};

const getScoreCardDetails = ({ accountId, platformID, offset, limit, search }) => {
    let url = `${API.SCORECARD}?account_id=${accountId}&offset=${offset}&limit=${limit}`;
    if (platformID) url = `${url}&platform_id=${platformID}`;
    if (search) url = `${url}&search=${encodeURIComponent(search)}`;
    return fetchData({ method: METHODS.GET, url });
};

const createCustomerFeedback = ({ data }) => {
    const url = API.CUSTOMER_FEEDBACK;
    return fetchData({ body: data, method: METHODS.POST, url });
};
const getAllPrimaryUser = ({ offset, limit, search }) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
    };
    const url = constructURL(API.USER, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

const getAllUser = ({ offset, limit, search }) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        filter: encodeURIComponent(JSON.stringify({ role: 'user' })),
    };
    const url = constructURL(API.USER, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

const getUserById = ({ id }) => {
    const url = `${API.USER}/${id}`;
    return fetchData({ method: METHODS.GET, url });
};

const createAccountUser = (body) => {
    const url = `${API.ACCOUNT_USER}`;
    return fetchData({ body: body, method: METHODS.POST, url });
};

const deleteAccountUser = ({ data }) => {
    const url = `${API.ACCOUNT_USER}`;
    return fetchData({ body: data, method: METHODS.DELETE, url });
};

const updateUser = ({ accountId, data }) => {
    const url = `${API.ACCOUNT_USER}/${accountId}`;
    return fetchData({ body: data, method: METHODS.PUT, url });
};

const getAllAccounts = ({ offset, limit, search, id = '', role = '' }) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        id: id,
        role: role,
    };

    const url = constructURL(API.CUSTOMER_ENDPOINT, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

const getAccountByID = ({ id }) => {
    const url = `${API.ACCOUNT}/${id}`;
    return fetchData({ method: METHODS.GET, url });
};

const updatePrimaryUser = ({ id, data }) => {
    const url = `${API.USER}/${id}`;
    return fetchData({ body: data, method: METHODS.PUT, url });
};

const initiateResetPassword = ({ body, headers }) => {
    const url = `${API.USER}${API.RESET_INITIATE}`;
    return fetchData({ body, method: METHODS.POST, url, headers });
};

const validateResetLink = ({ body, headers }) => {
    const url = `${API.USER}${API.VALIDATE_RESET_LINK}`;
    return fetchData({ body, method: METHODS.POST, url, headers });
};

const resetPassword = ({ body, headers }) => {
    const url = `${API.USER}${API.RESET_PASSWORD}`;
    return fetchData({ body, method: METHODS.POST, url, headers });
};

export const sendReport = ({ id }) => {
    const url = `${API.REPORT}/${id}`;
    return fetchData({ method: METHODS.GET, url });
};

export {
    createCustomer,
    customerLogin,
    getAllCustomer,
    getUserById,
    getHiringClients,
    getCustomerByID,
    getScoreCardDetails,
    createCustomerFeedback,
    getAllPrimaryUser,
    getAllAccounts,
    updateUser,
    getAccountByID,
    updatePrimaryUser,
    initiateResetPassword,
    validateResetLink,
    resetPassword,
    createAccountUser,
    deleteAccountUser,
    getAllUser,
};
