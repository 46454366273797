import React, { useCallback, useState } from 'react';
import { Delete, DownloadIcon, Edit, ViewIcon } from '../../../assets/svg';
import { useSelector } from 'react-redux';
import { DIGITAL_SAFETY_LIBRARY, ROLES } from '../../../constants';
import { DeleteConfirmationModal } from '../Modal/DeleteConfirmationModal';
import {
    deleteDigitalDelivery,
    getContentTypeByExtension,
    getFileExtension,
    getFileSignedUrl,
    isDocORExcelExtension,
    popup,
    updateDigitalDelivery,
} from '../../../utils';
import {
    setFileViewModalDetails,
    setLoaderProgress,
    setLoaderVisibility,
    setShowProgress,
} from '../../../redux';
import { useDispatch } from 'react-redux';
import { EditFileNameModal } from '../Modal/EditFileNameModal';

const DigitalSafetyLibraryActions = ({ data, debouncedApiCall }) => {
    const { id, file_name, s3_key } = data;
    const { response, setResponse, refetch } = debouncedApiCall;
    const extension = getFileExtension(s3_key);
    const fileType = getContentTypeByExtension(extension);
    const dispatch = useDispatch();
    const role = useSelector((s) => s.user.role);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const hasPrivileges = [
        ROLES.ADMIN,
        ROLES.CONTENT_MANAGER,
        ROLES.CSM,
        ROLES.SUPER_ADMIN,
    ].includes(role);

    const fetchFileWithProgress = useCallback(async (url) => {
        dispatch(setShowProgress(true));
        const response = await fetch(url); // Fetch the file
        if (!response.ok) throw new Error('Failed to fetch file');

        const contentLength = response.headers.get('content-length');
        if (!contentLength) throw new Error('Unable to determine file size');

        const totalSize = parseInt(contentLength, 10);
        let loadedSize = 0;

        const reader = response.body.getReader();
        const chunks = [];

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }

            // Append the chunk to the list of chunks
            chunks.push(value);
            loadedSize += value.length;

            // Calculate the progress percentage
            const progress = Math.round((loadedSize / totalSize) * 100);

            // Dispatch the progress to the state or UI
            dispatch(setLoaderProgress(progress));
        }

        const blob = new Blob(chunks, { type: fileType });
        const uri = URL.createObjectURL(blob);

        return uri;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const viewHandler = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const { data } = await getFileSignedUrl({ filePath: s3_key });
            if (isDocORExcelExtension(extension)) {
                dispatch(
                    setFileViewModalDetails({
                        openFileViewerModal: true,
                        signedURL: data.url,
                        fileName: file_name,
                    }),
                );
            } else {
                const uri = await fetchFileWithProgress(data.url);
                dispatch(
                    setFileViewModalDetails({
                        openFileViewerModal: true,
                        docs: [{ uri, fileType }],
                        fileName: file_name,
                    }),
                );
            }
        } catch (error) {
            popup('error', error.message ?? 'File Maybe Corrupted');
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const downloadHandler = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const { data } = await getFileSignedUrl({ filePath: s3_key });
            const uri = await fetchFileWithProgress(data.url);
            const link = document.createElement('a');
            link.href = uri;
            link.setAttribute('download', file_name || '');
            link.click();
        } catch (error) {
            popup('error', error.message ?? 'Unable to download the file');
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const editHandler = () => {
        setShowEditModal(true);
    };

    const deleteHandler = async ({ selectedItemId, setIsDeleteModal }) => {
        try {
            dispatch(setLoaderVisibility(true));
            const response = await deleteDigitalDelivery({ id: selectedItemId, data: { s3_key } });
            popup('success', response.message);
            setIsDeleteModal(false);
            refetch();
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const editModalOnSubmit = async (values, { setSubmitting }) => {
        dispatch(setLoaderVisibility(true));
        setSubmitting(true);
        try {
            const { message, status, statusCode } = await updateDigitalDelivery({
                data: { file_name: values.fileName },
                id,
            });
            if (status === 'success' || statusCode === 200) {
                setResponse({
                    data: response?.data.map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                file_name: values.fileName,
                            };
                        } else {
                            return item;
                        }
                    }),
                    count: response.count,
                });
                popup('success', message);
                setShowEditModal(false);
            }
        } catch (error) {
            popup('error', error.message);
        } finally {
            setSubmitting(false);
            dispatch(setLoaderVisibility(false));
        }
    };

    return (
        <div>
            <div className='d-flex gap-2'>
                <span title={DIGITAL_SAFETY_LIBRARY.VIEW} id='action-btn' onClick={viewHandler}>
                    <ViewIcon />
                </span>
                {role !== ROLES.SALES_AGENT && (
                    <span
                        title={DIGITAL_SAFETY_LIBRARY.DOWNLOAD}
                        id='action-btn'
                        onClick={downloadHandler}
                    >
                        <DownloadIcon />
                    </span>
                )}
                {hasPrivileges && (
                    <span title={DIGITAL_SAFETY_LIBRARY.EDIT} id='action-btn' onClick={editHandler}>
                        <Edit />
                    </span>
                )}
                {hasPrivileges && (
                    <span
                        title={DIGITAL_SAFETY_LIBRARY.DELETE}
                        id='action-btn'
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        <Delete />
                    </span>
                )}
            </div>
            {isDeleteModalOpen && hasPrivileges && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModalOpen}
                    setIsDeleteModal={setIsDeleteModalOpen}
                    selectedItemId={id}
                    handleDeleteClick={deleteHandler}
                    deleteButtonName={file_name}
                />
            )}
            {showEditModal && hasPrivileges && (
                <EditFileNameModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    currentFileName={file_name}
                    onSubmit={editModalOnSubmit}
                />
            )}
        </div>
    );
};

export default DigitalSafetyLibraryActions;
