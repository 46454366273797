import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SSCSelect from '../SSCSelect';

export const CustomDropdown = ({
    options,
    handleOptionSelect,
    minWidth = '140px',
    selectedOption,
    selectStyle = {},
}) => {
    const selectOptions = options.map((option) => ({
        id: option.id,
        label: option.name,
        value: option,
    }));
    const dropdownRef = useRef(null);
    return (
        <div ref={dropdownRef} className='customized-dropdown'>
            <div className='dropdown'>
                <SSCSelect
                    mainStyle={{ minWidth: minWidth, zIndex: '198' }}
                    selectStyle={selectStyle}
                    options={selectOptions}
                    value={selectOptions.filter((option) => option.id === selectedOption)}
                    defaultValue={selectOptions[0]}
                    onChange={({ value }) => handleOptionSelect(value)}
                />
            </div>
        </div>
    );
};

CustomDropdown.propTypes = {
    optionsKeyName: PropTypes.string,
    selectedOption: PropTypes.any,
    options: PropTypes.array,
    setIsDropdownOpen: PropTypes.func,
    isDropdownOpen: PropTypes.bool,
    handleOptionSelect: PropTypes.func,
};
