import React, { useEffect, useState } from 'react';
import { SCORECARD_EMAIL_LOGS_COLUMNS, SCORECARD_REPORT } from '../../../constants';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { getAllScoreCardEmailLogs } from '../../../utils/apiServices/scorecardEmailLogAPI';
import { Table } from '../Table';
import { Modal } from 'react-bootstrap';

const EmailReportModal = ({ showModal, setShowModal, accountId }) => {
    const [reportData, setReportData] = useState(undefined);
    const dispatch = useDispatch();
    const pagination = usePagination(reportData?.data?.length, reportData?.count);

    const getEmailReportsByAccountId = useDebounce(async () => {
        try {
            // dispatch(setLoaderVisibility(true));
            const response = await getAllScoreCardEmailLogs({
                offset: pagination.offset,
                limit: pagination.limit,
                account_id: accountId,
            });
            setReportData({ data: response.data, count: response.count });
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 1000);

    //initial stat

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        getEmailReportsByAccountId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, pagination.limit, pagination.offset]);

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
            size='lg'
            dialogClassName='overflow-hidden'
            fullscreen='md-down'
        >
            <Modal.Header closeButton>
                <Modal.Title className='me-2'>
                    {SCORECARD_REPORT.SCORE_CARD_EMAIL_RECORDS.HEADER_HEADING}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='modal-body p-0 pt-4'>
                    {!!reportData && (
                        <Table
                            columns={SCORECARD_EMAIL_LOGS_COLUMNS}
                            data={reportData?.data}
                            pagination={pagination}
                            pageName={SCORECARD_REPORT.SCORE_CARD_EMAIL_RECORDS.HEADER_HEADING}
                            stickyColumns={['Delivery Status']}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EmailReportModal;
