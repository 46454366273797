import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import loaderReducer from './slices/loaderSlice';
import accountsArrayReducer from './slices/accountsArraySlice';
import navigationStateReducer from './slices/navigationStateSlice';
import notificationUpdateReducer from './slices/notificationUpdateSlice';
import setSessionReducer from './slices/sessionSlice';
import userReducer from './slices/userSlice';
import fileViewerModalReducer from './slices/fileViewerModalSlice';
import isSubscriptionExpiryReadReducer from './slices/isSubscriptionExpiryReadSlice';
import themeReducer from './slices/themeSlice';
import safetyMeetingsReducer from './slices/safetyMeetingSlice';
import csmCaseloadReducer from './slices/csmCaseloadSlice';

const allReducers = combineReducers({
    loader: loaderReducer,
    accountArray: accountsArrayReducer,
    navigationState: navigationStateReducer,
    notificationUpdate: notificationUpdateReducer,
    sessionPopup: setSessionReducer,
    user: userReducer,
    fileViewerModal: fileViewerModalReducer,
    isSubscriptionExpiryRead: isSubscriptionExpiryReadReducer,
    theme: themeReducer,
    safetyMeetings: safetyMeetingsReducer,
    csmCaseload: csmCaseloadReducer,
});

const rootReducer = (state, action) => {
    if (action.type.includes('reset/store')) state = undefined;
    return allReducers(state, action);
};

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['accountArray', 'user', 'isSubscriptionExpiryRead'],
    blacklist: ['loader', 'notificationUpdate'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export const persistor = persistStore(store);
