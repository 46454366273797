import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from '../../../constants';
import DashboardContent from './DashboardContent';
import { HiringClientListing } from '../HiringClientListing';
import { DocumentListing } from '../DocumentListing';
import { ScoreCardReport } from '../ScoreCardReport';
import { Notification } from '../../shared';
import { EditProfile } from '../../shared';
import { DigitalSafetyLibraryCustomer } from '../DigitalSafetyLibraryCustomer';
import LegendPage from '../../shared/common/LegendPage';
import SafetyMeetingsCustomer from '../SafetyMeetingsCustomer/SafetyMeetingsCustomer';
import { AllNewsFeedCustomer } from '../NewsFeed/AllNewsFeedCustomer';

const RenderDashboard = (selectedTab) => {
    switch (selectedTab) {
        case ROUTES.CUSTOMER.DASHBOARD:
            return <DashboardContent />;
        case ROUTES.CUSTOMER.DOCUMENTS:
            return <DocumentListing />;
        case ROUTES.CUSTOMER.SCORE_CARD_REPORT:
            return <ScoreCardReport />;
        case ROUTES.CUSTOMER.HIRING_CLIENTS:
            return <HiringClientListing />;
        case ROUTES.CUSTOMER.NOTIFICATION:
            return <Notification />;
        case ROUTES.CUSTOMER.EDIT_PROFILE:
            return <EditProfile />;
        case ROUTES.CUSTOMER.DIGITAL_SAFETY_LIBRARY:
            return <DigitalSafetyLibraryCustomer />;
        case ROUTES.CUSTOMER.LEGEND:
            return <LegendPage />;
        case ROUTES.CUSTOMER.SAFETY_MEETINGS:
            return <SafetyMeetingsCustomer />;
        case ROUTES.CUSTOMER.NEWS_FEEDS:
            return <AllNewsFeedCustomer />;
        default:
            return <DashboardContent />;
    }
};

const ContentBox = ({ currentRoute }) => (
    <div className='col-lg-10 ssc-main-content pb-3'>{RenderDashboard(currentRoute)}</div>
);

ContentBox.propTypes = {
    currentRoute: PropTypes.string.isRequired,
};

export default ContentBox;
