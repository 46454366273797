import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const initialState = {
    selectedSafetyMeetings: [],
    safetyMeetingCustomer: {},
};

const safetyMeetingsSlice = createSlice({
    name: REDUX.slice.SAFETY_MEETING_SLICE,
    initialState,
    reducers: {
        setSelectedSafetyMeetings: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.selectedSafetyMeetings = action.payload;
                return;
            } else {
                console.log('setSelectedSafetyMeetings payload must be an Array');
            }
        },
        addSafetyMeeting: (state, action) => {
            if (typeof action.payload === 'object') {
                const newArray = state.selectedSafetyMeetings || [];
                newArray.push(action.payload);
                state.selectedSafetyMeetings = newArray;
            } else {
                console.log('addSafetyMeeting payload must be an Object');
            }
        },
        removeSafetyMeeting: (state, action) => {
            if (typeof action.payload === 'string') {
                state.selectedSafetyMeetings = state.selectedSafetyMeetings?.filter(
                    (ele) => ele.id !== action.payload,
                );
            } else {
                console.log('removeSafetyMeeting payload must be an String');
            }
        },
        setSafetyMeetingCustomer: (state, action) => {
            if (typeof action.payload === 'object') {
                state.safetyMeetingCustomer = action.payload;
                return;
            } else {
                console.log('setSafetyMeetingCustomer payload must be an Object');
            }
        },
        resetSafetyMeetingStore: (state) => {
            state.selectedSafetyMeetings = [];
            state.safetyMeetingCustomer = {};
        },
    },
});

export const {
    setSelectedSafetyMeetings,
    addSafetyMeeting,
    removeSafetyMeeting,
    resetSafetyMeetingStore,
    setSafetyMeetingCustomer,
} = safetyMeetingsSlice.actions;
export default safetyMeetingsSlice.reducer;
