import React from 'react';
import PropTypes from 'prop-types';
import { DashboardContent } from '../Dashboard/DashboardContent';
import { CsmListing } from '../CsmListing';
import { CustomerListing } from '../CustomerListing';
import { ROUTES } from '../../../constants';
import { DocumentCategoryListing } from '../DocumentCategoryListing';
import { AdminListing } from '../AdminListing';
import { DocumentListing } from '../DocumentListing';
import { DocumentRequestView } from '../DocumentRequest/DocumentRequestView';
import { ScoreCardReportAdmin } from '../ScoreCardReportAdmin';
import { NewsFeed } from '../NewsFeed';
import { Notification } from '../../shared';
import { AdminScoreCardListing } from '../ScoreCardReportAdmin/AdminScoreCardListing';
import { EditProfile } from '../../shared';
import { RegulatoryIssue } from '../RegulatoryIssues';
import { EditCustomer } from '../EditCustomer';
import { CSMHiringClientOrder } from '../EditCustomer/CSMHiringClientOrder';
import { DigitalSafetyLibrary } from '../DigitalSafetyLibrary';
import LegendPage from '../../shared/common/LegendPage';
import SafetyMeetings from '../SafetyMeetings';

const COMPONENT_MAP = {
    [ROUTES.ADMIN.DASHBOARD]: <DashboardContent />,
    [ROUTES.ADMIN.DOCUMENT_CATEGORY]: <DocumentCategoryListing />,
    [ROUTES.ADMIN.DOCUMENTS]: <DocumentListing />,
    [ROUTES.ADMIN.ADMIN]: <AdminListing />,
    [ROUTES.ADMIN.CSM]: <CsmListing />,
    [ROUTES.ADMIN.CUSTOMER]: <CustomerListing />,
    [ROUTES.ADMIN.CUSTOMER_EDIT]: <EditCustomer />,
    [ROUTES.ADMIN.DOCUMENT_REQUEST]: <DocumentRequestView />,
    [ROUTES.ADMIN.ADMIN_SCORE_CARD_LISTING]: <AdminScoreCardListing />,
    [ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN]: <ScoreCardReportAdmin />,
    [ROUTES.ADMIN.NEWS_FEED_ADMIN]: <NewsFeed />,
    [ROUTES.ADMIN.NOTIFICATION]: <Notification />,
    [ROUTES.ADMIN.EDIT_PROFILE]: <EditProfile />,
    [ROUTES.ADMIN.ADMIN_REGULATORY_ISSUE]: <RegulatoryIssue />,
    [ROUTES.ADMIN.CUSTOMER_HIRING_CLIENT_ORDER]: <CSMHiringClientOrder />,
    [ROUTES.ADMIN.DIGITAL_SAFETY_LIBRARY]: <DigitalSafetyLibrary />,
    [ROUTES.ADMIN.LEGEND]: <LegendPage />,
    [ROUTES.ADMIN.SAFETY_MEETINGS]: <SafetyMeetings />,
};

const RenderDashboard = (selectedTab) => COMPONENT_MAP[selectedTab] || <DashboardContent />;

export const ContentBox = ({ currentRoute }) => {
    return <div className='col-lg-10 ssc-main-content'>{RenderDashboard(currentRoute)}</div>;
};

ContentBox.propTypes = {
    currentRoute: PropTypes.string.isRequired,
};
