import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const PopoverMenu = ({
    children,
    icon = '⚙️',
    placement = 'bottom',
    title = '',
    menuClassName = '',
    buttonClassName = '',
}) => {
    const [show, setShow] = useState(false);
    return (
        <Dropdown align={placement}>
            <Dropdown.Toggle
                variant='light'
                id='dropdown-basic'
                onClick={() => setShow(!show)}
                className={buttonClassName}
            >
                <span title={title}>{icon}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className={menuClassName + `${!show ? 'd-none' : ''}`} show={show}>
                {React.Children.map(children, (child, index) => {
                    if (child !== null) {
                        return (
                            <Dropdown.Item as='div' key={index} onClick={() => setShow(false)}>
                                {child}
                            </Dropdown.Item>
                        );
                    }
                    return null;
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default PopoverMenu;
