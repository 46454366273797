import * as React from 'react';
const AssignToUserIcon = ({ size = 18, stroke, fill, strokeWidth, ...props }) => (
    <svg
        width={size}
        height={size}
        stroke={stroke || '#fff'}
        strokeWidth={strokeWidth || 0}
        viewBox='0 0 24 24'
        fill={fill || 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        {/* <path fill='currentColor' d='M0 0h24v24H0z' stroke='currentColor' />
        <path
            d='M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1m-2 14-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9z'
            stroke='currentColor'
        /> */}
        <path fill='currentColor' d='M0 0h24v24H0V0z' stroke='currentColor'></path>
        <path
            d='m18 9-1.41-1.42L10 14.17l-2.59-2.58L6 13l4 4zm1-6h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 0 0-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z'
            stroke='currentColor'
        ></path>
    </svg>
);
export default AssignToUserIcon;
