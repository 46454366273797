import React, { useEffect, useMemo, useState } from 'react';
import { Edit, ViewIcon } from '../../../assets/svg';
import { ROLES, ROUTES } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HideIcon } from '../../../assets/svg/HideIcon';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { popup, updateCSMStatus } from '../../../utils';
import { ConfirmationBox } from '../../shared';

export const CsmProfile = ({ id, status, full_name, debouncedApiCall, hideInactive = false }) => {
    const navigate = useNavigate();
    const role = useSelector((s) => s.user.role);
    const isEdit = useMemo(() => [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(role), [role]);
    const dispatch = useDispatch();
    const [confirm, setConfirm] = React.useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const handleEditClick = () => {
        navigate(ROUTES.ADMIN.EDIT_PROFILE, {
            state: {
                userId: id,
            },
        });
    };

    const toggleStatus = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            await updateCSMStatus({ ids: [id], status: status === 1 ? 0 : 1 });
            popup(
                'success',
                `${full_name}'s account has been ${!status === 1 ? 'activated' : 'deactivated'}`,
            );
            debouncedApiCall();
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
            setConfirm(false);
        }
    };

    useEffect(() => {
        if (confirm) {
            toggleStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirm]);

    return (
        <div className='d-flex align-items-center justify-content-center options-set'>
            <button
                className={`border-0 p-0 bg-white`}
                onClick={() => handleEditClick()}
                id='action-btn'
                title={isEdit ? 'Edit' : 'View'}
            >
                {isEdit ? <Edit /> : <ViewIcon />}
            </button>
            {isEdit && !hideInactive && (
                <button
                    className={`border-0 p-0 bg-white`}
                    onClick={() => setOpenConfirmationBox(true)}
                    id='action-btn'
                    title={'Deactivate'}
                >
                    <HideIcon />
                </button>
            )}
            {openConfirmationBox && (
                <ConfirmationBox
                    setConfirm={setConfirm}
                    openConfirmationBox={openConfirmationBox}
                    setOpenConfirmationBox={setOpenConfirmationBox}
                    customMessage={`Are you sure you want to ${status === 1 ? 'deactivate' : 'activate'} ${full_name}?`}
                />
            )}
        </div>
    );
};
