import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import AppRouter from './AppRouter';
import { ErrorBoundary, FileViewerModal, Loader, SessionPopup } from './components/shared';

function App({ instance }) {
    // isLoaderRequired is false initially
    const isLoaderRequired = useSelector((state) => state.loader.loader);
    const sessionPopup = useSelector((state) => state.sessionPopup);
    const openFileViewerModal = useSelector((state) => state.fileViewerModal.openFileViewerModal);
    const isDarkMode = useSelector((state) => state.theme.isDarkMode);

    // Update the theme on state change
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    return (
        <MsalProvider instance={instance}>
            <ErrorBoundary>
                <AppRouter instance={instance} />
                {isLoaderRequired && <Loader />}
                {sessionPopup && <SessionPopup />}
                {openFileViewerModal && <FileViewerModal />}
            </ErrorBoundary>
        </MsalProvider>
    );
}

export default App;
