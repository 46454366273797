import React, { useEffect, useState } from 'react';
import { CUSTOMER_LIST } from '../../../constants';
import { setSafetyMeetingCustomer } from '../../../redux';
import { useDebounce } from '../../../hooks';
import { getAllCustomer, popup } from '../../../utils';
import { useDispatch } from 'react-redux';
import SSCSelect from '../../shared/SSCSelect';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const SelectCustomerDD = () => {
    const dispatch = useDispatch();
    const [customer, setCustomer] = useState([]);
    const [searchCustomer, setSearchCustomer] = useState('');
    const csmCaseload = useSelector((s) => s.csmCaseload?.csmCaseloadData);

    const handleClose = (data) => {
        if (isEmpty(data)) {
            dispatch(setSafetyMeetingCustomer({}));
            return;
        }

        if (data?.primary_user_id) {
            dispatch(
                setSafetyMeetingCustomer({
                    id: data.id,
                    name: data.name,
                    primaryUserId: data.primary_user_id,
                }),
            );
        } else {
            dispatch(setSafetyMeetingCustomer({}));
            popup('error', 'Client has no primary user');
        }
    };

    const debouncedFetchData = useDebounce(() => {
        getAllCustomer({
            offset: 1,
            limit: 25,
            search: searchCustomer || '',
            id: csmCaseload?.id || '',
            role: csmCaseload?.role_code || '',
        })
            .then((res) => {
                if (res?.data) setCustomer(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCustomer]);

    return (
        <div className='col-6'>
            <SSCSelect
                required
                isClearable
                label={CUSTOMER_LIST.PAGE_HEADING}
                options={customer?.map((customer) => ({ label: customer.name, value: customer }))}
                onChange={(d) => handleClose(d?.value)}
                onInputChange={(v) => {
                    if (!isEmpty(v)) {
                        setSearchCustomer(v);
                    }
                }}
                formatOptionLabel={({ label, value }) =>
                    value?.primary_user_id ? (
                        <div>{label}</div>
                    ) : (
                        <div className='text-danger' title='No primary user'>
                            {label}
                        </div>
                    )
                }
            />
        </div>
    );
};

export default SelectCustomerDD;
