import React from 'react';
import ReactDOM from 'react-dom/client';
import { store, persistor } from './redux';
import { Provider } from 'react-redux';
import App from './App';
import '../src/assets/style/main.css';
import '../src/assets/style/index.css';
// import 'react-select/dist/react-select.css';
import { ToastContainer } from 'react-toastify';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
export const msalInstance = new PublicClientApplication(msalConfig);
export const exportStore = store;

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App instance={msalInstance} />
            <ToastContainer />
        </PersistGate>
    </Provider>,
);
