import React from 'react';
import { IconPlus } from '../../../assets/svg';
import useWindowSize from '../../../hooks/useWindowSize';

export const ResponsiveButton = ({
    breakpoint = 768,
    onClick,
    title,
    icon = <IconPlus />,
    id = 'action-btn',
    customRef = undefined,
}) => {
    const { width } = useWindowSize(customRef);
    return width < breakpoint ? (
        <button type='button' id={id} className='bg-transparent' title={title} onClick={onClick}>
            {icon}
        </button>
    ) : (
        <button
            aria-label={title}
            title={title}
            className='ssc-primary-green-btn-small'
            onClick={onClick}
        >
            {title}
        </button>
    );
};
