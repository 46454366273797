import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setLoaderVisibility } from '../../../redux';
import { SCORECARD_REPORT, BUTTONS, SUCCESS, ERROR, MAX_LENGTH } from '../../../constants';
import { popup, updatePlatform } from '../../../utils';
import { handleNotesChangeFn, handleSaveFn, getHiringClientsById } from './ScoreCardsFunctions';
import { ButtonComponent } from '../../shared';
import { useDebounce } from '../../../hooks';
import SSCSelect from '../../shared/SSCSelect';
import { isEmpty } from 'lodash';

const EditScorecardModal = ({
    accountDetails,
    openEditModal,
    setOpenEditModal,
    selectedData,
    isEdit,
    debouncedApiCall,
    selectedPlatform,
    scorecards,
}) => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedHiringClient, setSelectedHiringClient] = useState(null);
    const [notes, setNotes] = useState('');
    const [hiringClients, setHiringClients] = useState(null);
    const [hcFetching, setHcFetching] = useState(false);
    const [selectedHiringClientGrade, setSelectedHiringClientGrade] = useState(null);

    const handleHiringClientSelect = (hc) => {
        setSearchQuery('');
        setSelectedHiringClient(hc);
        hc?.hiring_client_grade && setSelectedHiringClientGrade(hc.hiring_client_grade);
    };
    const handleNotes = handleNotesChangeFn(setNotes);

    const handleHiringClientApi = async () => {
        try {
            setHcFetching(true);
            await getHiringClientsById({
                account_id: accountDetails?.id || selectedData.account_id,
                platform_id: selectedPlatform.id,
                setHiringClients,
                searchQuery,
                scorecards,
            });
        } catch (error) {
            popup('error', error?.message);
        } finally {
            setHcFetching(false);
        }
    };

    const handleSave = async () => {
        if (selectedHiringClient?.id) {
            const { success, type } = await handleSaveFn({
                isEdit,
                selectedHiringClient,
                selectedPlatform,
                notes,
                selectedData,
                setOpenEditModal,
                debouncedApiCall,
                accountId: accountDetails?.id,
                scorecards,
                selectedHiringClientGrade,
            });
            if (success) {
                dispatch(setLoaderVisibility(true));
                setOpenEditModal(false);

                if (!isEdit && !scorecards.length) {
                    const data = {
                        platform_grade: accountDetails?.grade,
                        account_id: accountDetails?.id,
                    };
                    await updatePlatform({
                        id: accountDetails?.platform_id.toString(),
                        data: data,
                    });
                }
                await debouncedApiCall();
                setTimeout(() => {
                    type === 'create'
                        ? popup('success', SUCCESS.SCORE_CARD_COLUMNS_ADMIN.CREATED)
                        : popup('success', SUCCESS.SCORE_CARD_COLUMNS_ADMIN.UPDATED);
                }, 500);
            }
        } else {
            const err_message = !selectedHiringClient?.id
                ? ERROR.SELECT_HIRING_CLIENT
                : ERROR.UNEXPECTED_ERROR;
            popup('error', err_message);
        }
    };

    const debouncedHCCall = useDebounce(handleHiringClientApi, 500);

    useEffect(() => {
        if (selectedData) {
            const { hiring_client_id, hiring_client_name, notes, hc_grade } = selectedData;
            setSelectedHiringClient({ name: hiring_client_name, id: hiring_client_id });
            setSelectedHiringClientGrade(hc_grade);
            setNotes(notes);
        }
    }, [selectedData]);

    useEffect(() => {
        !isEdit && debouncedHCCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, isEdit]);

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openEditModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openEditModal}
            role='dialog'
            style={{ display: openEditModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='d-flex flex-column gap-4 modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {isEdit ? SCORECARD_REPORT.EDIT_BUTTON : SCORECARD_REPORT.BUTTON_TEXT}
                        </span>
                        <ButtonComponent
                            clickHandler={() => setOpenEditModal(false)}
                            className='btn-close'
                        ></ButtonComponent>
                    </div>
                    <div className='d-flex flex-column gap-3 modal-body p-0 ssc-scorecard-form'>
                        <SSCSelect
                            label={SCORECARD_REPORT.COLUMNS.HIRING_CLIENT.NAME}
                            placeholder={SCORECARD_REPORT.COLUMNS.HIRING_CLIENT.PLACEHOLDER}
                            isLoading={hcFetching}
                            isDisabled={isEdit}
                            isClearable={true}
                            isOptionSelected={(data) =>
                                data?.value?.id === selectedHiringClient?.id
                            }
                            value={
                                isEmpty(selectedHiringClient)
                                    ? null
                                    : {
                                          label: selectedHiringClient?.name,
                                          value: selectedHiringClient,
                                      }
                            }
                            options={hiringClients?.map((hc) => ({ label: hc?.name, value: hc }))}
                            onChange={(data) => handleHiringClientSelect(data?.value)}
                            onInputChange={(v) => {
                                if (!isEmpty(v)) {
                                    setSearchQuery(v.trim());
                                }
                            }}
                            required
                        />
                        <div className=' d-flex align-items-start flex-column'>
                            <label htmlFor=''>{SCORECARD_REPORT.COLUMNS.NOTES.NAME}</label>
                            <textarea
                                onChange={handleNotes}
                                value={notes || ''}
                                name=''
                                id=''
                                cols='20'
                                rows='3'
                                className='add-newsfeed-text w-100'
                                placeholder={SCORECARD_REPORT.COLUMNS.NOTES.PLACEHOLDER}
                            ></textarea>
                        </div>
                        <div className='d-flex align-items-start flex-column'>
                            <label htmlFor=''>{SCORECARD_REPORT.COLUMNS.HIRING_CLIENT_GRADE}</label>
                            <input
                                maxLength={MAX_LENGTH.HIRING_CLIENT_GRADE}
                                type='text'
                                name='grade'
                                onChange={(e) => setSelectedHiringClientGrade(e.target.value)}
                                value={selectedHiringClientGrade || ''}
                                className={`add-grade-hc-text w-100`}
                                placeholder={SCORECARD_REPORT.COLUMNS.GRADE.PLACEHOLDER}
                            />
                        </div>
                    </div>

                    <div className='d-flex align-items-center justify-content-end modal-btn mt-2'>
                        <ButtonComponent
                            clickHandler={() => setOpenEditModal(false)}
                            className='ssc-secondary-white-btn'
                        >
                            {BUTTONS.CANCEL_BUTTON}
                        </ButtonComponent>

                        <ButtonComponent
                            clickHandler={handleSave}
                            className='ssc-primary-green-btn py-0 w-auto'
                            disabled={isEmpty(selectedHiringClient)}
                        >
                            {BUTTONS.SAVE_BUTTON}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditScorecardModal;
EditScorecardModal.propTypes = {
    accountDetails: PropTypes.object,
    openEditModal: PropTypes.bool,
    setOpenEditModal: PropTypes.func,
    selectedItemId: PropTypes.string,
    isEdit: PropTypes.bool,
    setResponse: PropTypes.func,
    debouncedApiCall: PropTypes.func,
    selectedData: PropTypes.object,
    selectedPlatform: PropTypes.object,
    scorecards: PropTypes.array,
};
