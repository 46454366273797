import { useEffect } from 'react';

function useWindowEvent(type, listener, options) {
    useEffect(() => {
        // Initial call
        listener();
        window.addEventListener(type, listener, options);
        return () => window.removeEventListener(type, listener, options);
    }, [type, listener, options]);
}

export default useWindowEvent;
