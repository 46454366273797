/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import PropTypes from 'prop-types';

import { setLoaderVisibility } from '../../../redux';
import { TextArea } from './TextArea';
import { RadioSelector } from './RadioSelector';
import {
    DOCUMENT_REQUEST_FORM,
    DOCUMENT_REQUEST_FORM as DRF,
    MAX_LENGTH,
    SUCCESS,
} from '../../../constants';
import {
    getAllCustomer,
    getCustomerByID,
    getHiringClients,
    createDocumentRequest,
    getAllDocumentCategory,
    validateFiles,
    signedURL,
    popup,
    getFileExtensionFromPath,
    getContentTypeByExtension,
    handleScroll,
} from '../../../utils';
import { FeedbackCross, IconPlus, DocImage } from '../../../assets/svg';
import { useDebounce } from '../../../hooks';
import { isEmpty } from 'lodash';
import SSCSelect from '../../shared/SSCSelect';
import useWindowSize from '../../../hooks/useWindowSize';
import { useSelector } from 'react-redux';

export const RequestBoxContainer = ({
    accountDetails = {},
    submitStatus,
    setSubmitStatus,
    setRes,
    cancelStatus,
    setCancelStatus,
    confirmUpload,
    setOpenConfirmationBox,
    setConfirmUpload,
    setDisable,
}) => {
    const scrollBar = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const dispatch = useDispatch();
    const { width } = useWindowSize();

    // eslint-disable-next-line no-use-before-define
    const [customerData, setCustomerData] = useState([]);
    const [customerFetching, setCustomerFetching] = useState(false);
    const [hiringClient, setHiringClient] = useState([]);
    const [documentCategory, setDocumentCategory] = useState([]);
    const [tpa, setTpa] = useState([]);
    const [note, setNote] = useState('');
    const role = useSelector((s) => s.user.role);

    // set selected data
    const [selectedCustomer, setSelectedCustomer] = useState(
        !isEmpty(accountDetails) ? accountDetails : null,
    );
    const [selectedDocumentCategory, setSelectedDocumentCategory] = useState('');
    const [selectedHiringClient, setSelectedHiringClient] = useState('');
    const [selectedTpa, setSelectedTpa] = useState('');
    const [priority, setPriority] = useState('');
    // set searched key
    const [customerSearch, setCustomerSearch] = useState('');
    const [documentCategorySearch, setDocumentCategorySearch] = useState('');
    const [hiringClientSearch, setHiringClientSearch] = useState('');
    // scrolling
    const [isScrolling, setIsScrolling] = useState(false);
    const csmCaseload = useSelector((s) => s.csmCaseload?.csmCaseloadData);
    const offset = 1;

    // get customer data and recall Api on search
    const debouncedFetchCustomerSearchData = useDebounce(async () => {
        try {
            setCustomerFetching(true);
            const customers = await getAllCustomer({
                offset: offset,
                limit: 25,
                search: customerSearch || '',
                id: csmCaseload?.id || '',
                role: csmCaseload?.role_code || '',
            });
            setCustomerData(customers?.data);
        } catch (error) {
            popup('error', error?.message);
        } finally {
            setCustomerFetching(false);
        }
    }, 500);

    const debouncedDocumentCategoryData = useDebounce(async () => {
        const documents = await getAllDocumentCategory({
            offset: offset,
            limit: 25,
            search: documentCategorySearch || '',
        });
        setDocumentCategory(documents?.data);
    }, 500);

    useEffect(() => {
        debouncedDocumentCategoryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentCategorySearch]);

    useEffect(() => {
        debouncedFetchCustomerSearchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerSearch, csmCaseload?.id]);

    async function fetchCustomerData() {
        setTpa([]);
        const response = await getCustomerByID({
            customerID: selectedCustomer?.id,
            fetchTPA: true,
            fetchHiringClient: true,
            fetchCSM: true,
        });
        if (response?.data) {
            const { platforms } = response.data[0];

            setTpa(platforms);
        }
    }

    useEffect(() => {
        if (selectedCustomer?.id) {
            fetchCustomerData();
        }
        setSelectedHiringClient('');
        setSelectedTpa('');
        setHiringClient([]);
        setTpa([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer?.id]);

    const debouncedFetchHiringClientData = useDebounce(async () => {
        setHiringClient([]);
        const hiringClient = await getHiringClients({
            account_id: selectedCustomer?.id,
            platform_id: selectedTpa.id,
            search: hiringClientSearch,
        });
        setHiringClient(hiringClient?.data);
    }, 500);

    useEffect(() => {
        if (selectedTpa) {
            debouncedFetchHiringClientData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTpa, hiringClientSearch]);

    // if user confirm for upload this useEffect will Work
    useEffect(() => {
        if (confirmUpload === 1) {
            handleSubmitWithUpload();
        } else if (confirmUpload === 2) {
            setSelectedFiles([]);
            setFileNames([]);
            setConfirmUpload(0);
            popup('error', DOCUMENT_REQUEST_FORM.FILE.DECLINED);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmUpload]);

    //  Default Handle submit in case Of no upload
    async function handleSubmit() {
        const documentRequestUuid = uuidV4();
        const requestData = {
            id: documentRequestUuid,
            account_id: selectedCustomer?.id,
            hiring_client_id: selectedHiringClient?.id,
            platform_id: selectedTpa?.id,
            document_category_id: selectedDocumentCategory?.id,
            priority: priority?.toLowerCase(),
            notes: note,
        };
        if (fileNames.length) {
            setOpenConfirmationBox(true);
        } else {
            dispatch(setLoaderVisibility(true));
            const response = await createDocumentRequest({ data: requestData });
            afterSubmission(response);
        }
    }

    // IF Request Has Uploads
    async function handleSubmitWithUpload() {
        dispatch(setLoaderVisibility(true));
        const documentRequestUuid = uuidV4();
        const requestData = {
            id: documentRequestUuid,
            account_id: selectedCustomer?.id,
            hiring_client_id: selectedHiringClient?.id,
            platform_id: selectedTpa?.id,
            document_category_id: selectedDocumentCategory?.id,
            priority: priority?.toLowerCase(),
            notes: note,
        };
        const filePathURL = [];
        let uploadResponse = [];

        const data = {
            account_id: selectedCustomer?.id,
            doc_req_id: documentRequestUuid,
            objects: fileNames,
        };
        const response = await signedURL({ data });
        const urlData = response?.data;
        for (let i = 0; i < urlData?.length; i++) {
            const binaryData = await readFileAsArrayBuffer(selectedFiles[i]);
            const { preSignedUrl, filePath } = urlData[i];
            uploadResponse = await fetch(preSignedUrl, {
                method: 'PUT',
                body: binaryData,
                headers: {
                    'Content-Type': getContentTypeByExtension(getFileExtensionFromPath(filePath)),
                },
            });
            if (uploadResponse?.status === 200) {
                filePathURL.push(filePath);
            } else {
                popup('error', DOCUMENT_REQUEST_FORM.FILE.FILE_UPLOAD_FAILED);
            }
        }
        setFileNames([]);

        const finalResponse = await createDocumentRequest({
            data: { ...requestData, filePathURL },
        });
        afterSubmission(finalResponse);
    }

    const resetForm = () => {
        setSelectedCustomer('');
        setSelectedDocumentCategory('');
        setSelectedTpa('');
        setSelectedHiringClient('');
        setPriority('');
        setNote('');
        setSelectedFiles([]);
    };

    // Default afterSubmission to show error or success and neutralize all states
    const afterSubmission = (response) => {
        if (response?.status === 'success') {
            dispatch(setLoaderVisibility(false));
            setRes(true);
            resetForm();
            setSubmitStatus(false);
            setConfirmUpload(0);
        } else {
            popup('error', response.message);
        }
    };

    useEffect(() => {
        if (submitStatus) {
            if (!selectedCustomer || !selectedDocumentCategory || !priority) {
                popup('error', DOCUMENT_REQUEST_FORM.EMPTY_FIELDS);
                setSubmitStatus(false);
                return;
            }
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priority, selectedCustomer, selectedDocumentCategory, submitStatus]);

    const readFileAsArrayBuffer = async (file) => {
        try {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            return new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const onFileChange = (event) => {
        try {
            const files = event.target.files;
            validateFiles(files, selectedFiles);
            const selectedFilesArray = Array.from(files);
            const selectedFileNames = selectedFilesArray?.map((file) => file.name);
            setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...selectedFilesArray]);
            setFileNames((prevFileNames) => [...prevFileNames, ...selectedFileNames]);
        } catch (error) {
            popup('error', error.message);
        }
    };

    // 1.) To dynamically creates the <input> element,
    // 2.) Don't need the input to persist in the DOM but only need its functionality.
    const openFileExplorer = () => {
        if (selectedFiles.length >= DOCUMENT_REQUEST_FORM.FILE.FILE_LIMIT) {
            popup(SUCCESS.SUCCESS_TOAST, DOCUMENT_REQUEST_FORM.FILE.LIMIT_TEXT);
        } else {
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.onchange = onFileChange;
            input.click();
        }
    };
    const handleRemoveFile = (indexToRemove) => {
        event.preventDefault();
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles?.filter((_, index) => index !== indexToRemove),
        );
        setFileNames((prevFileNames) =>
            prevFileNames?.filter((_, index) => index !== indexToRemove),
        );
    };

    useEffect(() => {
        if (cancelStatus) {
            dispatch(setLoaderVisibility(false));
            resetForm();
            setCancelStatus(false);
        }
        // reset form if its csm or if csm caseload id changes
        if ((isEmpty(csmCaseload) && role === 'csm') || !!csmCaseload?.id) {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelStatus, csmCaseload?.id]);

    useEffect(() => {
        setCustomerSearch('');
    }, [selectedCustomer]);

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    useEffect(() => {
        if (
            !isEmpty(selectedCustomer) &&
            !isEmpty(selectedDocumentCategory) &&
            !isEmpty(priority)
        ) {
            setDisable(false);
        } else {
            setDisable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer, selectedDocumentCategory, priority]);

    return (
        <div className='doc-request-box col-12 pb-2' id='doc-request-box'>
            <form
                action=''
                onScroll={handleScrolling}
                className={` pb-2 ${isScrolling ? 'scrolling' : 'not-scrolling'}`}
            >
                <div className='row'>
                    <div className='d-flex flex-column gap-3 col-12 col-md-6 col-lg-6 col-xl-6'>
                        <SSCSelect
                            required
                            placeholder={DRF.CUSTOMER.PLACEHOLDER}
                            label={DRF.CUSTOMER.LABEL}
                            options={customerData?.map((data) => ({
                                label: data.name,
                                value: data,
                            }))}
                            onChange={(v) => {
                                setSelectedCustomer(!isEmpty(v) ? v?.value : null);
                                setSelectedTpa(null);
                            }}
                            onInputChange={(v) => {
                                if (v) {
                                    setCustomerSearch(v);
                                }
                            }}
                            isClearable={true}
                            value={
                                selectedCustomer
                                    ? { label: selectedCustomer?.name, value: selectedCustomer }
                                    : null
                            }
                            menuPortalTarget={document.getElementById('root')}
                            isLoading={customerFetching}
                            isOptionSelected={(option) => option.value?.id === selectedCustomer?.id}
                        />
                        <SSCSelect
                            required
                            label={DRF.DOCUMENT_CATEGORY.LABEL}
                            placeholder={DRF.DOCUMENT_CATEGORY.PLACEHOLDER}
                            options={documentCategory?.map((data) => ({
                                label: data?.name,
                                value: data,
                            }))}
                            onChange={(v) => setSelectedDocumentCategory(v?.value)}
                            onInputChange={(v) => {
                                if (v) {
                                    setDocumentCategorySearch(v);
                                }
                            }}
                            isClearable={true}
                            value={
                                selectedDocumentCategory
                                    ? {
                                          label: selectedDocumentCategory?.name,
                                          value: selectedDocumentCategory,
                                      }
                                    : null
                            }
                            menuPortalTarget={document.getElementById('root')}
                        />
                        <TextArea
                            label={DRF.NOTES.LABEL}
                            placeholder={DRF.NOTES.PLACEHOLDER}
                            note={note}
                            setNote={setNote}
                            maxLength={MAX_LENGTH.DOCUMENT_NOTES}
                            mainClass='w-100'
                        />
                        <RadioSelector
                            required
                            label={DRF.RADIO.LABEL}
                            options={DRF.RADIO.OPTIONS}
                            selectedData={priority}
                            setSelectedData={setPriority}
                            mainClass={'mb-2'}
                        />

                        <div className='d-flex align-items-center justify-content-start'>
                            {selectedFiles?.length === 0 && (
                                <button
                                    className='ssc-primary-green-btn upload-file-btn mb-3 rounded-1'
                                    style={{ fontSize: '15px' }}
                                    type='button'
                                    onClick={openFileExplorer}
                                    disabled={
                                        selectedFiles.length ===
                                        DOCUMENT_REQUEST_FORM.FILE.FILE_LIMIT
                                    }
                                    title={DRF.FILE.UPLOAD}
                                >
                                    {DRF.FILE.UPLOAD}
                                </button>
                            )}

                            {selectedFiles?.length > 0 && (
                                <div className='d-flex align-items-center file-upload mb-2'>
                                    {selectedFiles?.map((file, index) => (
                                        <div
                                            key={index}
                                            className='d-flex align-items-start flex-column position-relative'
                                        >
                                            <DocImage />
                                            {file.name.length < 10 ? (
                                                <span
                                                    title={file.name}
                                                    className='mt-2'
                                                    style={{ fontSize: '13px' }}
                                                >
                                                    {file.name}
                                                </span>
                                            ) : (
                                                <span
                                                    className='truncate-text mt-2'
                                                    title={file.name}
                                                    style={{ fontSize: '13px' }}
                                                >
                                                    {file.name.slice(0, 5) +
                                                        '...' +
                                                        file.name.split('.')[1]}
                                                </span>
                                            )}
                                            <button
                                                id='action-btn'
                                                className='remove-file-btn position-absolute'
                                                onClick={() => handleRemoveFile(index)}
                                            >
                                                <FeedbackCross />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedFiles?.length > 0 && (
                                <button
                                    className='ssc-primary-green-btn add-upload-file-btn ms-2 mb-4'
                                    type='button'
                                    onClick={openFileExplorer}
                                    disabled={selectedFiles?.length === 5}
                                >
                                    <IconPlus />
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='d-flex flex-column gap-3 col-12 col-md-6 col-lg-6 col-xl-6'>
                        <SSCSelect
                            placeholder={DRF.PLATFORM.PLACEHOLDER}
                            label={DRF.PLATFORM.LABEL}
                            options={tpa?.map((data) => ({
                                label: data?.name,
                                value: data,
                            }))}
                            onChange={(v) => {
                                setSelectedTpa(!isEmpty(v) ? v?.value : null);
                                setSelectedHiringClient(null);
                            }}
                            isClearable={true}
                            menuPlacement={width < 768 ? 'top' : 'auto'}
                            menuPortalTarget={document.getElementById('root')}
                            value={
                                selectedTpa
                                    ? {
                                          label: selectedTpa?.name,
                                          value: selectedTpa,
                                      }
                                    : null
                            }
                            isDisabled={isEmpty(selectedCustomer) || isEmpty(tpa)}
                        />
                        <SSCSelect
                            label={DRF.HIRING_CLIENT.LABEL}
                            placeholder={DRF.HIRING_CLIENT.PLACEHOLDER}
                            options={hiringClient?.map((data) => ({
                                label: data?.name,
                                value: data,
                            }))}
                            onChange={(v) => setSelectedHiringClient(!isEmpty(v) ? v?.value : null)}
                            onInputChange={(v) => {
                                if (v) {
                                    setHiringClientSearch(v);
                                }
                            }}
                            isClearable={true}
                            menuPlacement={width < 768 ? 'top' : 'auto'}
                            menuPortalTarget={document.getElementById('root')}
                            value={
                                selectedHiringClient
                                    ? {
                                          label: selectedHiringClient?.name,
                                          value: selectedHiringClient,
                                      }
                                    : null
                            }
                            isDisabled={isEmpty(selectedTpa)}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

RequestBoxContainer.propTypes = {
    accountDetails: PropTypes.object,
    submitStatus: PropTypes.bool,
    setSubmitStatus: PropTypes.func,
    setRes: PropTypes.func,
    cancelStatus: PropTypes.bool,
    setCancelStatus: PropTypes.func,
    confirmUpload: PropTypes.number,
    setOpenConfirmationBox: PropTypes.func,
    setConfirmUpload: PropTypes.func,
};
