import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDebounce } from '../../../hooks';
import { CUSTOMER_DASHBOARD, ROUTES } from '../../../constants';
import { setLoaderVisibility } from '../../../redux';
import { popup, getAllNewsFeed, handleScroll } from '../../../utils';
import { ActivityToken, CardRightArrow } from '../../../assets/svg';
import { formatDate } from '../../../utils/common/formatHelpers';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ResponsiveButton } from '../../shared';

export const CustomerNewsFeed = () => {
    const accountId = useSelector((state) => state.accountArray.selectedAccount);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scrollBar = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [response, setResponse] = useState({ data: [] });
    const newsFeedBoxRef = useRef(null);

    const debouncedApiCall = useDebounce(() => {
        getAllNewsFeed({ account_id: accountId, filter: { status: 1 } })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) setResponse({ data: res.data, count: res.count });
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);

    return (
        <div className='h-100 w-100 activity-main ssc-shadow' ref={newsFeedBoxRef}>
            <div className='content-header'>
                <span>Activity</span>
                <ResponsiveButton
                    icon={<CardRightArrow />}
                    title={'Archived Activity'}
                    onClick={() => navigate(ROUTES.CUSTOMER.NEWS_FEEDS)}
                    customRef={newsFeedBoxRef}
                    breakpoint={254}
                />
            </div>
            <div
                onScroll={handleScrolling}
                className={`activity-list ${isScrolling ? 'scrolling' : 'not-scrolling'}`}
            >
                <div className='d-flex flex-column align-items-start justify-content-start list-container'>
                    {!isEmpty(response?.data)
                        ? response?.data?.map(({ id, feeds, updated_at }) => (
                              <div key={id} className='d-flex align-items-start list'>
                                  <div className='icon'>
                                      <ActivityToken />
                                  </div>
                                  <div className='text'>
                                      {feeds}
                                      <br />
                                      <div className='small fst-italic'>
                                          last updated : {updated_at ? formatDate(updated_at) : ''}
                                      </div>
                                  </div>
                              </div>
                          ))
                        : CUSTOMER_DASHBOARD.NO_NEWS_FEED}
                </div>
            </div>
        </div>
    );
};
