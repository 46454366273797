import * as React from 'react';
const ReplaceIcon = ({ size, height, width, ...props }) => (
    <svg
        stroke={props.stroke || 'currentColor'}
        fill={props.fill || 'currentColor'}
        strokeWidth={2}
        viewBox='0 0 24 24'
        strokeLinecap='round'
        strokeLinejoin='round'
        height={size || height || 18}
        width={size || width || 18}
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path d='M3 4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1zm12 12a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm6-5V8a2 2 0 0 0-2-2h-6l3 3m0-6-3 3M3 13v3a2 2 0 0 0 2 2h6l-3-3m0 6 3-3' />
    </svg>
);
export default ReplaceIcon;
