import { isEmpty } from 'lodash';
import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';

export const signedDigitalDeliveryFile = ({ signedData }) => {
    const url = `${API.SIGNED_DD_URL}`;
    return fetchData({ body: signedData, method: METHODS.POST, url });
};

export const updateDigitalDelivery = ({ data, id }) => {
    const url = `${API.DIGITAL_DELIVERY}/${id}`;
    return fetchData({ body: data, method: METHODS.PUT, url });
};

export const createDigitalDelivery = ({ createDDData }) => {
    const url = `${API.DIGITAL_DELIVERY}`;
    return fetchData({ body: createDDData, method: METHODS.POST, url });
};

export const getAllDigitalDelivery = ({ offset, limit, search = '', filter }) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        filter: !isEmpty(filter) ? encodeURIComponent(JSON.stringify(filter)) : '',
    };
    const url = constructURL(API.DIGITAL_DELIVERY, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

export const getAllDigitalDeliveryForUser = ({
    id,
    account_id,
    offset,
    limit,
    search = '',
    filter,
}) => {
    const queryParams = {
        account_id: account_id ? account_id : '',
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        filter: !isEmpty(filter) ? encodeURIComponent(JSON.stringify(filter)) : '',
    };
    const url = constructURL(`${API.DIGITAL_DELIVERY}${API.USER}/${id}`, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

export const deleteDigitalDelivery = ({ id, data }) => {
    const url = `${API.DIGITAL_DELIVERY}/${id}`;
    return fetchData({ body: data, method: METHODS.DELETE, url });
};
