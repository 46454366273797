import * as React from 'react';
const SafetyMeetingIcon = ({ width = 18, height = 18, ...props }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 308.000000 412.000000'
        preserveAspectRatio='xMidYMid meet'
        stroke={props.color || 'currentColor'}
        fill={props.fill || 'currentColor'}
        className='safety-meeting-icon'
        {...props}
    >
        <g transform='translate(0.000000,412.000000) scale(0.100000,-0.100000)' stroke='none'>
            <path d='M5 4098 c-3 -7 -4 -929 -3 -2048 l3 -2035 1535 0 1535 0 3 1620 2 1620 -428 427 -427 428 -1108 0 c-877 0 -1109 -3 -1112 -12z m2047 -670 l3 -343 338 -3 337 -2 0 -1365 0 -1365 -1190 0 -1190 0 0 1710 0 1710 850 0 850 0 2 -342z' />
            <path d='M602 3383 l3 -128 468 -3 467 -2 0 130 0 130 -470 0 -471 0 3 -127z' />
            <path d='M1379 2814 l-34 -95 -80 -32 c-113 -45 -166 -79 -247 -155 -156 -148 -232 -312 -245 -534 l-6 -108 -84 0 -83 0 0 -130 0 -130 940 0 940 0 0 130 0 130 -85 0 -85 0 0 63 c0 318 -191 610 -473 725 -40 16 -79 32 -88 35 -11 3 -29 42 -49 101 l-32 96 -128 0 -128 0 -33 -96z m161 -584 l130 0 0 120 0 120 26 -10 c222 -85 354 -270 354 -497 l0 -73 -510 0 -510 0 0 69 c0 93 14 161 50 236 52 110 158 207 276 253 l49 20 3 -119 3 -119 129 0z' />
            <path d='M600 1250 l0 -130 470 0 470 0 0 130 0 130 -470 0 -470 0 0 -130z' />
            <path d='M1800 1250 l0 -130 340 0 340 0 0 130 0 130 -340 0 -340 0 0 -130z' />
            <path d='M607 863 c-4 -3 -7 -62 -7 -130 l0 -123 685 0 686 0 -3 128 -3 127 -676 3 c-371 1 -679 -1 -682 -5z' />
        </g>
    </svg>
);
export default SafetyMeetingIcon;
