import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AdminPageHeader } from '../../Admin/Common/AdminPagesHeader';
import { Table } from '../../shared';
import {
    NAVIGATION_DATA_TYPES,
    SCORECARD_REPORT,
    SCORE_CARD_CUSTOMER_COLUMNS,
} from '../../../constants';
import { useDebounce, usePagination } from '../../../hooks';
import { setLoaderVisibility, setNavigationState } from '../../../redux';
import { popup, getScoreCardDetails } from '../../../utils';

export function ScoreCardReport() {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [response, setResponse] = useState({ data: [], count: 0 });
    const pagination = usePagination(response.data.length, response.count);
    const accountId = useSelector((state) => state.accountArray.selectedAccount);
    const { navigationState } = useSelector((state) => state.navigationState);

    const debouncedApiCall = useDebounce(() => {
        const conditionData = {};
        if (
            navigationState &&
            navigationState?.type === NAVIGATION_DATA_TYPES.PLATFORM &&
            navigationState?.id
        )
            conditionData.platformID = navigationState.id;
        getScoreCardDetails({
            ...conditionData,
            accountId,
            offset: pagination.offset,
            limit: pagination.limit,
            search,
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) setResponse({ data: res.data, count: res.count });
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        if (accountId) {
            !search && dispatch(setLoaderVisibility(true));
            debouncedApiCall();
        }
    }, [pagination.limit, pagination.offset, search, accountId]);

    useEffect(() => {
        return () => {
            dispatch(setNavigationState(null));
        };
    }, []);

    return (
        <>
            <AdminPageHeader
                heading={SCORECARD_REPORT.PAGE_NAME}
                searchBarPlaceholder={SCORECARD_REPORT.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />
            <Table
                columns={SCORE_CARD_CUSTOMER_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={SCORECARD_REPORT.PAGE_NAME}
                stickyColumns={['Hiring Client']}
            />
        </>
    );
}
