import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { SCORECARD_REPORT, BUTTONS, ROUTES, ERROR, SYMBOL } from '../../../constants';
import { getAllCustomerApi } from './ScoreCardsFunctions';
import { ButtonComponent } from '../../shared';
import { useDebounce } from '../../../hooks';
import { getAllScoreCardDetails, popup } from '../../../utils';
import SSCSelect from '../../shared/SSCSelect';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

export const AdminScoreCardCreateModal = ({ openCreateModal, setOpenCreateModal }) => {
    //declarations

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [customers, setCustomers] = useState([]);
    const [customerFetching, setCustomerFetching] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [platforms, setPlatforms] = useState(null);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [isScorecardExists, setIsScorecardExists] = useState(false);
    const csmCaseload = useSelector((s) => s.csmCaseload?.csmCaseloadData);

    const handleCustomerSelect = (customer) => {
        setSearchQuery('');
        setSelectedCustomer(customer);
        setPlatforms(customer?.platforms || []);
        setSelectedPlatform(null);
    };

    const getAllCustomerListApi = async () => {
        try {
            setCustomerFetching(true);
            await getAllCustomerApi({
                setCustomers,
                searchQuery,
                id: csmCaseload?.id || '',
                role: csmCaseload?.role_code || '',
            });
        } catch (error) {
            popup('error', error.message);
        } finally {
            setCustomerFetching(false);
        }
    };

    const handleCreate = () => {
        if (!isScorecardExists) {
            if (
                selectedCustomer?.id &&
                selectedCustomer?.name &&
                selectedPlatform?.id &&
                selectedGrade
            ) {
                navigate(ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN, {
                    state: {
                        accountUserDetails: {
                            id: selectedCustomer.id,
                            name: selectedCustomer.name,
                            platform_id: selectedPlatform.id,
                            grade: selectedGrade,
                        },
                    },
                });
            } else {
                const err_message = !selectedCustomer?.id
                    ? ERROR.SELECT_CUSTOMER
                    : !selectedPlatform?.id
                      ? ERROR.SELECT_PLATFORM
                      : !selectedGrade
                        ? ERROR.SELECT_PLATFORM_GRADE
                        : '';
                popup('error', err_message);
            }
        } else {
            popup('error', ERROR.SCORECARD_ALREADY_EXISTS);
        }
    };

    const debouncedCustomerCall = useDebounce(getAllCustomerListApi, 500);

    const handlePlatformSelect = (platform) => {
        setSelectedPlatform(platform);
    };

    const handleGradeSelect = (grade) => {
        setSelectedGrade(grade);
    };

    const checkIsScorecardExists = async (selectedCustomer, selectedPlatform) => {
        const allScorecards = await getAllScoreCardDetails({
            account_id: selectedCustomer ? selectedCustomer.id : '',
            selectedPlatform: selectedPlatform ? selectedPlatform : {},
            list: true,
        });

        if (allScorecards.data?.length) {
            return setIsScorecardExists(true);
        } else {
            return setIsScorecardExists(false);
        }
    };

    // initial state

    useEffect(() => {
        debouncedCustomerCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (selectedCustomer && selectedPlatform) {
            checkIsScorecardExists(selectedCustomer, selectedPlatform);
        }
    }, [selectedCustomer, selectedPlatform]);

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openCreateModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openCreateModal}
            role='dialog'
            style={{ display: openCreateModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.HEADER_BUTTON}
                        </span>
                        <ButtonComponent
                            clickHandler={() => setOpenCreateModal(false)}
                            className='btn-close'
                        ></ButtonComponent>
                    </div>

                    <div className='d-flex flex-column gap-4'>
                        <div className='d-flex flex-column gap-3 modal-body p-0 mt-3 ssc-scorecard-form'>
                            <SSCSelect
                                label={SCORECARD_REPORT.COLUMNS.CUSTOMER.NAME}
                                placeholder={SCORECARD_REPORT.COLUMNS.CUSTOMER.PLACEHOLDER}
                                options={customers?.map((customer) => ({
                                    label: customer.name,
                                    value: customer,
                                }))}
                                onChange={(data) => handleCustomerSelect(data?.value)}
                                onInputChange={(v) => {
                                    if (!isEmpty(v)) {
                                        setSearchQuery(v.trim());
                                    }
                                }}
                                isClearable
                                isOptionSelected={(data) =>
                                    data?.value?.id === selectedCustomer?.id
                                }
                                isLoading={customerFetching}
                                required
                            />
                            <SSCSelect
                                label={SCORECARD_REPORT.COLUMNS.PLATFORM.NAME}
                                placeholder={SCORECARD_REPORT.COLUMNS.PLATFORM.PLACEHOLDER}
                                onChange={(data) => handlePlatformSelect(data?.value)}
                                isDisabled={isEmpty(selectedCustomer)}
                                isSearchable={false}
                                options={platforms?.map((platform) => ({
                                    label: platform.name,
                                    value: platform,
                                }))}
                                formatOptionLabel={({ label }) => {
                                    return isEmpty(label) ? null : (
                                        <div className='text-txt'>
                                            <span>{label}</span>
                                            <sup>{SYMBOL.REGISTERED}</sup>
                                        </div>
                                    );
                                }}
                                value={
                                    isEmpty(selectedPlatform)
                                        ? null
                                        : {
                                              label: selectedPlatform?.name,
                                              value: selectedPlatform,
                                          }
                                }
                                required
                            />
                            <div className='d-flex align-items-start flex-column'>
                                <label htmlFor=''>
                                    {SCORECARD_REPORT.COLUMNS.PLATFORM_GRADE.NAME}
                                </label>
                                <div className='d-flex w-100 align-items-center justify-content-between mt-2 mx-2'>
                                    <button
                                        className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'red' ? 'grade-btn-active' : ''}`}
                                        onClick={() => handleGradeSelect('red')}
                                    >
                                        <div className='dot-circle red me-2'></div>
                                        <div className='label-color font-16px'>
                                            <span>Red</span>
                                        </div>
                                    </button>
                                    <button
                                        className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'yellow' ? 'grade-btn-active' : ''}`}
                                        onClick={() => handleGradeSelect('yellow')}
                                    >
                                        <div className='dot-circle yellow me-2'></div>
                                        <div className='label-color font-16px'>
                                            <span>Yellow</span>
                                        </div>
                                    </button>
                                    <button
                                        className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'green' ? 'grade-btn-active' : ''}`}
                                        onClick={() => handleGradeSelect('green')}
                                    >
                                        <div className='dot-circle green me-2'></div>
                                        <div className='label-color font-16px'>
                                            <span>Green</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-end modal-btn mt-2'>
                            <ButtonComponent
                                clickHandler={() => setOpenCreateModal(false)}
                                className='ssc-secondary-white-btn'
                            >
                                {BUTTONS.CANCEL_BUTTON}
                            </ButtonComponent>

                            <ButtonComponent
                                clickHandler={handleCreate}
                                disabled={
                                    isEmpty(selectedCustomer) ||
                                    isEmpty(selectedPlatform) ||
                                    isEmpty(selectedGrade)
                                }
                                className='ssc-primary-green-btn py-0 w-auto disabled-hover '
                            >
                                {BUTTONS.CREATE_SCORECARD}
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdminScoreCardCreateModal.propTypes = {
    openCreateModal: PropTypes.bool,
    setOpenCreateModal: PropTypes.func,
};
