import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDebounce, usePagination } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { NEWS_FEED } from '../../../constants';
import { AdminPageHeader } from '../Common/AdminPagesHeader';
import {
    getAllNewsFeed,
    getMaxOrderValue,
    popup,
    updateNewsFeed,
    updateNewsFeedOrder,
} from '../../../utils';
import { NewsFeedOperation } from './NewsFeedOperation';
import { NewsFeedAddEdit, PaginationBar } from '../../shared';
import Toggle from '../../shared/Buttons/Toggle';
import { formatDate } from '../../../utils/common/formatHelpers';
import noDataImage from '../../../assets/img/icons/empty-img-doc.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { IconPlus } from '../../../assets/svg';

export const NewsFeed = () => {
    const location = useLocation();
    const { state } = location;
    const [search, setSearch] = useState(null);
    const [accountDetails] = useState(state?.accountUserDetails);
    const dispatch = useDispatch();
    const [response, setResponse] = useState({ data: [], count: 0 });
    const pagination = usePagination(response?.data.length, response.count);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [order, setOrder] = useState(1);
    const { width } = useWindowSize();
    const smallScreen = width < 640;

    const debouncedApiCall = useDebounce(() => {
        getAllNewsFeed({
            account_id: accountDetails?.id,
            search,
            limit: pagination.limit,
            offset: pagination.offset,
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) {
                    setResponse({ data: res.data, count: res.count });
                    setOrder(getMaxOrderValue(res.data));
                }
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    const toggleHandler = async ({ item, value }) => {
        try {
            const res = await updateNewsFeed({
                id: item?.id,
                data: { status: value, feeds: item?.feeds, order: item?.order },
            });
            if (res.status === 'success' || res.statusCode === 200) {
                setResponse((prevState) => ({
                    data: prevState.data.map((data) => {
                        if (data?.id === item?.id) {
                            return { ...data, status: value };
                        }
                        return data;
                    }),
                    count: prevState.count,
                }));
                popup(
                    'success',
                    value ? 'Feed activated successfully' : 'Feed deactivated successfully',
                );
            }
        } catch (error) {
            popup('error', error.message);
        }
    };

    const onDragEnd = async (e) => {
        if (!e.destination) return;
        if (e.source.index === e.destination.index) return;
        const sorted = reorder(response.data, e.source.index, e.destination.index);
        setResponse((prevState) => ({ data: sorted, count: prevState.count }));
        const data = sorted.map(({ id, order }) => ({ id, order }));
        updateNewsFeedOrder({ data });
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pagination.offset, pagination.limit]);

    return (
        <>
            <AdminPageHeader
                heading={NEWS_FEED.TITLE}
                backbutton
                showSearchBar={true}
                searchBarPlaceholder={'Search feed'}
                setSearch={setSearch}
                count={pagination.dataCount}
            />
            <>
                <div className='ssc-newsfeed-box'>
                    <div className='p-3 name-box-set d-flex align-items-center justify-content-between'>
                        <div>
                            <h6 className='name m-0'>{accountDetails?.name}</h6>
                        </div>
                        {smallScreen ? (
                            <button
                                type='button'
                                id='action-btn-green'
                                title={'Add News Feed Entry'}
                                onClick={() => setOpenCreateModal(true)}
                            >
                                <IconPlus />
                            </button>
                        ) : (
                            <div className='newsfeed-top-btn'>
                                <button
                                    className='ssc-primary-green-btn-small'
                                    type='button'
                                    aria-label='Add News Feed Entry'
                                    onClick={() => setOpenCreateModal(true)}
                                >
                                    Add News Feed Entry
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100 mt-2 pt-2'>
                    <PaginationBar pageName={NEWS_FEED.TITLE} pagination={pagination} />
                </div>
                <div>
                    <div
                        className='ssc-table-area w-100'
                        style={{ height: 'calc(100dvh - 360px)' }}
                    >
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='Table'>
                                {(provided) => (
                                    <table
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className='w-100'
                                    >
                                        <thead>
                                            <tr>
                                                <th className='w-100'>News Feed</th>
                                                <th>Created At</th>
                                                <th>Visibility</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {response?.data.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <td
                                                                className='text-truncate mw-11'
                                                                style={{ padding: '0.75rem' }}
                                                            >
                                                                {item.feeds}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    padding: '0.75rem',
                                                                }}
                                                            >
                                                                {formatDate(item?.created_at)}
                                                            </td>
                                                            <td style={{ padding: '0.75rem' }}>
                                                                <Toggle
                                                                    checked={item.status}
                                                                    title={
                                                                        item.status
                                                                            ? 'Click to Hide'
                                                                            : 'Click to Unhide'
                                                                    }
                                                                    onChange={(value) => {
                                                                        toggleHandler({
                                                                            item,
                                                                            value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ padding: '0.75rem' }}>
                                                                <span className='d-flex align-items-center justify-content-start options-set'>
                                                                    <NewsFeedOperation
                                                                        data={item}
                                                                        debouncedApiCall={
                                                                            debouncedApiCall
                                                                        }
                                                                    />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                {response?.data?.length === 0 && (
                    <div className='pt-5 pt-3 empty-set d-flex align-items-center justify-content-center flex-column'>
                        <img src={noDataImage} alt='img' width={176} height={138} />
                        <p>There are no news feed for this customer</p>
                        <button
                            className='w-auto ssc-primary-green-btn'
                            type='button'
                            data-bs-toggle='modal'
                            data-bs-target='#exampleModal-add'
                            onClick={() => setOpenCreateModal(true)}
                        >
                            Add News Feed Entry
                        </button>
                    </div>
                )}
                {openCreateModal && (
                    <NewsFeedAddEdit
                        openEditModal={openCreateModal}
                        setOpenEditModal={setOpenCreateModal}
                        isEdit={false}
                        debouncedApiCall={debouncedApiCall}
                        accountId={accountDetails?.id}
                        order={order}
                    />
                )}
            </>
        </>
    );
};
