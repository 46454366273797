import React from 'react';
import { useSelector } from 'react-redux';

export const Loader = () => {
    const showProgress = useSelector((s) => s.loader.showProgress);
    const progress = useSelector((s) => s.loader.progress);
    return (
        <div className='loader '>
            {showProgress && (
                <span className='position-absolute' style={{ fontSize: '20px' }}>
                    {Math.min(parseInt(progress), 100)}%
                </span>
            )}
            <div className='spinner-border spinner ' role='status'>
                <span className='sr-only'></span>
            </div>
        </div>
    );
};
