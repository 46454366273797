import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const initialState = {
    loader: false,
    progress: 0,
    showProgress: false,
};

const slice = createSlice({
    name: REDUX.slice.LOADER,
    initialState: initialState,
    reducers: {
        setLoaderVisibility: (state, action) => {
            if (action.payload === false) state.progress = 0;
            if (action.payload === false) state.showProgress = false;
            state.loader = action.payload;
        },
        setLoaderProgress: (state, action) => {
            state.progress = action.payload;
        },
        setShowProgress: (state, action) => {
            state.showProgress = action.payload;
        },
    },
});

export const { setLoaderVisibility, setLoaderProgress, setShowProgress } = slice.actions;
export default slice.reducer;
