import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import YearMonthPicker from './YearMonthPicker';
import SSCSelect from '../SSCSelect';
import { GreenPlus } from '../../../assets/svg';

const YearMonthPopoverPicker = ({
    onChange = () => {},
    className = '',
    selectStyle,
    label,
    title = '',
    placeholder = 'Select Month',
}) => {
    const [selectedDate, setSelectedDate] = useState(null);

    // Options for react-select
    const options = [{ value: '', label: selectedDate || placeholder }];

    const handleYearMonthSelect = (yearMonth) => {
        setSelectedDate(yearMonth); // Format: MM/YYYY
        onChange(yearMonth);
    };

    const popover = (
        <Popover id='year-month-picker-popover' style={{ margin: 0, maxWidth: '230px' }}>
            <YearMonthPicker onSelect={handleYearMonthSelect} selectedDate={selectedDate} />
        </Popover>
    );

    return (
        <div>
            <OverlayTrigger
                trigger='click'
                placement='bottom'
                overlay={popover}
                rootClose // Close popover on outside click
            >
                <div
                    title={title}
                    className={`position-relative d-flex align-items-center justify-content-center ${className}`}
                >
                    <SSCSelect
                        label={label}
                        options={options}
                        className={'d-flex align-items-center justify-content-center gap-2'}
                        selectStyle={selectStyle}
                        value={options[0]}
                        components={{
                            DropdownIndicator: () => null, // Remove dropdown arrow
                            IndicatorSeparator: () => null,
                        }}
                        isDisabled={true}
                    />
                    {selectedDate && (
                        <span
                            className='position-absolute top-1/2 end-0 me-2 text-danger'
                            style={{ cursor: 'pointer', zIndex: 9, rotate: '45deg' }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleYearMonthSelect(null);
                            }}
                        >
                            <GreenPlus stroke={'#FF0000'} size={20} />
                        </span>
                    )}
                </div>
            </OverlayTrigger>
        </div>
    );
};

export default YearMonthPopoverPicker;
