import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerByID, getAllDocumentRequestByAccount, popup } from '../../../utils';
import CSMProfileCard from './CSMProfileCard';
import CustomerTPABox from './CustomerTPABox';
import CustomerDashboardHiringClients from './CustomerDashboardHiringClients';
import CustomerDashboardDocumentRequests from './CustomerDashboardDocumentRequests';
import CustomerFeedback from './CustomerFeedback';
import { setLoaderVisibility, setPlatformLength } from '../../../redux';
import { CustomerNewsFeed } from './CustomerNewsFeed';
import { useDebounce } from '../../../hooks';

// TODO: /* USING DUMMY DATA UNTIL THE API IS INTEGRATED */
const DashboardContent = () => {
    const [csmData, setCsmData] = useState({});
    const [tpaData, setTPAData] = useState([]);
    const [documentRequests, setDocumentRequestData] = useState([]);
    const customerId = useSelector((state) => state.accountArray.selectedAccount);
    const filteredDocumentRequests = documentRequests.filter(
        (req) => req.status === 'requested' || req.status === 'rejected',
    );

    const dispatch = useDispatch();

    const fetchData = useDebounce(async () => {
        try {
            const offset = 1;
            const limit = 21;
            const [customerResponse, docRequestResponse] = await Promise.all([
                getCustomerByID({
                    customerID: customerId,
                    fetchCSM: true,
                    fetchHiringClient: true,
                    fetchPlatform: true,
                }),
                getAllDocumentRequestByAccount({ account_id: customerId, offset, limit }),
            ]).catch((error) => {
                throw error;
            });

            if (customerResponse?.data) {
                setCsmData(customerResponse.data[0].csm[0]);
                setTPAData(customerResponse.data[0].platforms);
                dispatch(setPlatformLength(customerResponse?.data[0]?.platforms?.length ?? 0));
            }
            if (docRequestResponse?.data) {
                setDocumentRequestData(docRequestResponse.data);
            }
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 200);

    useEffect(() => {
        if (customerId) {
            dispatch(setLoaderVisibility(true)); // TODO: Need to fix listing css issue
            fetchData();
        }
        return () => {
            setCsmData({});
            setTPAData([]);
            setDocumentRequestData([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    return (
        <div className='customer-dashboard-view pb-5 pb-md-0'>
            <div className='row main-content'>
                <div className='col-12 col-md-9 custom-col-set'>
                    <div className='h-100'>
                        <div className=' d-flex flex-column justify-content-between gap-4 mb-32 flex-sm-row'>
                            <CSMProfileCard
                                csmName={csmData?.full_name}
                                csmImageLink={csmData?.image}
                            />
                            <CustomerTPABox tpaData={tpaData} />
                        </div>
                        <div className='row main-content-inner'>
                            <CustomerDashboardHiringClients platforms={tpaData} />
                            <CustomerDashboardDocumentRequests
                                documentRequests={filteredDocumentRequests}
                                showViewAllButton={filteredDocumentRequests.length >= 20}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-3 custom-col-set mt-4 mt-md-0'>
                    <div className='h-100 flex-column d-flex gap-3 align-items-center justify-content-between w-100 ssc-activity-bar'>
                        <CustomerNewsFeed />
                        <CustomerFeedback />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardContent;
