import React, { useEffect, useState } from 'react';
import { LeftArrow, RightArrow } from '../../../assets/svg';
import { monthOptions } from '../../../constants';

const YearMonthPicker = ({ onSelect, selectedDate }) => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(null);

    useEffect(() => {
        if (selectedDate) {
            setSelectedMonth(selectedDate);
            setYear(parseInt(selectedDate.split('/')[1]));
        }
        if (selectedDate === null) {
            setSelectedMonth(null);
        }
    }, [selectedDate]);

    const handleMonthClick = (monthIndex) => {
        setSelectedMonth(monthIndex);
        onSelect(monthIndex); // Pass selected year-month to parent
    };

    const handlePreviousYear = () => setYear((prev) => prev - 1);
    const handleNextYear = () => setYear((prev) => prev + 1);

    return (
        <div className='ymp-container'>
            {/* Header */}
            <div className='ymp-header'>
                <button
                    className='ymp-nav-button'
                    title='Previous Year'
                    onClick={handlePreviousYear}
                >
                    <LeftArrow />
                </button>
                <span className='ymp-year-display'>{year}</span>
                <button className='ymp-nav-button' title='Next Year' onClick={handleNextYear}>
                    <RightArrow />
                </button>
            </div>

            {/* Month Grid */}
            <div className='ymp-month-grid'>
                {monthOptions.map((month, index) => {
                    const newIndex = `${index + 1}/${parseInt(year)}`;
                    return (
                        <button
                            key={newIndex}
                            className={`ymp-month-button ${selectedMonth === newIndex ? 'selected' : ''}`}
                            onClick={() => handleMonthClick(newIndex)}
                            title={month.fullName}
                        >
                            {month.name}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default YearMonthPicker;
