import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PropTypes from 'prop-types';

const animatedComponents = makeAnimated();

export const customStyles = {
    control: (provided, { isDisabled }) => ({
        ...provided,
        minHeight: '48px',
        background: isDisabled ? 'rgba( 255, 255,0,0.07)' : 'var(--col-select-white)',
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 12px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    multiValue: (provided) => ({
        ...provided,
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        background: 'var(--col-multi-select-option)',
        minWidth: 'max-content',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        padding: '0 6px',
        color: 'var(--col-primary)',
        fontWeight: '500',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--col-primary)',
        fontWeight: 500,
    }),
    menu: (styles) => {
        return {
            ...styles,
            backgroundColor: 'var(--col-select-white)',
            zIndex: 999,
        };
    },
    menuList: (provided) => ({
        ...provided,
        maxHeight: '10rem', // 4 * 40px
        overflowY: 'auto', // Enable scrolling
    }),
};

export const customTheme = (theme) => ({
    ...theme,
    borderRadius: 6,
    colors: {
        ...theme.colors,
        primary25: 'var(--col-E4FBFA)',
        primary: 'var(--col-btn-primary)',
    },
});

const FormSelect = ({
    field,
    form: { touched, errors, setFieldValue, setTouched },
    label,
    options,
    placeholder,
    isFormFocused,
    className = '',
    col = 6,
    width = '356px',
    isMulti = false,
    isClearable = false,
    isDisabled = false,
    isSearchable = false,
    onInputChange = () => void 0,
    isLoading = false,
    required = false,
    ...props
}) => {
    const handleChange = (selectedOptions) => {
        setFieldValue(field.name, selectedOptions);
        if (!touched[field.name]) {
            setTouched({ ...touched, [field.name]: true });
        }
    };

    return (
        <div className={`col-${col} mb-2 ` + className} style={{ width: `${width}` }}>
            {label && (
                <label htmlFor={field.name}>
                    {label}
                    {required && <span className='text-danger ms-1'>*</span>}
                </label>
            )}
            <Select
                isMulti={isMulti}
                name={field.name}
                value={field.value || []}
                onChange={handleChange}
                options={options}
                components={animatedComponents}
                placeholder={placeholder}
                classNamePrefix={'ssc-select'}
                className={` ${
                    isFormFocused && errors[field.name] && touched[field.name]
                        ? 'border border-danger-subtle border-1'
                        : ''
                }`}
                styles={customStyles}
                theme={customTheme}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                onInputChange={onInputChange}
                isLoading={isLoading}
                {...props}
            />
            {isFormFocused && touched[field.name] && errors[field.name] && (
                <p className='error-message'>{errors[field.name]}</p>
            )}
        </div>
    );
};

FormSelect.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    isFormFocused: PropTypes.bool,
    className: PropTypes.string,
    col: PropTypes.number,
    width: PropTypes.string,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default FormSelect;
