import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';

export const createDocumentRequest = ({ data }) => {
    let url = `${API.DOCUMENT_REQUEST}`;
    return fetchData({ method: METHODS.POST, url, body: data });
};

export const getAllDocumentRequest = async ({
    offset,
    limit,
    search,
    filter = {},
    role = '',
    id = '',
}) => {
    let url = `${API.DOCUMENT_REQUEST}?offset=${offset}&limit=${limit}`;
    if (role && id) url += `&role=${role}&id=${id}`;

    const filterObject = Object.fromEntries(
        Object.entries(filter).filter(([key, value]) => key && value !== undefined && value !== ''),
    );
    if (Object.keys(filterObject).length > 0)
        url += `&filter=${encodeURIComponent(JSON.stringify(filterObject))}`;
    if (search) url += `&search=${encodeURIComponent(search)}`;
    return await fetchData({ method: METHODS.GET, url });
};

// get specific document detail
export const getDocumentDetailsByID = ({ id }) => {
    const url = `${API.DOCUMENT_REQUEST}/${id}`;
    return fetchData({ method: METHODS.GET, url });
};

export const doFileUploadEntry = ({ body }) => {
    let url = `${API.DOCUMENT_UPLOAD}`;
    return fetchData({ body, method: METHODS.POST, url });
};

export const getAllDocumentRequestByAccount = async ({
    hiring_client_id,
    account_id,
    offset,
    limit,
    search,
    filter = {},
}) => {
    let url = `${API.DOCUMENT_REQUEST}?account_id=${account_id}&offset=${offset}&limit=${limit}`;
    if (hiring_client_id) url += `&hiring_client_id=${hiring_client_id}`;
    const filterObject = Object.fromEntries(
        Object.entries(filter).filter(([key, value]) => key && value !== undefined && value !== ''),
    );
    if (Object.keys(filterObject).length > 0)
        url += `&filter=${encodeURIComponent(JSON.stringify(filterObject))}`;
    if (search) url += `&search=${encodeURIComponent(search)}`;
    return await fetchData({ method: METHODS.GET, url });
};

export const getFileUploadsByDocumentRequestID = ({ documentRequestID }) => {
    const url = `${API.DOCUMENT_UPLOAD}?document_request_id=${documentRequestID}`;
    return fetchData({ method: METHODS.GET, url });
};

export const updateDocsRequest = ({ id, body }) => {
    const url = `${API.DOCUMENT_REQUEST}/${id}`;
    return fetchData({ body, method: METHODS.PUT, url });
};

export const deleteDocsRequest = ({ id }) => {
    const url = `${API.DOCUMENT_REQUEST}/${id}`;
    return fetchData({ method: METHODS.DELETE, url });
};
