import * as React from 'react';
const CloseIcon = ({ size, strokeWidth, fill, ...props }) => (
    <svg
        stroke='currentColor'
        fill={fill || 'currentColor'}
        strokeWidth={strokeWidth || 0}
        viewBox='119.958 119.958 272.083 272.083'
        height={size || 15}
        width={size || 15}
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z'
            stroke={'none'}
        />
    </svg>
);
export default CloseIcon;
