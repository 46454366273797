import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { ADMIN_COLUMNS, ADMIN, ROLES } from '../../../constants';
import { Table } from '../../shared';
import { useDebounce, usePagination } from '../../../hooks';
import { getAllAdmin, popup } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';
import { useSelector } from 'react-redux';

export const AdminListing = () => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({ data: [], count: 0 });
    const isSuperAdmin = useSelector((s) => s.user.role) === ROLES.SUPER_ADMIN;
    const [search, setSearch] = useState('');
    const pagination = usePagination(response.data.length, response.count);
    const debouncedApiCall = useDebounce(() => {
        getAllAdmin({
            limit: pagination.limit,
            offset: pagination.offset,
            search: search,
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) setResponse({ data: res.data, count: res.count });
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [pagination.limit, pagination.offset, search]);
    return (
        <>
            <AdminPageHeader
                heading={ADMIN.PAGE_HEADING}
                buttonText={ADMIN.BUTTON_TEXT}
                searchBarPlaceholder={ADMIN.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />

            <Table
                columns={
                    isSuperAdmin
                        ? ADMIN_COLUMNS
                        : ADMIN_COLUMNS.filter((col) => col.name !== 'Action')
                }
                data={response.data}
                pagination={pagination}
                pageName={ADMIN.PAGE_NAME}
                debouncedApiCall={debouncedApiCall}
                stickyColumns={['Admin Name']}
            />
        </>
    );
};
