import React from 'react';
import PropTypes from 'prop-types';

const CardRightArrow = ({ size = 18, style, fill, stroke = '#8993A4' }) => (
    <svg
        width={size}
        height={size}
        viewBox='0 0 16 17'
        fill={fill || 'none'}
        xmlns='http://www.w3.org/2000/svg'
        style={style}
    >
        <path
            d='M5.66675 3.83341L10.3334 8.50008L5.66675 13.1667'
            stroke={stroke}
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

CardRightArrow.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

export default CardRightArrow;
