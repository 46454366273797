import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { extractFileName, popup, renameFileWithUUID } from '../../../utils';
import {
    MODAL,
    DOCUMENT_REQUEST_FORM,
    SAFETY_MEETING,
    industryTypesOptions,
    languageOptions,
    safetyMeetingTypesOptions,
    INDUSTRIES,
} from '../../../constants';
import { setLoaderProgress, setLoaderVisibility, setShowProgress } from '../../../redux';
import FormSelect from '../Forms/FormSelect';
import FormInput from '../Forms/FormInput';
import SafetyMeetingFilesListView from '../../Admin/SafetyMeetings/SafetyMeetingFilesListView';
import {
    createSafetyMeetingsService,
    signedSafetyServiceFile,
} from '../../../utils/apiServices/safetyServiceAPI';
import Toggle from '../Buttons/Toggle';
import useBulkUploadZip from '../../../hooks/useBulkUploadZip';
import { getFileInfoFromFileName } from '../../../hooks/constants';
import useWindowSize from '../../../hooks/useWindowSize';
import { Button, Modal } from 'react-bootstrap';

const DEFAULT_EDIT_DETAILS = {
    file_name: '',
    industries: [],
    language: '',
    type: '',
};

export const AddSafetyMeetingModal = ({
    showAddModal,
    setShowAddModal,
    isEdit = false,
    editDetails = DEFAULT_EDIT_DETAILS,
    editHandler = () => {},
}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedZip, setSelectedZip] = useState([]);
    const [filesArray, setFilesArray] = useState([]);
    const [uploadWithZip, setUploadWithZip] = useState(false);
    const [fileName, setFileName] = useState(editDetails.file_name);
    const { bulkUploadZip } = useBulkUploadZip();
    const dispatch = useDispatch();
    const { width } = useWindowSize();
    const fieldWidth = width < 776;

    const handleRemoveFile = (indexToRemove) => {
        setFilesArray((prevSelectedFiles) =>
            prevSelectedFiles.filter((_, index) => index !== indexToRemove),
        );
        !!selectedFiles.length &&
            setSelectedFiles((prevSelectedFiles) =>
                prevSelectedFiles.filter((_, index) => index !== indexToRemove),
            );
        !!selectedZip.length &&
            setSelectedZip((prevSelectedFiles) =>
                prevSelectedFiles.filter((_, index) => index !== indexToRemove),
            );
    };

    const onFileChange = (event, setFieldValue) => {
        try {
            const files = event.target.files;
            const selectedFilesArray = Array.from(files);
            if (uploadWithZip && selectedFilesArray[0]?.type === 'application/zip') {
                if (selectedFilesArray[0]?.size > 1e8) {
                    return popup('error', 'File size should be upto 100MB');
                }
                setSelectedZip(selectedFilesArray);
                return;
            }
            if (selectedFilesArray[0]?.type !== 'application/pdf') {
                return popup('error', 'Only PDF* file is allowed');
            }
            const fullFileName = selectedFilesArray[0]?.name;
            const fileName = extractFileName(fullFileName);
            const { type, language, isQuiz } = getFileInfoFromFileName(fileName);
            setFileName(fileName);
            setFieldValue('fileName', fileName);
            setFieldValue('type', { value: type, label: type });
            setFieldValue('language', {
                value: language,
                label: language,
            });
            setFieldValue(
                'industries',
                isQuiz
                    ? [
                          { value: type, label: type },
                          { value: INDUSTRIES.QUIZ, label: INDUSTRIES.QUIZ },
                      ]
                    : [{ value: type, label: type }],
            );
            setSelectedFiles([...selectedFilesArray]);
        } catch (error) {
            popup('error', error.message);
        }
    };

    // 1.) To dynamically creates the <input> element,
    // 2.) Don't need the input to persist in the DOM but only need its functionality.
    const openFileExplorer = (setFieldValue) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.onchange = (e) => onFileChange(e, setFieldValue);
        input.click();
    };

    const readFileAsArrayBuffer = async (file) => {
        try {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            return new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const createSafetyMeetings = async (filesData) => {
        const { message } = await createSafetyMeetingsService({ data: filesData });
        return message;
    };

    const resetFormState = () => {
        setSelectedFiles([]);
        setFileName('');
    };

    const addMoreFile = (values, resetForm) => {
        if (selectedFiles.length > 0 && filesArray.length > 0) {
            if (filesArray.some((file) => file.file?.name === selectedFiles[0]?.name)) {
                popup('error', 'File already exist');
                return;
            }
        }
        const newFile = {
            fileName: values.fileName,
            industries: values.industries.map((industry) => industry.value),
            language: values.language.value,
            type: values.type.value,
            file: selectedFiles[0],
        };
        setFilesArray([...filesArray, newFile]);
        resetFormState();
        resetForm();
    };

    return (
        <Modal
            show={showAddModal}
            onHide={() => {
                setShowAddModal(false);
                setFilesArray([]);
                setSelectedFiles([]);
            }}
            size='lg'
            dialogClassName='overflow-hidden'
            fullscreen='lg-down'
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id='contained-modal-title-vcenter'
                    className='d-flex align-items-center gap-2 justify-content-start w-100'
                >
                    {isEdit ? 'Edit Safety Meeting' : 'Add Safety Meeting'}
                    {!isEdit && (
                        <Toggle
                            checked={uploadWithZip}
                            title='Upload with a ZIP File'
                            onChange={setUploadWithZip}
                        />
                    )}
                </Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={{
                    fileName: fileName,
                    industries: editDetails.industries,
                    language: editDetails.language,
                    type: editDetails.type,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    if (isEdit) {
                        editHandler(values, { setSubmitting });
                        return;
                    }
                    if (uploadWithZip) {
                        await bulkUploadZip(selectedZip[0]);
                        resetFormState();
                        setSelectedZip([]);
                        setShowAddModal(false);
                        popup('success', 'Safety Meeting(s) added successfully');
                    } else {
                        if (filesArray.length === 0) {
                            return popup('error', 'Please select at least 1 file');
                        }
                        try {
                            dispatch(setLoaderVisibility(true));
                            dispatch(setShowProgress(true));
                            setSubmitting(true);
                            const filesDetails = [];

                            for (let i = 0; i < filesArray.length; i++) {
                                const file = filesArray[i];
                                const fileUUID = uuidV4();

                                const binaryData = await readFileAsArrayBuffer(file.file);
                                const {
                                    data: { preSignedUrl, filePath, contentType },
                                } = await signedSafetyServiceFile({
                                    signedData: {
                                        file_name: renameFileWithUUID(file.file.name, fileUUID),
                                        language: file.language,
                                        type: file.type,
                                    },
                                });

                                dispatch(setLoaderProgress(((i + 1) / filesArray.length) * 100));

                                const uploadResponse = await fetch(preSignedUrl, {
                                    method: 'PUT',
                                    body: binaryData,
                                    headers: {
                                        'Content-Type': contentType,
                                    },
                                });
                                if (uploadResponse.status === 200) {
                                    filesDetails.push({
                                        file_name: file.fileName,
                                        language: file.language,
                                        s3_key: filePath,
                                        industries: file.industries,
                                    });
                                } else {
                                    throw new Error(DOCUMENT_REQUEST_FORM.FILE.FILE_UPLOAD_FAILED);
                                }
                            }
                            await createSafetyMeetings(filesDetails);
                            setFilesArray([]);
                            setSelectedFiles([]);
                            popup('success', 'Safety Meeting(s) added successfully');
                            setShowAddModal(false);
                        } catch (error) {
                            popup('error', error.message);
                        } finally {
                            setSubmitting(false);
                            dispatch(setLoaderVisibility(false));
                        }
                    }
                }}
            >
                {({ values, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                    <>
                        <Modal.Body>
                            <form action=''>
                                <div className='row'>
                                    {!uploadWithZip ? (
                                        <div className='d-inline-flex flex-row flex-wrap justify-content-between'>
                                            <Field
                                                name='fileName'
                                                label={SAFETY_MEETING.FILE_NAME}
                                                placeholder={SAFETY_MEETING.FILE_NAME_PLACEHOLDER}
                                                maxLength={100} // Adjust the max length as needed
                                                component={FormInput}
                                                width={fieldWidth ? '100%' : '48%'}
                                                isRequired
                                                isDisabled={!isEdit && !selectedFiles.length}
                                            />
                                            {!isEdit && (
                                                <Field
                                                    name='type'
                                                    label='Type'
                                                    options={safetyMeetingTypesOptions}
                                                    placeholder='Select a type...'
                                                    component={FormSelect}
                                                    width={fieldWidth ? '100%' : '48%'}
                                                    isClearable={!!values.type}
                                                    isDisabled={!!isEdit}
                                                />
                                            )}
                                            <Field
                                                name='industries'
                                                label={'Industries'}
                                                options={industryTypesOptions}
                                                placeholder={'Select industries...'}
                                                component={FormSelect}
                                                width={fieldWidth ? '100%' : '48%'}
                                                isMulti
                                            />
                                            <Field
                                                name='language'
                                                label='Language'
                                                options={languageOptions}
                                                placeholder='Select a language...'
                                                component={FormSelect}
                                                width={fieldWidth ? '100%' : '48%'}
                                                isClearable={!!values.language}
                                            />
                                        </div>
                                    ) : null}
                                    {!isEdit && (
                                        <>
                                            <div
                                                className={`d-flex align-items-center justify-content-between mt-2 ${
                                                    uploadWithZip ? ' col-4 ' : ' col-8 '
                                                }`}
                                            >
                                                <div className='d-flex gap-2 gap-md-4 w-100'>
                                                    <button
                                                        className='ssc-primary-green-btn upload-file-btn disabled-hover '
                                                        type='button'
                                                        onClick={() =>
                                                            openFileExplorer(setFieldValue)
                                                        }
                                                        disabled={isSubmitting}
                                                        style={{
                                                            height: '48px',
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        {uploadWithZip ? 'Add Zip' : 'Select File'}
                                                    </button>
                                                    {!uploadWithZip && (
                                                        <button
                                                            type='button'
                                                            onClick={() =>
                                                                addMoreFile(values, resetForm)
                                                            }
                                                            className='ssc-primary-green-btn py-0  disabled-hover'
                                                            disabled={
                                                                isSubmitting ||
                                                                !values.fileName ||
                                                                !values.language ||
                                                                !values.type ||
                                                                !values.industries.length ||
                                                                !selectedFiles.length
                                                            }
                                                        >
                                                            {'Add File'}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            {!uploadWithZip && (
                                                <span className='d-flex align-items-center justify-content-between col-4 mt-2'>
                                                    {`Files Added ${filesArray.length}`}
                                                </span>
                                            )}
                                            <div className='d-flex align-items-center justify-content-between col-12 mt-3'>
                                                <SafetyMeetingFilesListView
                                                    filesArray={filesArray}
                                                    selectedFiles={selectedFiles}
                                                    handleRemoveFile={handleRemoveFile}
                                                    resetForm={resetForm}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {uploadWithZip && (
                                        <div className='d-flex align-items-center justify-content-between col-3'>
                                            <SafetyMeetingFilesListView
                                                filesArray={[]}
                                                selectedFiles={selectedZip}
                                                handleRemoveFile={handleRemoveFile}
                                                resetForm={resetForm}
                                            />
                                        </div>
                                    )}
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='d-flex gap-3 w-100 align-items-center justify-content-end modal-btn mt-3'>
                                <Button
                                    type='button'
                                    className='ssc-secondary-white-btn '
                                    onClick={() => {
                                        setShowAddModal(false);
                                    }}
                                    style={{ height: '40px', fontSize: '15px' }}
                                >
                                    {MODAL.CANCEL_BUTTON}
                                </Button>
                                <Button
                                    type='submit'
                                    onClick={handleSubmit}
                                    className='table-footer-btn'
                                    disabled={
                                        !isEdit &&
                                        (isSubmitting ||
                                            filesArray.length === 0 ||
                                            selectedFiles.length === 1) &&
                                        selectedZip.length === 0
                                    }
                                    style={{ height: '40px' }}
                                >
                                    {isEdit ? 'Save' : 'Upload'}
                                </Button>
                            </div>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

AddSafetyMeetingModal.propTypes = {
    showAddModal: PropTypes.bool.isRequired,
    setShowAddModal: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    editDetails: PropTypes.shape({
        file_name: PropTypes.string.isRequired,
        industries: PropTypes.array.isRequired,
        language: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),
    editHandler: PropTypes.func,
};
