import React, { useEffect, useState } from 'react';
import {
    HIDDEN_HIRING_CLIENT_COLUMN,
    HIDDEN_HIRING_CLIENTS,
    SCORECARD_REPORT,
} from '../../../constants';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility, setSelectedHiringClients } from '../../../redux';
import { popup } from '../../../utils';
import { Table } from '../Table';
import {
    getHiddenHiringClients,
    showHiringClients,
} from '../../../utils/apiServices/hideHiringClientApi';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import InputWithSearch from '../common/InputWithSearch';

const UnhideHiringClientModal = ({ showModal, setShowModal, accountId, debouncedApiCall }) => {
    const [hiddenHClData, setHiddenHClData] = useState(undefined);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const pagination = usePagination(hiddenHClData?.data.length, hiddenHClData?.count);
    const { selectedHiringClients, selectedAccount } = useSelector((s) => s.accountArray);

    const getHiddenHCByAccountId = useDebounce(async () => {
        try {
            const response = await getHiddenHiringClients({
                accountId,
                offset: pagination.offset,
                limit: pagination.limit,
                search: search,
            });
            setHiddenHClData({ data: response.data, count: response.count });
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 1000);

    const unhideHiringClient = useDebounce(async () => {
        dispatch(setLoaderVisibility(true));
        try {
            await showHiringClients({
                accountId: selectedAccount,
                hiringClientIds: selectedHiringClients,
            });
            popup('success', 'Hiring clients unhidden successfully');
            setShowModal(false);
            debouncedApiCall();
            dispatch(setSelectedHiringClients([]));
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 1000);

    useEffect(() => {
        if (accountId) {
            !search && dispatch(setLoaderVisibility(true));
            getHiddenHCByAccountId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, pagination.limit, pagination.offset, search]);
    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
                dispatch(setSelectedHiringClients([]));
            }}
            size='lg'
            dialogClassName='overflow-hidden'
            fullscreen='sm-down'
        >
            <Modal.Header closeButton>
                <Modal.Title className='me-2' style={{ fontSize: '18px' }}>
                    {HIDDEN_HIRING_CLIENTS.MODAL.HEADING}
                </Modal.Title>
                <InputWithSearch
                    search={search}
                    setSearch={setSearch}
                    placeholder={SCORECARD_REPORT.SCORE_CARD_DETAILS_PAGE.SEARCH_BAR_PLACEHOLDER}
                    className={'w-50'}
                />
            </Modal.Header>
            <Modal.Body>
                <div className='modal-body p-0 pt-4'>
                    {!!hiddenHClData && (
                        <Table
                            columns={HIDDEN_HIRING_CLIENT_COLUMN}
                            data={hiddenHClData?.data}
                            pagination={pagination}
                            tableStyle={{ height: '580px', overflow: 'auto' }}
                            stickyColumns={['Hiring Client']}
                        />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex w-100 align-items-center justify-content-between modal-btn'>
                    <span
                        style={{ color: '#738da6', fontSize: '14px' }}
                    >{`${selectedHiringClients.length}/${hiddenHClData?.count} Selected`}</span>
                    <button
                        type='submit'
                        onClick={unhideHiringClient}
                        className='ssc-primary-green-btn py-2 w-auto disabled-hover'
                        disabled={selectedHiringClients.length === 0}
                    >
                        {HIDDEN_HIRING_CLIENTS.BUTTONS.SAVE}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default UnhideHiringClientModal;
