import { useCallback, useState } from 'react';
import useWindowEvent from './useWindowEvent';

const eventListenerOptions = {
    passive: true,
};

const useWindowSize = (ref) => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    const setSize = useCallback(() => {
        setWindowSize({
            width: ref ? ref?.current?.offsetWidth : window.innerWidth || 0,
            height: ref ? ref?.current?.offsetHeight : window.innerHeight || 0,
        });
    }, [ref]);

    useWindowEvent('resize', setSize, eventListenerOptions);
    useWindowEvent('orientationchange', setSize, eventListenerOptions);

    return windowSize;
};

export default useWindowSize;
