import React from 'react';

export const SectionBox = ({ children }) => {
    return (
        <div className='row h-100 '>
            <div className='col-12 h-100'>
                <div className='d-flex flex-column h-100 justify-content-start pt-2 p-md-3'>
                    {children}
                </div>
            </div>
        </div>
    );
};
