import { PDFDocument, rgb } from 'pdf-lib';
import { useDispatch } from 'react-redux';
import {
    setFileViewModalDetails,
    setLoaderProgress,
    setLoaderVisibility,
    setShowProgress,
} from '../redux';
import { getContentTypeByExtension, getFileSignedUrl, popup } from '../utils';
import { downloadFileWithRetry } from './constants';

const useViewProcessedFile = () => {
    const dispatch = useDispatch();

    const viewProcessedFile = async (filesData) => {
        try {
            dispatch(setLoaderVisibility(true));
            dispatch(setShowProgress(true));

            let currentStep = 0;
            const updateProgress = () => {
                currentStep += 1;
                dispatch(setLoaderProgress((currentStep / 3) * 100));
            };

            const { s3_key, logoUrl, customerName, fileName } = filesData;

            // Fetch the PDF from the signed URL
            const { data } = await getFileSignedUrl({ filePath: s3_key });
            const fileArrayBuffer = await downloadFileWithRetry(data.url);

            // Step 2: Add the logo to the PDF
            const pdfDoc = await PDFDocument.load(fileArrayBuffer);

            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            // Define the position for the top-left placement
            const { height } = firstPage.getSize();
            const logoX = 40; // Slight padding from the left edge
            const logoY = height - 35; // Padding from the top edge

            // Handle the logo or customer name fallback
            updateProgress();

            if (logoUrl) {
                const { data } = await getFileSignedUrl({ filePath: logoUrl });
                const logoResponse = await fetch(data.url);
                const logoBuffer = await logoResponse.arrayBuffer();
                const logoImage = await pdfDoc.embedPng(logoBuffer);
                firstPage.drawImage(logoImage, {
                    x: logoX,
                    y: logoY - 50, // Adjust height to fit the logo
                    width: 150,
                    height: 50,
                });
            } else {
                firstPage.drawText(customerName, {
                    x: logoX,
                    y: logoY,
                    size: 18,
                    color: rgb(0, 0, 0),
                });
            }
            updateProgress();

            // Prepare PDF view
            const modifiedPdfBytes = await pdfDoc.save();
            const blob = new Blob([modifiedPdfBytes], { type: getContentTypeByExtension('pdf') });
            const uri = URL.createObjectURL(blob);

            dispatch(
                setFileViewModalDetails({
                    openFileViewerModal: true,
                    docs: [{ uri, fileType: getContentTypeByExtension('pdf') }],
                    fileName,
                }),
            );
            updateProgress();
        } catch (error) {
            popup('error', error.message);
            console.error('Error processing PDFs:', error);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    return {
        viewProcessedFile,
    };
};

export default useViewProcessedFile;
