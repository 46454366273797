/* eslint-disable no-useless-escape */
/*
constants related to string literals used throughout the application, 
such as labels, placeholders, button text.
*/

export const MODULES = {
    ADMIN: 'admin',
    CLIENT: 'client',
};

export const REGEX = {
    ACCOUNT_NAME: /^[a-zA-Z0-9][a-zA-Z0-9!@#$%^&*()\-_+=~'\[\]{}|:;"'<>,.?\\/ ]{1,38}$/,
    ALL_LANG_NAME: /^[\p{L}0-9][\p{L}0-9!@#$%^&*()\-_+=~'\[\]{}|:;"'<>,.?\\/ ]{1,38}$/u,
    CAPITAL_CASE_CHARACTER: /^(?=.*[A-Z])/,
    DOCUMENT_CATEGORY:
        /^[©®™a-zA-Z][a-zA-Z0-9!@#$%^&*©®™()\-_+=~'\[\]{}|:;"'<>,.?\\ ]{1,100}$/,
    EMAIL: /^[A-Z0-9._%+-]{1,50}@[A-Z0-9.-]+\.[A-Z]{2,50}$/i,
    FILE_NAME: /^[a-zA-Z0-9 _-]+$/,
    MINIMUM_LENGTH_10: /^.{10,}$/,
    MAXIMUM_LENGTH_16: /^.{0,16}$/,
    NAME: /^(?=[A-Za-z ]{2,40}$)[A-Za-z]+(?: [A-Za-z]+){0,2}$/,
    NUMERIC_CHARACTER: /^(?=.*\d)/,
    PHONE: /^[0-9]{10}$/,
    REGULATORY_CONTENT: /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]{3,255}$/,
    REGULATORY_TITLE: /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]{3,255}$/,
    SMALL_CHARACTER: /^(?=.*[a-z])/,
    SPECIAL_CHARACTER: /^(?=.*[!@#$%^&*])/,
    STRONG_PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*#?~+=^_-]).{10,16}$/,
};

export const LOCAL_STORAGE = {
    AUTH_TOKEN: 'auth_token',
    CUSTOMER_ID: 'customer_id',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    FULL_NAME: 'full_name',
    ACCOUNT_ID: 'account_id',
    ROLE_NAME: 'role_name',
    ROLE_CODE: 'role_code',
    USER_ID: 'user_id',
    EMAIL: 'email',
    REFRESH_TOKEN: 'refresh_token',
    PLATFORMS_LENGTH: 'platforms_length',
    IMPERSONATE_TOKEN: 'impersonate_token',
    ORIGINAL_TOKEN: 'original_token',
    IS_IMPERSONATING: 'is_impersonating',
    DARK_MODE: 'dark-mode',
    LIGHT_MODE: 'light-mode',
    CSM_CASELOAD_DATA: 'csm_caseload_data',
    IS_CSM_CASELOAD: 'is_csm_caseload',
};

export const AUTH = {
    ACCOUNT_NAME: 'Company Name',
    ENTER_ACCOUNT_NAME: 'Enter Company Name',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    PASSWORD: 'Password',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number',
    SUBMIT: 'Submit',
    ENTER_EMAIL_ADDRESS: 'Enter Email Address',
    ENTER_PHONE: 'Enter Phone Number',
    ENTER_PASSWORD: 'Enter Password',
    ENTER_NEW_PASSWORD: 'Enter New Password',
    CONFIRM_NEW_PASSWORD: 'Confirm New Password',
    ENTER_FIRST_NAME: 'Enter First Name',
    ENTER_LAST_NAME: 'Enter Last Name',
    LOGIN: 'Login',
    SIGN_UP: 'Sign Up',
    CREATE: 'Create',
    REGISTER_NOW: 'Register Now',
    FORGOT_PASSWORD: 'Forgot Password',
    LOGIN_WITH_YOUR_EMAIL: 'Login with your email',
    NOT_REGISTERED: 'Not Registered?',
    CREATE_AN_ACCOUNT: 'Create an Account',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have account?',
    SMALL_CHARACTER: '1 Lower Case Character',
    CAPITAL_CASE_CHARACTER: '1 Capital Case Character',
    NUMERIC_CHARACTER: '1 Numeric Character',
    SPECIAL_CHARACTER: '1 Special Character',
    MINIMUM_LENGTH_10: 'Minimum Length 10',
    MAXIMUM_LENGTH_16: 'Maximum Length 16',
    BACK_TO_LOGIN: 'Back to login',
    NEXT: 'Next',
    NO_WORRIES: 'No Worries, We will send you reset instructions',
    RESET_PASSWORD: 'Reset Password',
    CREATE_A_NEW_PASSWORD: 'Create a new password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm New Password',
    ENTER_CUSTOMER_NAME: 'Enter Client Name',
    CSM_NAME: ' CSM Name',
    NAME: 'Name',
    EMAIL: 'Email',
    TYPE: 'Type',
    SAVE_CHANGES: 'Save Changes',
};

export const REDUX = {
    slice: {
        USER: 'user_slice',
        LOADER: 'loader_slice',
        ACCOUNT_ARRAY: 'accountArray_slice',
        NAVIGATION_STATE: 'navigation_state_slice',
        NOTIFICATION_UPDATE: 'notification_update_slice',
        SAFETY_MEETING_SLICE: 'safety_meeting_slice',
        SESSION_SLICE: 'session_slice',
        FILE_VIEWER_MODAL_SLICE: 'file_viewer_modal_slice',
        IS_SUBSCRIPTION_EXPIRY_READ_SLICE: 'is_subscription_expiry_read_slice',
        THEME: 'theme_slice',
        CSM_CASELOAD: 'csm_caseload_slice',
    },
};

export const CUSTOMER_LIST = {
    PAGE_HEADING: 'Clients',
    BUTTON_TEXT: 'Add Client',
    SEARCH_BAR_PLACEHOLDER: 'Search Clients',
    PAGE_NAME: 'Clients',
    SELECT_USER: 'Select Primary User',
    ACTION: 'ACTION',
    ADD_CUSTOMER: 'Add New Client',
    CUSTOMER_NAME: 'Client Name',
};

export const DOCUMENT_CATEGORY = {
    PAGE_HEADING: 'Document Categories',
    BUTTON_TEXT: 'Add Document Category',
    SEARCH_BAR_PLACEHOLDER: 'Search Document Categories',
    PAGE_NAME: 'Document Categories',
    EDIT_BUTTON: 'Edit Document Category',
    DELETE_BUTTON: 'Document Category',
    DELETED_MESSAGE: 'Document Category has been deleted',
    UPDATED_MESSAGE: 'Document Category updated',
    CREATED_MESSAGE: 'New Document Category has been created',
    ALREADY_EXIST: 'Category name already exists',
    SPACE_VALIDATION: 'Please enter a category name',
    INPUT_PLACEHOLDER: 'Please enter a category name',
};

export const CSM = {
    PAGE_HEADING: 'CSMs',
    BUTTON_TEXT: 'Add CSM',
    SEARCH_BAR_PLACEHOLDER: 'Search CSMs',
    PAGE_NAME: 'CSMs',
    SELECT_CSM: 'Please Select CSM',
};

export const SALES_AGENT = {
    PAGE_HEADING: 'Sales Agents',
    BUTTON_TEXT: 'Add Sales Agent',
    SEARCH_BAR_PLACEHOLDER: 'Search Sales Agents',
    PAGE_NAME: 'Sales Agents',
    SELECT_SALES_AGENT: 'Please Select Sales Agent',
};

export const HIRING_CLIENT = {
    PAGE_HEADING: 'Hiring Clients',
    BUTTON_TEXT: 'Add Hiring Client',
    SEARCH_BAR_PLACEHOLDER: 'Search For Hiring Clients',
};

export const DOC_CUSTOMER = {
    PAGE_HEADING: 'Documents',
    SEARCH_BAR_PLACEHOLDER: 'Search Documents',
};

export const ADMIN = {
    PAGE_HEADING: 'Admins',
    BUTTON_TEXT: 'Add Admin',
    SEARCH_BAR_PLACEHOLDER: 'Search Admins',
    PAGE_NAME: 'Admins',
};

export const REGULATORY_ISSUE = {
    PAGE_HEADING: 'Regulatory Issue',
    PAGE_NAME: 'Regulatory Issue',
    UPDATED_MESSAGE: 'Regulatory issue updated',
    DELETED_MESSAGE: 'Regulatory issue has been deleted',
    CREATED_MESSAGE: 'New Regulatory issue has been created',
    EDIT_REGULATORY_ISSUE: 'Edit Regulatory issue',
    ADD_REGULATORY_ISSUE: 'Add Regulatory issue',
    ADD_BUTTON_TEXT: 'Add Regulatory Issue',
    BROADCASTED: 'Regulatory Issue Broadcasted',
};

export const DASHBOARD_COLUMNS = {
    ADMIN: 'Admins',
    CSM: 'CSMs',
    CUSTOMER: 'Clients',
    DOCUMENT_CATEGORY: 'Document Categories',
};

export const DOCUMENTS = {
    PAGE_HEADING: 'Documents',
    BUTTON_TEXT: 'Request New Document',
    SEARCH_BAR_PLACEHOLDER: 'Search by Documents, Hiring Clients or Clients',
    PAGE_NAME: 'Documents',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    PRIORITY: 'Priority',
    CUSTOMER: 'Client Name',
    CSM: 'CSM',
    HIRING_CLIENT: 'Hiring Client',
    PLATFORM: 'Platform',
    STATUS: 'Status',
    NOTES: 'Notes',
    UPLOADED_BY_CSM: 'Uploaded by CSM',
    UPLOADED_BY_USER: 'Uploaded by User',
    UPLOADED_DOCUMENTS: 'Uploaded Documents',
    UPLOAD_DOCUMENTS: 'Upload Documents',
    UPLOAD_FILE: 'Upload File',
    UPLOAD_FILES: 'Upload Files',
};

export const DOCUMENTS_STATUS = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
};

export const SCORECARD_REPORT = {
    PAGE_HEADING: 'Scorecard Report',
    BUTTON_TEXT: 'Add Hiring Client',
    SEARCH_BAR_PLACEHOLDER: 'Search Scorecards',
    PAGE_NAME: 'Scorecard Reports',
    DELETE_BUTTON: 'item',
    EDIT_BUTTON: 'Edit Scorecard',
    COLUMNS: {
        HIRING_CLIENT: {
            NAME: 'Hiring Client',
            PLACEHOLDER: 'Select Hiring Client',
        },
        TPA: 'TPA',
        PLATFORM: {
            NAME: 'Platform',
            PLACEHOLDER: 'Select a platform',
        },
        PLATFORM_GRADE: {
            NAME: 'Platform Grade',
        },
        HIRING_CLIENT_GRADE: 'Grade',
        NOTES: {
            NAME: 'Notes',
            PLACEHOLDER: 'Select Notes',
        },
        GRADE: {
            NAME: 'Grade',
            PLACEHOLDER: 'Grade',
        },
        CUSTOMER: {
            NAME: 'Client',
            PLACEHOLDER: 'Select Client',
        },
        STATUS: 'Status',
        PENDING: 'Pending',
    },
    NO_DATA_FOUND: 'There are no Scorecard for this customer',
    SCORE_CARD_LISTING_PAGE: {
        HEADER_HEADING: 'Scorecards',
        HEADER_BUTTON: 'New Scorecard',
        SEARCH_BAR_PLACEHOLDER: 'Search by Clients and CSMs',
        DELETE_BUTTON: 'Scorecard',
        NO_SCORECARDS: 'There are no Scorecards',
        SCORECARD_DELETED: 'Scorecard deleted',
        SCORECARD: 'Scorecard',
    },
    SCORE_CARD_DETAILS_PAGE: {
        HEADER_HEADING: 'Scorecard Detail',
        HEADER_BUTTON: 'New Scorecard Entry',
        CREATE_SCORECARD_MODAL_HEADING: 'Create Scorecard Entry',
        EDIT_SCORECARD_MODAL_HEADING: 'Edit Scorecard Entry',
        SEARCH_BAR_PLACEHOLDER: 'Search Hiring Clients',
        ITEM: 'item',
    },
    SCORE_CARD_EMAIL_RECORDS: {
        HEADER_HEADING: 'Scorecard Email Records',
    },
};

export const CUSTOMER_DASHBOARD = {
    FEEDBACK_OPTIONS: ['App Functionality', 'Overall Service', 'Transparency', 'CSM'],
    NO_NEWS_FEED: 'No feeds to show',
    PLATFORMS: 'Platforms',
    PLATFORMS_NOT_FOUND: 'No Platforms found',
    HIRING_CLIENT_NOT_FOUND: 'No Hiring Clients found',
    NO_UPLOAD_REQUESTS: 'There are no document \n upload requests',
};

export const SSO = {
    GET_CODE: 'getCode',
    STARTUP: 'startup',
    TOKEN: 'token',
    FIRST_NAME: 'first-name',
    LAST_NAME: 'last-name',
    USER_ID: 'user_id',
    LOGIN_TEXT: 'Login with SSO',
    ROLE: 'role',
    REFRESH_TOKEN: 'refresh-token',
    EMAIL: 'email',
    PROFILE_IMAGE: 'image',
};
export const OVERVIEW = {
    TITLE: 'Overview',
    NEW_REGISTRATIONS: 'New Registration',
    DOCUMENTS_CREATED: 'Documents Created',
    DAYS_30: ' 30 Days',
    DAYS_60: ' 60 Days',
    DAYS_90: ' 90 Days',
    DAYS_120: ' 120 Days',
};

export const DELETE_MODAL = {
    DELETE_BUTTON: 'Delete',
    DELETE_HEADING: 'Are you sure want to delete \n',
    PARA_TEXT: 'This item will be deleted immediately. You can’t',
    UNDO_TEXT: 'undo this again',
};

export const MODAL = {
    PLATFORM_GRADE_CHANGE: 'Are you sure you want to change the platform grade for',
    PLATFORM: 'platform',
    UPDATE_BUTTON: 'Update',
    CANCEL_BUTTON: 'Cancel',
    SELECT_GRADE: 'Select a Platform Grade',
};

export const SERVER_ERROR = {
    CODE: 500,
    TITLE: 'Internal Server Error',
    GO_BACK: 'Go back',
};

export const DOCUMENT_REQUESTS = {
    DOCUMENT_REQUEST: 'Request Document',
};

export const DOCUMENT_REQUEST_FORM = {
    CUSTOMER: {
        LABEL: 'Client Name',
        PLACEHOLDER: 'Select Client',
    },
    HIRING_CLIENT: {
        LABEL: 'Hiring Client',
        PLACEHOLDER: 'Select Hiring Client',
    },
    DOCUMENT_CATEGORY: {
        LABEL: 'Document Category',
        PLACEHOLDER: 'Select Document Category',
    },
    PLATFORM: {
        LABEL: 'Platform',
        PLACEHOLDER: 'Select Platforms',
    },
    NOTES: {
        LABEL: 'Notes',
        PLACEHOLDER: 'Enter Notes',
    },
    RADIO: {
        LABEL: 'Priority',
        OPTIONS: ['High', 'Low'],
    },
    FILE: {
        UPLOAD: 'Upload file',
        FILE_LIMIT: 5,
        LIMIT_TEXT: 'only five files are allowed',
        FILE_SIZE: 'File size should be less than 10MB',
        FILE_SIZE_25MB: 'File size should be less than 25MB',
        FILE_TYPE: 'file type not allowed',
        FILE_SIZE_LIMIT: 10 * 1024 * 1024,
        FILE_SIZE_LIMIT_25MB: 25 * 1024 * 1024,
        FILE_UPLOAD_FAILED: 'File upload failed',
        CONFIRM_UPLOAD_1: 'Are you sure ?',
        CONFIRM_UPLOAD_2: 'Uploading will permanently add this file in the Request',
        CONFIRM: 'Confirm',
        DECLINE: 'Decline',
        DECLINED: 'File Upload Declined',
    },
    FORM_SUCCESS: ' Request has been',
    SUCCESS_MESSAGE: 'submitted successfully',
    VIEW_DOCUMENT: 'View Document',
    NEW_DOCUMENT: ' New Document Request',
    EMPTY_FIELDS: 'Please fill in all required fields.',
    FILE_UPLOAD_STATUS: 'Please upload at least one file.',
};

export const ROLES = {
    ACCOUNT_USER: 'account_user',
    ADMIN: 'admin',
    CONTENT_MANAGER: 'content_manager',
    CSM: 'csm',
    PRIMARY_USER: 'primary_user',
    SALES_AGENT: 'sales_agent',
    SECONDARY_USER: 'secondary_user',
    SUPER_ADMIN: 'super_admin',
    USER: 'user',
};

export const ROLE_NAME = {
    ADMIN: 'Admin',
    CONTENT_MANAGER: 'Content Manager',
    CSM: 'CSM',
    PRIMARY_USER: 'Primary User',
    SALES_AGENT: 'Sales Agent',
    SECONDARY_USER: 'Secondary User',
    SUPER_ADMIN: 'Super Admin',
};

export const USER = {
    ACCOUNT_USER: 'account_user',
    PRIMARY_USER: 'primary_user',
    ACCOUNT_USER_BUTTON: 'Account User',
    PRIMARY_USER_BUTTON: 'Primary User',
    SECONDARY_USER: 'Secondary User',
    ADD_PRIMARY_USER: 'Add Primary user',
    SELECTED_ROLE: 'selectedRole',
    USER_ALREADY_EXIST: 'This User already exist',
    USERS_UPDATED: 'Users updated',
    USERS_DELETED: 'users deleted',
    SET_AS_PRIMARY_USER: 'Set as Primary User',
    ADD_USER: 'Add USER',
    ARE_YOU_SURE: 'You want to continue with these changes?',
};

export const BUTTONS = {
    SAVE_BUTTON: 'Save',
    CANCEL_BUTTON: 'Cancel',
    DELETE_BUTTON: 'Delete',
    EDIT_PRIMARY_USER: 'Edit Primary User',
    CREATE_SCORECARD: 'Create Scorecard',
    YES: 'Yes',
    NO: 'No',
    GO: 'GO',
    UPDATE_PLATFORM_GRADE: 'Update Platform Grade',
    VIEW_SCORECARD: 'View Scorecard',
};
export const NEWS_FEED = {
    TITLE: 'News Feed ',
    ADD: 'Add News Feed Entry',
    EDIT: 'Edit News Feed',
    PLACEHOLDER: 'Add a feed',
    NO_NEWS_FEED: 'There are no news feed for this customer',
    CREATED_MESSAGE: 'News feed has been added',
    UPDATED_MESSAGE: 'News feed has been updated',
    DELETED_MESSAGE: 'News feed has been deleted',
};

export const NOTIFICATION = {
    HEADING: 'Notification',
    NO_NOTIFICATIONS: 'There are no notifications',
    MARK_ALL_READ: 'Mark all as read',
    VIEW_ALL: 'View All',
    FILTER_STRINGS: {
        ALL: 'All',
        ALL_UNREAD: 'All Unread',
        ALL_READ: 'All Read',
    },
    NOTIFICATION_TABS: {
        CSM: {
            regulatory_issue: 'Regulatory',
            document: 'Documents',
            account_deleted: 'Clients',
        },
        ADMIN: {
            account: 'Clients',
        },
        CUSTOMER: {
            regulatory_issue: 'Regulatory',
            document: 'Documents',
            scorecard_created: 'Scorecards',
        },
    },
};

export const SUBSCRIPTION_EXPIRY = {
    SUBSCRIPTION_EXPIRY_NOTIFICATION_TAB: 'Subscription',
    ABOUT_TO_EXPIRE: 'Your subscription is about to expire in',
    ABOUT_TO_EXPIRE_TITLE: 'Your subscription is about to expire.',
    EXPIRED_TITLE: 'Your subscription expired.',
    EXPIRED: 'Your subscription has expired',
    DAYS: 'days',
    DAY: 'day',
    DAYS_BEFORE: 'days before',
    DAY_BEFORE: 'day before',
    ERROR: 'Failed to update response',
};

export const DOC_REQUEST = {
    DOCS_UPLOADED: 'Documents uploaded successfully',
    DOC_UPLOADED: 'Document uploaded successfully',
    NOTES_UPDATED: 'Notes updated successfully',
    PLEASE_ADD_NOTES: 'Please add notes before proceeding',
    DOCUMENT_REQUEST_REJECTED: 'Document request rejected',
    DOCUMENT_REQUEST_APPROVED: 'Document request approved',
    DOCUMENT_REQUEST_DELETED: 'Document request deleted',
    DELETE_BUTTON: 'Document Request',
    DELETED_MESSAGE: 'Document request has been deleted',
    NO_NOTES_CREATED: 'No Notes Created',
    NOT_ASSIGNED: 'Not Assigned',
    ERROR_DOWNLOADING_FILE: 'Error downloading file:',
    ERROR_FETCHING_FILE: 'Error fetching file:',
    FILE_UPLOAD_FAILED: 'Failed to upload file',
};

export const LABEL = {
    SESSION_RESTORE: 'Do you want to restore the session ?',
};

export const FILE_VIEWER = {
    LOADING: 'Loading...',
    GOOGLE_VIEWER_URL: 'https://docs.google.com/viewer?url=',
    EMBEDED: '&embedded=true',
    OFFICE_VIEWER_URL: 'https://view.officeapps.live.com/op/view.aspx?src=',
    FILE_FETCH_FAILED: 'Failed to fetch file',
    FILE_FETCH_ERROR: 'Error fetching file',
    AVAILABLE_DOC_VIEWER_EXTNS: ['mp4', 'gif', 'png', 'bmp', 'html', 'jpg', 'jpeg'],
    AVAILABLE_GOOGLE_VIEWER_EXTNS: ['tif', 'tiff', 'csv', 'pdf', 'txt'],
};

export const EDIT_ACCOUNT = {
    TABS: ['Personal Info', 'Manage CSM', 'Manage User', 'Manage Platform & Hiring Client'],
    TABS_WITH_ROLES: [
        {
            id: 0,
            name: 'Personal Info',
            allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SALES_AGENT],
        },
        {
            id: 1,
            name: 'Manage CSM',
            allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SALES_AGENT],
        },
        {
            id: 2,
            name: 'Manage User',
            csm_name: 'Users',
            allowedRoles: [
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.CSM,
                ROLES.CONTENT_MANAGER,
                ROLES.SALES_AGENT,
            ],
        },
        {
            id: 3,
            name: 'Manage Platform & Hiring Client',
            csm_name: 'Manage Hiring Client',
            allowedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CSM],
        },
        {
            id: 4,
            name: 'Manage Sales Agent',
            allowedRoles: [ROLES.SALES_AGENT, ROLES.SUPER_ADMIN, ROLES.ADMIN],
        },
    ],
};

export const EDIT_USER = {
    TABS: [{ id: 0, name: 'Personal Info' }],
};

export const EDIT_CUSTOMER = {
    HEADING: 'Edit Client',
    POPUP_MSG: {
        HIRING_CLIENT_EXIST: 'Hiring Client Already Exist',
        NOTHING_TO_SAVE: 'No changes to save',
        PLATFORM_UPDATED: 'Platform data updated',
        PLEASE_SELECT_PLATFORM: 'Please select a Platform',
        PLATFORM_EXIST: 'Platform already exists',
        ARE_YOU_SURE: 'You want to continue with these changes?',
    },
    BUTTON_TEXT: {
        ADD_NEW_HC: 'Add New Hiring Client',
        SAVE: 'Save Changes',
        CANCEL: 'Cancel',
        ADD: 'Add',
        ADD_NEW_PLATFORM: ' Add New Platform',
        NO_PLATFORM: 'No Platform Available',
        NO_HC: 'No Hiring Clients Available',
    },
    FORM_FIELD: {
        NAME: 'Name',
        EMAIL: 'Email Address',
        PHONE: 'Phone',
    },
    PLACEHOLDER: {
        HC_NAME: 'Enter Hiring Client Name',
        EMAIL: 'Enter Email Address',
    },
    POPUP_BOX: {
        SURE: ' Are you sure ?',
        CHANGES_INFO: 'This item will be deleted immediately. You can’t undo this again.',
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
    },
};

export const NOTE_STRING = {
    NOTE: 'Note:',
    SCORECARD_DETAILS: 'The above fields are input fields, not filters.',
};

export const PROFILE_IMAGE = {
    DELETE_PROFILE_IMAGE: 'Profile Image',
    NOT_ALLOWED: 'Only png/jpg image format is allowed.',
    UPDATED: 'Details Updated',
};

export const SYMBOL = {
    REGISTERED: '®',
};

export const SOURCE = {
    PORTAL: 'Portal',
    SCHEDULAR: 'Schedular',
    ADMIN: 'Admin',
};

export const DIGITAL_SAFETY_LIBRARY = {
    PAGE_HEADING: 'Digital Safety Library',
    PAGE_NAME: 'Digital Safety Library',
    UPDATED_MESSAGE: 'Digital safety library updated',
    DELETED_MESSAGE: 'Digital safety library has been deleted',
    CREATED_MESSAGE: 'New digital safety library has been created',
    VIEW: 'View file',
    DOWNLOAD: 'Download file',
    EDIT: 'Edit file',
    DELETE: 'Delete file',
    ADD_BUTTON_TEXT: 'Add digital safety file',
    FILE_NAME: 'File Name',
    FILE_NAME_PLACEHOLDER: 'Enter File Name',
    FILE_NAME_SEARCH: 'Enter File Name to Search...',

    MODAL: {
        HEADING: 'Add Digital Delivery To A Client',
    },
};

export const SAFETY_MEETING = {
    PAGE_HEADING: 'Safety Meetings',
    PAGE_NAME: 'Safety Meetings',
    UPDATED_MESSAGE: 'Safety Meeting updated',
    DELETED_MESSAGE: 'Safety Meeting has been deleted',
    CREATED_MESSAGE: 'New safety meeting(s) has been created',
    VIEW: 'View file',
    DOWNLOAD: 'Download file',
    EDIT: 'Edit file',
    DELETE: 'Delete file',
    ADD_BUTTON_TEXT: 'Add Safety Meeting(s)',
    FILE_NAME: 'File Name',
    FILE_NAME_PLACEHOLDER: 'Enter file name...',
    FILE_NAME_SEARCH: 'Enter File Name to Search...',
};

export const LEGEND_DETAILS = [
    {
        id: '1',
        description: 'Your grade is at risk. Act immediately!',
        color: 'red',
    },
    {
        id: '2',
        description: 'Tasks are pending, but your grade is not currently at risk.',
        color: 'yellow',
    },
    {
        id: '3',
        description: 'Tasks are up to date; no action needed.',
        color: 'green',
    },
    { id: '4', description: 'You do not have a grade yet.', color: 'grey' },
];

export const HIDDEN_HIRING_CLIENTS = {
    TITLE: 'Unhide Hiring Clients',
    HIDE: 'Hide Hiring Client',
    UNHIDE: 'Unhide Hiring Client',
    SELECT: 'Select to unhide',
    MODAL: {
        HEADING: 'Unhide Hiring Clients',
        NAME: 'Unhide hiding clients modal',
    },
    BUTTONS: {
        SAVE: 'Unhide',
    },
};

export const TABLE_ID = {
    ASSIGNED_SAFETY_MEETINGS: 'assigned-safety-meetings-table',
    SAFETY_MEETINGS: 'safety-meetings-table',
};

export const NOTIFICATION_TYPES = {
    HIRING_CLIENT_GRADE_UPDATED: 'hiring_client_grade_updated',
    DOCUMENT_UPLOADED: 'document_uploaded',
    DOCUMENT_REQUESTED: 'document_requested',
    DOCUMENT_STATUS_CHANGE: 'document_status_change',
    HIRING_CLIENT_HIDDEN: 'hiring_client_hidden',
    ACCOUNT_CREATED: 'account_created',
    REGULATORY_ISSUE: 'regulatory_issue',
    CUSTOMER_FEEDBACK: 'customer_feedback',
    ACCOUNT_DELETED: 'account_deleted',
    SCORECARD_CREATED: 'scorecard_created',
};
