import React, { useEffect, useState } from 'react';
import { resetSafetyMeetingStore, setLoaderVisibility } from '../../../redux';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { ASSIGNED_SAFETY_MEETINGS_CUSTOMER_COLUMN, ROUTES } from '../../../constants';
import { AdminPageHeader } from '../../Admin';
import { Table } from '../../shared';
import { getSafetyServiceAssociationByAccountIdService } from '../../../utils/apiServices/safetyServiceAPI';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DownloadIcon } from '../../../assets/svg';
import useBulkDownloadFiles from '../../../hooks/useBulkDownloadFiles';
import YearMonthPopoverPicker from '../../shared/common/YearMonthPopoverPicker';
import useWindowSize from '../../../hooks/useWindowSize';

const INITIAL_RESPONSE = {
    data: [],
    count: 1,
};

const SafetyMeetingsCustomer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [response, setResponse] = useState(INITIAL_RESPONSE);
    const { batchDownloadFiles } = useBulkDownloadFiles();
    const { width } = useWindowSize();

    const selectedSafetyMeetings = useSelector((s) => s.safetyMeetings.selectedSafetyMeetings);
    const maxHeight = selectedSafetyMeetings.length > 1 ? 'calc(100dvh - 420px)' : '100%';
    const minHeight = width > 771 ? '48px' : '38px';
    const isUserPrimary = useSelector((s) => s.accountArray.isUserPrimary);
    const customerId = useSelector((s) => s.accountArray.selectedAccount);
    const accountName = useSelector((s) => s.accountArray.selectedAccountName);
    const pagination = usePagination(response.data.length, response.count, 50);
    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState({ month: '', year: '' });

    const debouncedApiCall = useDebounce(async () => {
        try {
            const response = await getSafetyServiceAssociationByAccountIdService({
                offset: pagination.offset,
                limit: pagination.limit,
                search: search,
                accountId: customerId,
                year: dateFilter.year,
                month: dateFilter.month,
            });
            setResponse({
                data: response.data,
                count: response.count,
            });
        } catch (error) {
            console.warn('error', error.message + ` for ${accountName}`);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search, customerId, dateFilter]);

    useEffect(() => {
        if (!isUserPrimary) {
            navigate(ROUTES.CUSTOMER.DASHBOARD);
        }

        return () => {
            setResponse(INITIAL_RESPONSE);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserPrimary, customerId]);

    const bulkDownloadHandler = () => {
        const data = selectedSafetyMeetings.map((safetyMeeting) => ({
            s3_key: safetyMeeting.s3_key,
            logoUrl: '',
            customerName: accountName,
            fileName: safetyMeeting.file_name,
        }));
        batchDownloadFiles(data);
        dispatch(resetSafetyMeetingStore());
    };

    return (
        <>
            <AdminPageHeader
                heading={`${accountName}'s Safety Meetings`}
                backbutton
                showSearchBar
                searchBarPlaceholder={'Search Safety Meetings...'}
                count={pagination.dataCount}
                setSearch={setSearch}
                searchAdjacentChildren={
                    <div className={width < 771 ? 'd-flex w-100 justify-content-end' : ''}>
                        <YearMonthPopoverPicker
                            onChange={(v) => {
                                if (v) {
                                    const [month, year] = v.split('/');
                                    setDateFilter({ month, year });
                                } else {
                                    setDateFilter({ month: '', year: '' });
                                }
                            }}
                            selectStyle={{ width: '120px', minHeight: minHeight }}
                            label={'Assigned Month/Year:'}
                            title={'Filter Meetings By Month/Year'}
                        />
                    </div>
                }
            />

            <Table
                columns={ASSIGNED_SAFETY_MEETINGS_CUSTOMER_COLUMN}
                data={response.data}
                pagination={pagination}
                pageName='safetyMeeting(s)'
                debouncedApiCall={debouncedApiCall}
                tableStyle={{ maxHeight: maxHeight }}
                stickyColumns={['File Name', 0]}
            />
            {selectedSafetyMeetings.length > 1 && (
                <>
                    <div className='divider mb-3'></div>
                    <div className='d-flex justify-content-center w-100' style={{ height: '56px' }}>
                        <button
                            className='ssc-primary-green-btn'
                            type='button'
                            onClick={bulkDownloadHandler}
                            style={{
                                width: 200,
                                height: 40,
                                fontSize: 16,
                                color: 'white',
                            }}
                            title='Download all as ZIP'
                        >
                            <span className='d-flex align-items-center gap-2'>
                                <span>Download all</span>
                                <DownloadIcon />
                            </span>
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default SafetyMeetingsCustomer;
