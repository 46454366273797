import { isEmpty } from 'lodash';
import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';

export const signedSafetyServiceFile = ({ signedData }) => {
    const url = `${API.SIGNED_SM_URL}`;
    return fetchData({ body: signedData, method: METHODS.POST, url });
};

export const createSafetyMeetingsService = ({ data }) => {
    const url = `${API.SAFETY_MEETINGS}`;
    return fetchData({ body: data, method: METHODS.POST, url });
};

export const updateSafetyMeetingsService = ({ data, id }) => {
    const url = `${API.SAFETY_MEETINGS}/${id}`;
    return fetchData({ body: data, method: METHODS.PUT, url });
};

export const getAllSafetyMeetingService = ({ offset, limit, search = '', filter }) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        filter: !isEmpty(filter) ? encodeURIComponent(JSON.stringify(filter)) : '',
    };
    const url = constructURL(API.SAFETY_MEETINGS, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

export const deleteSafetyMeetingService = async ({ data }) => {
    const url = `${API.SAFETY_MEETINGS}`;
    return fetchData({ body: data, method: METHODS.DELETE, url });
};

/// ------------ SAFETY MEETING ASSOCIATIONS ------------- ///

export const createSafetyMeetingAssociationService = ({ data }) => {
    const url = `${API.SAFETY_MEETING_ASSOCIATIONS}`;
    return fetchData({ body: data, method: METHODS.POST, url });
};

export const getSafetyServiceAssociationByAccountIdService = async ({
    offset,
    limit,
    search = '',
    filter,
    accountId,
    year,
    month,
}) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        filter: !isEmpty(filter) ? encodeURIComponent(JSON.stringify(filter)) : '',
        month: month ? month : '',
        year: year ? year : '',
    };
    const id = accountId;
    const url = constructURL(`${API.SAFETY_MEETING_ASSOCIATIONS}/${id}`, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

export const getAllSafetyMeetingAssociationService = async ({
    offset,
    limit,
    search = '',
    filter,
}) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        filter: !isEmpty(filter) ? encodeURIComponent(JSON.stringify(filter)) : '',
    };
    const url = constructURL(API.SAFETY_MEETING_ASSOCIATIONS, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

export const deleteSafetyMeetingAssociationService = async ({ accountId, ids = [] }) => {
    const url = `${API.SAFETY_MEETING_ASSOCIATIONS}`;
    const data = { account_id: accountId, ids };
    return fetchData({ body: data, method: METHODS.DELETE, url });
};
