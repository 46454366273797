import React from 'react';
import PropTypes from 'prop-types';

export const RadioSelector = ({
    label,
    required,
    options,
    selectedData,
    setSelectedData,
    mainClass,
}) => {
    return (
        <div className={mainClass}>
            <span className='d-flex flex-row align-items-center d-flex'>
                <label>{label}</label>
                {required && <span className='text-danger ms-1'>*</span>}
            </span>
            <div className='d-flex align-items-center flex-wrap priority-box-set'>
                {options.map((option) => (
                    <div
                        key={option}
                        className={`box-container-set d-flex align-items-center justify-content-center ${selectedData === option ? 'active ' : ''}`}
                    >
                        <label className='container-custom'>
                            {option}
                            <input
                                type='radio'
                                name='radio'
                                checked={selectedData === option}
                                onChange={() => setSelectedData(option)}
                            />
                            <span className='checkmark'></span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

RadioSelector.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.array,
    selectedData: PropTypes.string,
    setSelectedData: PropTypes.func,
};
