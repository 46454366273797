import { PDFDocument, rgb } from 'pdf-lib';
import JSZip from 'jszip';
import { useDispatch } from 'react-redux';
import { setLoaderProgress, setLoaderVisibility, setShowProgress } from '../redux';
import { getContentTypeByExtension, getFileSignedUrl, popup } from '../utils';
import { isEmpty } from 'lodash';
import { concurrencyQueue, downloadFileWithRetry } from './constants';

const useBulkDownloadFiles = () => {
    const dispatch = useDispatch();

    const batchDownloadFiles = async (filesData = []) => {
        try {
            if (!Array.isArray(filesData)) {
                return popup('error', 'Invalid File Data, should be an array');
            }

            dispatch(setLoaderVisibility(true));
            dispatch(setShowProgress(true));

            const zip = new JSZip();
            const isSingleFile = filesData.length === 1;
            let finalFile = null;
            let completed = 0;

            // Step 1: Process each file
            const processFile = async ({ s3_key, logoUrl, customerName, fileName }) => {
                try {
                    const { data } = await getFileSignedUrl({ filePath: s3_key });

                    const pdfBytes = await downloadFileWithRetry(data.url);

                    const pdfDoc = await PDFDocument.load(pdfBytes);
                    const pages = pdfDoc.getPages();
                    const firstPage = pages[0];
                    const { height } = firstPage.getSize();

                    const logoX = 40; // Padding from the left edge
                    const logoY = height - 35; // Padding from the top edge

                    if (!isEmpty(logoUrl)) {
                        const { data: logoData } = await getFileSignedUrl({ filePath: logoUrl });
                        if (logoData && logoData.url) {
                            const logoBytes = await downloadFileWithRetry(logoData.url);
                            const logoImage = await pdfDoc.embedPng(logoBytes);
                            firstPage.drawImage(logoImage, {
                                x: logoX,
                                y: logoY - 50, // Adjust height to fit the logo
                                width: 150,
                                height: 50,
                            });
                        }
                    } else {
                        firstPage.drawText(customerName, {
                            x: logoX,
                            y: logoY,
                            size: 16,
                            color: rgb(0, 0, 0),
                        });
                    }

                    const modifiedPdfBytes = await pdfDoc.save();

                    if (isSingleFile) {
                        finalFile = new Blob([modifiedPdfBytes], {
                            type: getContentTypeByExtension('pdf'),
                        });
                    } else {
                        zip.file(`${fileName}.pdf`, modifiedPdfBytes);
                    }

                    completed += 1;
                    dispatch(setLoaderProgress((completed / filesData.length) * 100));
                } catch (error) {
                    console.error(`Error processing file ${fileName}:`, error);
                    popup('error', `Error processing file ${fileName}: ${error.message}`);
                    completed += 1;
                    dispatch(setLoaderProgress((completed / filesData.length) * 100));
                }
            };

            const tasks = filesData.map((file) => () => processFile(file));
            await concurrencyQueue(tasks);

            // Step 3: Generate the ZIP file
            if (isSingleFile) {
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(finalFile);
                downloadLink.download = filesData[0].fileName;
                downloadLink.click();
            } else {
                const zipBlob = await zip.generateAsync({ type: 'blob' });
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(zipBlob);
                downloadLink.download = 'ssc_safety_meetings.zip';
                downloadLink.click();
            }
        } catch (error) {
            popup('error', error.message);
            console.error('Error processing PDFs:', error);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    return {
        batchDownloadFiles,
    };
};

export default useBulkDownloadFiles;
