import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import Select from '../Forms/FormSelect';
import { Button, Modal } from 'react-bootstrap';
import { useDebounce } from '../../../hooks';
import {
    getAllSafetyMeetingService,
    updateSafetyMeetingsService,
} from '../../../utils/apiServices/safetyServiceAPI';
import { setLoaderVisibility } from '../../../redux';
import { useDispatch } from 'react-redux';
import { popup } from '../../../utils';

export const ReplaceSafetyMeetingModal = ({ showModal, setShowModal, id, fileName }) => {
    const dispatch = useDispatch();
    const [safetyMeetings, setSafetyMeetings] = useState(undefined);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState('');

    const getAllSafetyMeetings = useDebounce(async () => {
        try {
            setLoading(true);
            const response = await getAllSafetyMeetingService({
                search: search,
                limit: 50,
            });
            const options = response.data.map((safetyMeeting) => ({
                value: safetyMeeting.s3_key,
                label: safetyMeeting.file_name,
            }));
            setSafetyMeetings(options);
        } catch (error) {
            popup('error', error.message);
        } finally {
            setLoading(false);
        }
    }, 500);

    useEffect(() => {
        showModal && getAllSafetyMeetings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, showModal]);

    return (
        <>
            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
                dialogClassName='overflow-hidden'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='me-2'>{`Replace ${fileName}`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={{
                            s3_key: '',
                        }}
                        onSubmit={async ({ s3_key }, { setSubmitting }) => {
                            try {
                                dispatch(setLoaderVisibility(true));
                                setSubmitting(true);
                                await updateSafetyMeetingsService({
                                    data: {
                                        s3_key: s3_key?.value,
                                    },
                                    id,
                                });
                                setShowModal(false);
                                popup(
                                    'success',
                                    `${fileName}'s link replaced with ${s3_key?.label}`,
                                );
                            } catch (error) {
                                popup('error', error.message);
                            } finally {
                                setSubmitting(false);
                                dispatch(setLoaderVisibility(false));
                            }
                        }}
                    >
                        {({ values, isSubmitting, handleSubmit }) => (
                            <form action=''>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between'>
                                        <Field
                                            name='s3_key'
                                            label='Safety Files'
                                            options={safetyMeetings}
                                            placeholder='Select a file...'
                                            component={Select}
                                            width={'100%'}
                                            isClearable={!!values.s3_key}
                                            onInputChange={setSearch}
                                            isSearchable
                                            isLoading={loading}
                                            col={12}
                                        />
                                    </div>
                                    <div className='divider mt-2 mb-3'></div>
                                    <div className='d-flex w-100 align-items-center justify-content-end modal-btn'>
                                        <Button
                                            type='submit'
                                            onClick={handleSubmit}
                                            className='table-footer-btn'
                                            disabled={!values.s3_key || isSubmitting}
                                        >
                                            Replace
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

ReplaceSafetyMeetingModal.propTypes = {
    id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    setShowModal: PropTypes.func.isRequired,
    debouncedApiCall: PropTypes.func,
    showModal: PropTypes.bool.isRequired,
};
