import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EDIT_CUSTOMER as EDIT, INFO, ROLE_NAME, ROLES, SALES_AGENT } from '../../../constants';
import { getAllCSM, popup, updateAccount } from '../../../utils';
import { Edit } from '../../../assets/svg';
import useWindowSize from '../../../hooks/useWindowSize';
import SSCSelect from '../../shared/SSCSelect';
import { useDebounce } from '../../../hooks';
import { useSelector } from 'react-redux';

export const ManageSalesAgentBox = ({ data, debouncedApiCall }) => {
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const [selectData, setSelectData] = useState(null);
    const [edit, setEdit] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const { width } = useWindowSize();
    const isSmallScreen = width < 720;
    const role = useSelector((s) => s.user.role);

    const debouncedCall = useDebounce(() => {
        getAllCSM({
            limit: 50,
            offset: 1,
            search: search,
            role: [ROLES.SALES_AGENT],
        })
            .then((res) => {
                if (res?.data) {
                    setResponse({ data: res.data, count: res.count });
                }
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        edit && debouncedCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, data?.account?.account_id, edit]);

    const handleSave = () => {
        setIsDisabled(true);
        updateAccount({
            body: {
                sales_agent_id: selectData?.sales_agent_id,
            },
            id: data.account?.account_id,
        })
            .then((response) => {
                popup('success', response.message);
                debouncedApiCall();
                setEdit((prev) => !prev);
            })
            .catch((error) => {
                popup('error', error.message);
            })
            .finally(() => {
                setIsDisabled(false);
            });
    };

    const handleEdit = () => {
        setEdit(true);
    };

    const handleDiscard = () => {
        setEdit(false);
        setSelectData(null);
    };

    useEffect(() => {
        if (data?.account?.sales_agent_name) {
            setSelectData({
                sales_agent_id: data.account.sales_agent_id,
                full_name: data.account.sales_agent_name,
            });
        }
    }, [data?.account]);

    return (
        <>
            <div className='tabs-set h-25'>
                <div className='d-flex justify-content-end '>
                    <div>
                        {!edit && role !== ROLES.SALES_AGENT && (
                            <span title='Edit' id='action-btn' onClick={handleEdit}>
                                <Edit className='cursor-pointer' />
                            </span>
                        )}
                    </div>
                </div>
                {!data.account.sales_agent_name && !edit && (
                    <div
                        className='mt-4 vh-50 d-flex justify-content-center'
                        style={{ fontSize: '16px' }}
                    >
                        {INFO.NO_SALES_AGENT_FOUND}
                    </div>
                )}
                <div className='form-area mt-4'>
                    {edit ? (
                        <div className='d-flex flex-column gap-3 px-2'>
                            <div className={isSmallScreen ? 'w-100' : 'w-50'}>
                                <SSCSelect
                                    label={SALES_AGENT.PAGE_NAME}
                                    placeholder={
                                        data.account.sales_agent_name
                                            ? data.account.sales_agent_name
                                            : SALES_AGENT.SELECT_SALES_AGENT
                                    }
                                    options={response.data?.map((ele) => ({
                                        label: ele.full_name,
                                        value: { sales_agent_id: ele.id, full_name: ele.full_name },
                                    }))}
                                    value={
                                        selectData
                                            ? { label: selectData?.full_name, value: selectData }
                                            : null
                                    }
                                    onChange={(d) => setSelectData(d?.value)}
                                    onInputChange={(v) => {
                                        setSearch(v);
                                    }}
                                    isClearable
                                />
                            </div>

                            <div className='d-flex flex-wrap gap-2 align-items-center justify-content-end mb-1'>
                                <button
                                    onClick={handleDiscard}
                                    className={`form-white-button d-flex align-items-center justify-content-center border-0 cursor-pointer me-3`}
                                >
                                    {EDIT.BUTTON_TEXT.CANCEL}
                                </button>
                                <button
                                    onClick={handleSave}
                                    className='form-button d-flex align-items-center justify-content-center border-0'
                                    disabled={
                                        isDisabled ||
                                        !selectData ||
                                        selectData?.sales_agent_id === data?.account?.sales_agent_id
                                    }
                                >
                                    {EDIT.BUTTON_TEXT.SAVE}
                                </button>
                            </div>
                        </div>
                    ) : (
                        data.account.sales_agent_name && (
                            <div className='d-flex  border-bottom justify-content-start ps-5 '>
                                <div className='d-flex ps-4 flex-column mb-3 input-set '>
                                    <label> {ROLE_NAME.SALES_AGENT}</label>
                                    <span>{data?.account?.sales_agent_name}</span>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};
ManageSalesAgentBox.propTypes = {
    data: PropTypes.object,
    debouncedApiCall: PropTypes.func,
};
