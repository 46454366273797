import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { registerAccount, popup, getAllCSM, formValidator } from '../../../utils';
import {
    REGEX,
    VALIDATION,
    MAX_LENGTH,
    AUTH,
    CUSTOMER_LIST,
    DOCUMENTS,
    MODAL,
    BUTTONS,
    SOURCE,
} from '../../../constants';
import { useDebounce } from '../../../hooks';
import SSCSelect from '../SSCSelect';

export const AddNewCustomer = ({ showAddModal, setShowAddModal, debouncedApiCall }) => {
    const [csm, setCsm] = useState([]);
    const [selectCsm, setSelectCsm] = useState({ id: '', name: '' });
    const [searchCsm, setSearchCsm] = useState('');
    const [isFormFocused, setIsFormFocused] = useState(false);

    const validationSchema = {
        customerName: {
            regex: REGEX.ALL_LANG_NAME,
            message: VALIDATION.CUSTOMER_NAME,
            requiredMessage: VALIDATION.CUSTOMER_NAME_REQUIRED,
        },
        phone: {
            regex: REGEX.PHONE,
            message: VALIDATION.INVALID_PHONE,
            isNotRequired: true,
        },
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            isNotRequired: true,
        },
    };

    const debouncedFetchData = useDebounce(async () => {
        getAllCSM({ limit: 30, offset: 1, search: searchCsm })
            .then((res) => {
                if (res?.data) setCsm(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedFetchData();
    }, [searchCsm]);

    const validateForm = () => setIsFormFocused(true);

    return (
        <Formik
            initialValues={{
                customerName: '',
                phone: '',
                email: '',
            }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema });
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                const data = {
                    name: values.customerName,
                    ...(values.phone && { phone: values.phone }),
                    ...(selectCsm?.id && { csm_id: selectCsm.id }),
                    source: SOURCE.ADMIN,
                };
                if (values?.email) data.email = values?.email;
                registerAccount({
                    body: data,
                })
                    .then((response) => {
                        setSubmitting(false);
                        setShowAddModal(false);
                        debouncedApiCall();
                        popup('success', response.message);
                    })
                    .catch((error) => {
                        setSubmitting(false);
                        popup('error', error.message);
                    });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
            }) => (
                <div
                    className={`modal custom-modal-doc-cato fade ${showAddModal ? 'show' : ''}`}
                    id='exampleModal-add'
                    tabIndex={-1}
                    aria-labelledby='exampleModalLabel'
                    aria-hidden={!showAddModal}
                    style={{ display: showAddModal ? 'block' : 'none' }}
                >
                    <div className='modal-dialog w-100 custom-wid '>
                        <div className={`modal-content border-0`}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='head-title-edit'>
                                    {CUSTOMER_LIST.ADD_CUSTOMER}
                                </span>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                    onClick={() => {
                                        setShowAddModal(false);
                                    }}
                                />
                            </div>
                            <div
                                className='modal-body customer-form p-0'
                                onFocus={() => validateForm()}
                            >
                                <form action='' className='customer-from-content'>
                                    <div className='d-flex flex-column gap-2'>
                                        <div className='d-flex flex-column flex-sm-row gap-2'>
                                            <div className='input-set-area w-100 w-sm-50'>
                                                <label htmlFor='customerName'>
                                                    {CUSTOMER_LIST.CUSTOMER_NAME}
                                                    <span
                                                        title='Required'
                                                        className='text-danger ms-1'
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    required
                                                    maxLength={MAX_LENGTH.NAME}
                                                    type='text'
                                                    name='customerName'
                                                    onChange={(e) => {
                                                        !touched.customerName &&
                                                            setTouched({
                                                                ...touched,
                                                                customerName: true,
                                                            });
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.customerName}
                                                    className={`new-customer-input input-area-set form-control ${isFormFocused && errors.customerName && touched.customerName && errors.customerName ? 'border border-danger-subtle border-1' : ''}`}
                                                    placeholder={AUTH.ENTER_CUSTOMER_NAME}
                                                />
                                                {isFormFocused &&
                                                    touched.customerName &&
                                                    errors.customerName && (
                                                        <p className='error-message'>
                                                            {errors.customerName}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className='input-set-area w-100 w-sm-50'>
                                                <label htmlFor='phoneNumber'>
                                                    {AUTH.PHONE_NUMBER}
                                                </label>
                                                <input
                                                    maxLength={MAX_LENGTH.PHONE}
                                                    type='text'
                                                    name='phone'
                                                    onChange={(e) => {
                                                        !touched.phone &&
                                                            setTouched({
                                                                ...touched,
                                                                phone: true,
                                                            });
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.phone}
                                                    className={`new-customer-input input-area-set form-control ${isFormFocused && errors.phone && touched.phone && errors.phone ? 'border border-danger-subtle border-1' : ''}`}
                                                    placeholder='phone Number'
                                                />
                                                {isFormFocused && touched.phone && errors.phone && (
                                                    <p className='error-message'>{errors.phone}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column flex-sm-row gap-2'>
                                            <div className='input-set-area w-100 w-sm-50'>
                                                <label htmlFor='emailAddress'>
                                                    {AUTH.EMAIL_ADDRESS}
                                                </label>
                                                <input
                                                    // required
                                                    maxLength={MAX_LENGTH.EMAIL}
                                                    type='email'
                                                    name='email'
                                                    onChange={(e) => {
                                                        !touched.email &&
                                                            setTouched({ ...touched, email: true });
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    className={`new-customer-input input-area-set form-control ${isFormFocused && errors.email && touched.email && errors.email ? 'border border-danger-subtle border-1' : ''}`}
                                                    placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                                />
                                                {isFormFocused && touched.email && errors.email && (
                                                    <p className='error-message'>{errors.email}</p>
                                                )}
                                            </div>
                                            <SSCSelect
                                                label={DOCUMENTS.CSM}
                                                options={csm.map((csmData) => ({
                                                    label: csmData.full_name,
                                                    value: csmData,
                                                }))}
                                                searchable
                                                placeholder='Search...'
                                                onChange={(d) =>
                                                    setSelectCsm({
                                                        id: d?.value.id,
                                                        name: d?.value.full_name,
                                                    })
                                                }
                                                onInputChange={setSearchCsm}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='d-flex align-items-center justify-content-end modal-btn mt-4'>
                                <button
                                    type='button'
                                    className='ssc-secondary-white-btn '
                                    onClick={() => {
                                        setShowAddModal(false);
                                    }}
                                >
                                    {MODAL.CANCEL_BUTTON}
                                </button>
                                <button
                                    type='submit'
                                    onClick={handleSubmit}
                                    className='ssc-primary-green-btn py-0 w-auto disabled-hover'
                                    disabled={
                                        isSubmitting ||
                                        !dirty ||
                                        errors.name ||
                                        errors.email ||
                                        errors.phone
                                    }
                                >
                                    {BUTTONS.SAVE_BUTTON}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

AddNewCustomer.propTypes = {
    showAddModal: PropTypes.bool,
    setShowAddModal: PropTypes.func,
};
