import React from 'react';
import PropTypes from 'prop-types';
import { RightArrow, LeftArrow } from '../../../assets/svg';

export const PaginationBar = ({ pageName, pagination }) => {
    //TODO: itemsPerPage design
    //TODO  limit and offset get outside the component
    //TODO: testing

    const {
        dataCount,
        currentPage,
        itemsPerPage,
        updateItemsPerPage,
        totalPages,
        handlePrevPage,
        handleNextPage,
    } = pagination;

    return (
        <section
            id='Pagination'
            className='ssc-table-pagination-bar d-flex align-items-center justify-content-between mb-2 mb-md-4'
        >
            <p className='left-number-set mb-0'>
                <span>
                    {currentPage !== totalPages
                        ? `${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, dataCount)}`
                        : `${(currentPage - 1) * itemsPerPage + 1}-${dataCount}`}
                </span>
                of <span>{dataCount}</span> <span className='d-none d-sm-block'>{pageName}</span>
            </p>

            <div className='right-number-set'>
                <select
                    className='select-box-new cursor-pointer'
                    type='button'
                    name='item-per-page'
                    id='itemPerPage'
                    value={itemsPerPage}
                    onChange={(e) => {
                        updateItemsPerPage(Number(e.target.value));
                    }}
                >
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                </select>
                <button
                    disabled={currentPage === 1}
                    className='arrow d-flex align-items-center border-0 p-0 bg-white'
                    onClick={handlePrevPage}
                >
                    <LeftArrow opacity={currentPage === 1 ? 0.5 : 1} />
                </button>
                <h6 className='number-set mb-0'>
                    Page{' '}
                    <span>
                        {currentPage} of {totalPages}
                    </span>
                </h6>
                <button
                    disabled={currentPage === totalPages}
                    className='arrow d-flex align-items-center border-0 p-0 bg-white'
                    onClick={handleNextPage}
                >
                    <RightArrow opacity={currentPage === totalPages ? 0.5 : 1} />
                </button>
            </div>
        </section>
    );
};

PaginationBar.propTypes = {
    pageName: PropTypes.string.isRequired,
    pagination: PropTypes.shape({
        dataLength: PropTypes.number.isRequired,
        dataCount: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        itemsPerPage: PropTypes.number.isRequired,
        updateItemsPerPage: PropTypes.func.isRequired,
        totalPages: PropTypes.number.isRequired,
        handlePrevPage: PropTypes.func.isRequired,
        handleNextPage: PropTypes.func.isRequired,
    }).isRequired,
};
