import React from 'react';
import PropTypes from 'prop-types';
import { CardRightArrow } from '../../../assets/svg';
import { startCase } from 'lodash';

export default function NameBox({ name, mail, onClickNext }) {
    return (
        <div
            className='content-set d-flex w-100 align-items-center justify-content-between cursor-pointer'
            type='button'
            onClick={onClickNext}
        >
            <div
                className='d-flex align-items-start justify-content-start flex-column'
                style={{ width: '90%' }}
            >
                <h5 className='mb-0'>{startCase(name)}</h5>
                <span className='namebox-email' title={mail?.toLowerCase()}>
                    {mail?.toLowerCase()}
                </span>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
                <CardRightArrow />
            </div>
        </div>
    );
}

NameBox.propTypes = {
    name: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
};
