import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';

import { ButtonComponent } from '../../shared';
import { BackArrow, IconPlus } from '../../../assets/svg';
import { setLoaderVisibility } from '../../../redux';
import { handleGradeSelectFn, handleTPASelectFn } from './ScoreCardsFunctions';
import { SYMBOL } from '../../../constants';
import useWindowSize from '../../../hooks/useWindowSize';
import SSCSelect from '../../shared/SSCSelect';
import InputWithSearch from '../../shared/common/InputWithSearch';

export const AdminScoreCardHeader = ({
    platforms,
    availableGrades,
    setSelectedPlatform,
    setSearch,
    debouncedApiCall,
    grade,
    selectedPlatform,
    setGrade,
    handleAddClick,
    heading,
    buttonText,
    searchBarPlaceholder,
    search,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initPlatform, setInitPlatform] = useState(null);
    const { width } = useWindowSize();

    const handleGradeSelect = (item) => {
        dispatch(setLoaderVisibility(true));
        handleGradeSelectFn({
            setSearchQuery: setSearch,
            setGrade: setGrade,
        })(item);
        debouncedApiCall();
    };

    const handleSearchChange = (event) => {
        const searchQuery = event.target.value;
        setSearch && setSearch(searchQuery);
    };

    const handleBackButton = () => {
        navigate(-1);
    };

    const handleTPASelect = handleTPASelectFn({
        setSearchQuery: setSearch,
        setTpaValue: setInitPlatform,
        setSelectedTpa: setSelectedPlatform,
    });

    useEffect(() => {
        if (platforms.length > 0 && !initPlatform) {
            platforms.unshift({ id: '', name: 'All Platforms' });
            setInitPlatform(platforms[0]);
            setSelectedPlatform(platforms[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platforms, setGrade]);

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initPlatform, setGrade]);

    return (
        <>
            <div className='title-btn mb-4 mt-0 d-flex align-items-center justify-content-between'>
                <ButtonComponent
                    className='d-flex align-items-center border-0 bg-white'
                    clickHandler={handleBackButton}
                >
                    <BackArrow />
                    <span style={{ marginLeft: '10px' }} className='font-16px'>
                        {heading}
                    </span>
                </ButtonComponent>
                {width > 575 ? (
                    <ButtonComponent
                        clickHandler={handleAddClick}
                        className='ssc-primary-green-btn w-auto'
                        type='button'
                    >
                        {buttonText}
                    </ButtonComponent>
                ) : (
                    <span id='action-btn-green' title={buttonText} onClick={handleAddClick}>
                        <IconPlus />
                    </span>
                )}
            </div>
            <div className='mb-3'>
                <div className='ssc-scorecard-box'>
                    <div className='p-2 name-box-set '>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex w-100 gap-1 gap-sm-2 gap-md-4 flex-wrap flex-md-nowrap align-items-center justify-content-start'>
                                <InputWithSearch
                                    search={search}
                                    setSearch={setSearch}
                                    onChange={handleSearchChange}
                                    placeholder={searchBarPlaceholder}
                                    mainStyle={{ maxWidth: '600px' }}
                                    className={width > 680 ? 'w-50' : 'w-100'}
                                />
                                <SSCSelect
                                    placeholder={'Select Grade'}
                                    mainStyle={{ minWidth: '140px', zIndex: '199' }}
                                    options={availableGrades.map((grade) => ({
                                        value: grade,
                                        label: capitalize(grade),
                                    }))}
                                    onChange={(d) => handleGradeSelect(d?.value)}
                                    value={{ value: grade, label: capitalize(grade) }}
                                    formatOptionLabel={({ value, label }) => {
                                        return label !== 'All grades' ? (
                                            <div className='d-flex align-items-center gap-2'>
                                                <div className={`dot-circle ${value}`}></div>
                                                {label}
                                            </div>
                                        ) : (
                                            label
                                        );
                                    }}
                                    isOptionSelected={(data) => data?.value === grade}
                                />
                                <SSCSelect
                                    placeholder={'Select Platform'}
                                    mainStyle={{ minWidth: '140px', zIndex: '198' }}
                                    options={platforms.map((platform) => ({
                                        label: platform.name,
                                        value: platform,
                                    }))}
                                    value={{
                                        value: selectedPlatform,
                                        label: selectedPlatform.name,
                                    }}
                                    onChange={(d) => handleTPASelect(d?.value)}
                                    isOptionSelected={(data) =>
                                        data?.value?.id === selectedPlatform?.id
                                    }
                                    formatOptionLabel={({ label }) => {
                                        return (
                                            <div className='text-txt'>
                                                <span>{label}</span>
                                                {label !== 'All Platforms' && (
                                                    <sup>{SYMBOL.REGISTERED}</sup>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AdminScoreCardHeader.propTypes = {
    platforms: PropTypes.array,
    availableGrades: PropTypes.array,
    setSelectedPlatform: PropTypes.func,
    setSearch: PropTypes.func,
    debouncedApiCall: PropTypes.func,
    grade: PropTypes.string,
    setGrade: PropTypes.func,
    handleAddClick: PropTypes.func,
    heading: PropTypes.string,
    buttonText: PropTypes.string,
    searchBarPlaceholder: PropTypes.string,
};
