import { isEmpty } from 'lodash';
import { API, METHODS, ROLES } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';
export const getAllAdmin = ({ offset, limit, search = '', filter }) => {
    let url = `${API.USER}?offset=${offset}&limit=${limit}&filter=${JSON.stringify(filter ? filter : { role: [ROLES.ADMIN, ROLES.SUPER_ADMIN] })}`;
    if (search) url = `${url}&search=${encodeURIComponent(search)}`;

    return fetchData({ method: METHODS.GET, url });
};

export const getAllCSM = ({ offset, limit, search = '', filter, role }) => {
    if (!isEmpty(role) && !Array.isArray(role)) {
        throw new Error('role must be an array');
    }
    const roleFilter = role || [ROLES.CSM, ROLES.CONTENT_MANAGER, ROLES.SALES_AGENT];
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        filter: !isEmpty(filter)
            ? encodeURIComponent(JSON.stringify({ ...filter, role: roleFilter }))
            : encodeURIComponent(JSON.stringify({ role: roleFilter })),
    };

    const url = constructURL(API.USER, queryParams);
    return fetchData({ method: METHODS.GET, url });
};

export const updateCSMStatus = ({ ids, status }) => {
    let url = `${API.USER}`;
    return fetchData({ method: METHODS.PUT, url, body: { status, ids } });
};

export const getApplicationOverview = ({ newRegistrationRange, newDocumentRange }) => {
    let url = `${API.OVERVIEW}?new_registration_range=${newRegistrationRange}&new_document_range=${newDocumentRange}`;
    return fetchData({ method: METHODS.GET, url });
};

export const getAllRegulatoryIssues = ({ limit, offset }) => {
    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        get_regulatory_for_portal: true,
        filter: encodeURIComponent(JSON.stringify({ type: 'regulatory_issue' })),
    };

    const url = constructURL(API.NOTIFICATION, queryParams);

    return fetchData({ method: METHODS.GET, url });
};

export const deleteRegulatoryIssues = ({ id }) => {
    let url = `${API.NOTIFICATION}${API.REGULATORY}/${id}`;
    return fetchData({ method: METHODS.DELETE, url });
};

export const addNewRegulatoryIssues = ({ body }) => {
    let url = `${API.NOTIFICATION}${API.REGULATORY}`;
    return fetchData({ body, method: METHODS.POST, url });
};

export const editRegulatoryIssues = ({ id, body }) => {
    let url = `${API.NOTIFICATION}${API.REGULATORY}/${id}`;
    return fetchData({ body, method: METHODS.PUT, url });
};

export const broadcastRegulatoryIssues = ({ id }) => {
    const body = { broadcast: true };
    let url = `${API.NOTIFICATION}${API.REGULATORY}/${id}`;
    return fetchData({ body, method: METHODS.PUT, url });
};
