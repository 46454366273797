import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';

export const TextArea = forwardRef(
    (
        {
            label = '',
            placeholder,
            note,
            setNote,
            className,
            rows = 4,
            labelClass,
            mainClass = 'mb-4 w-100',
            ...props
        },
        ref,
    ) => {
        const textAreaRef = useRef(null);

        // Function to adjust height dynamically
        const adjustHeight = () => {
            if (textAreaRef.current) {
                textAreaRef.current.style.height = '3.5rem'; // Initial height (minimum height)
                textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 6}px`; // Dynamically adjust height
            }
        };

        // Sync forwarded ref with local ref
        useEffect(() => {
            if (ref) {
                if (typeof ref === 'function') {
                    ref(textAreaRef.current);
                } else {
                    ref.current = textAreaRef.current;
                }
            }
        }, [ref]);

        // Adjust height when the value changes
        useEffect(() => {
            adjustHeight();
        }, [note]);

        return (
            <div className={mainClass}>
                {label && <label className={labelClass}>{label}</label>}
                {label && props?.required && <span className='text-danger ms-1'>*</span>}
                <textarea
                    id='ssc-textarea'
                    ref={textAreaRef}
                    onChange={(e) => setNote(e.target.value)}
                    rows={rows}
                    className={className || 'form-control'}
                    value={note}
                    placeholder={placeholder}
                    {...props}
                />
            </div>
        );
    },
);

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    note: PropTypes.string,
    setNote: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
};
