import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { customStyles, customTheme } from './Forms/FormSelect';

const styles = (selectStyle) => ({
    ...customStyles,
    control: (provided, { isDisabled }) => ({
        ...provided,
        minHeight: '46px',
        background: isDisabled ? 'rgba( 255, 255,0,0.07)' : 'var(--col-select-white)',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ...selectStyle,
    }),
});

const SSCSelect = ({
    ref,
    options,
    value,
    onChange,
    onInputChange,
    placeholder,
    isDisabled,
    isClearable,
    className,
    error,
    isMulti,
    selectStyle,
    mainStyle,
    labelStyle,
    name,
    label,
    menuPortalTarget,
    required,
    menuPlacement = 'auto',
    formatOptionLabel,
    isLoading,
    labelClass,
    isOptionSelected = () => {},
    isSearchable,
    hideSelectedOptions,
    ...props
}) => {
    return (
        <div
            className={`ssc-select-wrapper ${className} ${error ? 'has-error' : ''} ${isDisabled ? 'cursor-na' : ''}`}
            style={mainStyle}
        >
            {label && (
                <label htmlFor={name} className={labelClass} style={labelStyle}>
                    {label}
                    {required && (
                        <span className='text-danger ms-1' title='Field is required'>
                            *
                        </span>
                    )}
                </label>
            )}
            <Select
                name={name}
                ref={ref}
                isMulti={isMulti}
                options={options}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                isDisabled={isDisabled}
                isClearable={isClearable}
                className={`${error ? 'error' : ''}`}
                styles={styles(selectStyle)}
                menuPortalTarget={menuPortalTarget}
                required={required}
                theme={customTheme}
                onInputChange={onInputChange}
                menuPlacement={menuPlacement}
                classNamePrefix={'ssc-select'}
                formatOptionLabel={formatOptionLabel}
                isLoading={isLoading}
                isOptionSelected={isOptionSelected}
                hideSelectedOptions={hideSelectedOptions}
                isSearchable={isSearchable}
                {...props}
            />
            {error && <div className='error-message'>{error}</div>}
        </div>
    );
};

SSCSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
    ).isRequired,
    value: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.array,
            PropTypes.object,
        ]).isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string,
    selectStyle: PropTypes.object,
    mainStyle: PropTypes.object,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    width: PropTypes.string,
    menuPortalTarget: PropTypes.elementType,
    required: PropTypes.bool,
    isSearchable: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
};

SSCSelect.defaultProps = {
    placeholder: 'Select...',
    isDisabled: false,
    required: false,
    isClearable: false,
    isMulti: false,
    className: '',
    error: '',
    selectStyle: {},
    labelStyle: {},
    mainStyle: { width: '100%' },
    name: 'ssc-react-select',
    label: '',
    labelClass: '',
};

export default SSCSelect;
