import JSZip from 'jszip';
import { v4 as uuidV4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setLoaderProgress, setLoaderVisibility, setShowProgress } from '../redux';
import {
    createSafetyMeetingsService,
    signedSafetyServiceFile,
} from '../utils/apiServices/safetyServiceAPI';
import { renameFileWithUUID } from '../utils';
import { popup } from '../utils';
import { concurrencyQueue, getFileInfoFromFileName, uploadFileWithRetry } from './constants';
import { INDUSTRIES } from '../constants';

const useBulkUploadZip = () => {
    const dispatch = useDispatch();

    const bulkUploadZip = async (zipFiles = []) => {
        try {
            dispatch(setLoaderVisibility(true));
            dispatch(setShowProgress(true));

            const zip = new JSZip();
            const loadedZip = await zip.loadAsync(zipFiles);
            const files = Object.keys(loadedZip.files);

            let completed = 0;
            const filesDetails = [];

            const processFile = async (file) => {
                const fileName = file.split('.')[0];
                const fileUUID = uuidV4();
                const { type, language, isQuiz } = getFileInfoFromFileName(fileName);
                const fileArrayBuffer = await loadedZip.files[file].async('arraybuffer');

                // Get Presigned URL for a PDF and its s3 path
                const {
                    data: { preSignedUrl, filePath, contentType },
                } = await signedSafetyServiceFile({
                    signedData: {
                        file_name: renameFileWithUUID(file, fileUUID),
                        language,
                        type,
                    },
                });

                // Upload the same to S3 using presigned URL with retry
                await uploadFileWithRetry(fileArrayBuffer, preSignedUrl, contentType, file);

                filesDetails.push({
                    file_name: fileName,
                    language: language,
                    s3_key: filePath,
                    industries: isQuiz ? [type, INDUSTRIES.QUIZ] : [type],
                });

                completed = completed + 1;
                dispatch(setLoaderProgress((completed / files.length) * 100));
            };

            const tasks = files.map((file) => () => processFile(file));
            await concurrencyQueue(tasks);

            await createSafetyMeetingsService({ data: filesDetails });
        } catch (error) {
            popup('error', error.message);
            console.error('Error processing PDFs:', error);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    return {
        bulkUploadZip,
    };
};

export default useBulkUploadZip;
