import React, { useMemo } from 'react';
import { removeFromSelectedHiringClients, addToSelectedHiringClients } from '../../../redux';
import { useDispatch } from 'react-redux';
import { HIDDEN_HIRING_CLIENTS } from '../../../constants';
import { useSelector } from 'react-redux';

export const HideHiringClientAction = ({ id }) => {
    const dispatch = useDispatch();
    const selectedHiringClients = useSelector((s) => s.accountArray?.selectedHiringClients);
    const hasChecked = useMemo(
        () => selectedHiringClients?.includes(id),
        [id, selectedHiringClients],
    );

    return (
        <span
            className='d-flex ms-3 align-items-center'
            title={HIDDEN_HIRING_CLIENTS.UNHIDE}
            aria-label={HIDDEN_HIRING_CLIENTS.UNHIDE}
        >
            <input
                type='checkbox'
                id='select-hc'
                className='cursor-pointer'
                checked={hasChecked}
                onChange={(e) => {
                    if (e.target.checked) {
                        dispatch(addToSelectedHiringClients(id));
                    } else {
                        dispatch(removeFromSelectedHiringClients(id));
                    }
                }}
            />
        </span>
    );
};
