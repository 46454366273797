// In your accountsArraySlice.js file
import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE, REDUX, ROLE_NAME, ROLES } from '../../constants';
import { setLocalStorageItem } from '../../utils';

const initialState = {
    accounts: [],
    selectedAccount: '',
    selectedAccountName: '',
    isUserPrimary: false,
    selectedHiringClients: [],
    platformLength: 0,
};

const accountsArraySlice = createSlice({
    name: REDUX.slice.ACCOUNT_ARRAY,
    initialState,
    reducers: {
        setAccounts: (state, action) => {
            state.accounts = action.payload;
        },
        setSelectedAccounts: (state, action) => {
            setLocalStorageItem(LOCAL_STORAGE.CUSTOMER_ID, action.payload);

            const account = state.accounts.find((account) => account.id === action.payload);
            setLocalStorageItem(
                LOCAL_STORAGE.ROLE_NAME,
                account.is_primary_user ? ROLE_NAME.PRIMARY_USER : ROLE_NAME.SECONDARY_USER,
            );
            setLocalStorageItem(
                LOCAL_STORAGE.ROLE_CODE,
                account.is_primary_user ? ROLES.PRIMARY_USER : ROLES.SECONDARY_USER,
            );
            const accountName = state.accounts.find(
                (account) => account.id === action.payload,
            ).name;
            state.selectedAccountName = accountName;
            state.selectedAccount = action.payload;
            state.isUserPrimary = Boolean(account.is_primary_user);
            state.selectedHiringClients = [];
        },
        setSelectedAccountName: (state, action) => {
            const selectedAccount = state.accounts.find((account) => account.id === action.payload);
            state.selectedAccountName = selectedAccount.name;
        },
        setSelectedHiringClients: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.selectedHiringClients = action.payload;
                return;
            }
        },
        addToSelectedHiringClients: (state, action) => {
            if (typeof action.payload === 'string') {
                const newArray = state.selectedHiringClients || [];
                newArray.push(action.payload);
                state.selectedHiringClients = newArray;
            }
        },
        removeFromSelectedHiringClients: (state, action) => {
            if (typeof action.payload === 'string') {
                state.selectedHiringClients = state.selectedHiringClients?.filter(
                    (id) => id !== action.payload,
                );
            }
        },
        setPlatformLength: (state, action) => {
            state.platformLength = action.payload;
        },
    },
});

export const {
    setAccounts,
    setSelectedAccounts,
    setSelectedAccountName,
    setSelectedHiringClients,
    addToSelectedHiringClients,
    removeFromSelectedHiringClients,
    setPlatformLength,
} = accountsArraySlice.actions;
export default accountsArraySlice.reducer;
