// themeSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils';
import { LOCAL_STORAGE } from '../../constants';
import { REDUX } from '../../constants';

const initialState = {
    isCsmCaseload: !!getLocalStorageItem(LOCAL_STORAGE?.IS_CSM_CASELOAD), // Initialize from localStorage
    csmCaseloadData: JSON.parse(getLocalStorageItem(LOCAL_STORAGE?.CSM_CASELOAD_DATA)) || null, // Initialize from localStorage
};

const csmCaseloadSlice = createSlice({
    name: REDUX.slice.CSM_CASELOAD,
    initialState,
    reducers: {
        setCSMCaseload: (state, action) => {
            if (typeof action.payload?.isCsmCaseload !== 'boolean') {
                throw new Error('setCSMCaseload payload must be a boolean');
            }
            if (typeof action.payload?.csmCaseloadData !== 'object') {
                throw new Error('setCSMCaseload payload must be a object');
            }
            state.isCsmCaseload = action.payload?.isCsmCaseload;
            state.csmCaseloadData = action.payload?.csmCaseloadData;
            setLocalStorageItem(LOCAL_STORAGE.IS_CSM_CASELOAD, action.payload?.isCsmCaseload);
            setLocalStorageItem(
                LOCAL_STORAGE.CSM_CASELOAD_DATA,
                JSON.stringify(action.payload?.csmCaseloadData),
            );
        },
        clearCSMCaseload: (state) => {
            state.isCsmCaseload = false;
            state.csmCaseloadData = null;
            localStorage.removeItem(LOCAL_STORAGE.IS_CSM_CASELOAD);
            localStorage.removeItem(LOCAL_STORAGE.CSM_CASELOAD_DATA);
        },
    },
});

export const { setCSMCaseload, clearCSMCaseload } = csmCaseloadSlice.actions;
export default csmCaseloadSlice.reducer;
