import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { PaginationBar } from '../common/PaginationBar';
import { CUSTOMER_LIST, DOCUMENTS, INFO, ROLES, ROUTES } from '../../../constants';
import { CustomerDetailsTab } from '../../Admin';
import { DocumentDetailsTab } from '../DocumentDetailsTab';
import { handleScroll, sortingByName } from '../../../utils';
import { isEmpty } from 'lodash';
import useIsScrollable from '../../../hooks/useIsScrollable';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

export const Table = ({
    columns,
    data,
    pagination,
    pageName,
    debouncedApiCall,
    search,
    selectedTabData,
    tableStyle = {},
    stickyColumns = [],
    onDragEnd = () => {},
    isDraggable = false,
    tableClass = '',
}) => {
    const location = useLocation();
    const [noDataFound, setNoDataFound] = useState(false);
    const [tabData, setTabData] = useState('');
    const [tabOpen, setTabOpen] = useState(false);
    const [tabDocumentOpen, setDocumentTabOpen] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
    const [sort, setSort] = useState('DEFAULT');
    const [sortedData, setSortedData] = useState(data);
    const [isScrolling, setIsScrolling] = useState(false);
    const scrollBar = useRef(null);
    const tableContainerRef = useRef(null);
    const { isScrollableWidth } = useIsScrollable(tableContainerRef);
    const role = useSelector((s) => s.user.role);
    const showDetailTabs = ![ROLES.SALES_AGENT].includes(role);

    useEffect(() => {
        setTabOpen(false);
        if (data && data.length > 0) {
            setNoDataFound(false);
        } else {
            setNoDataFound(true);
        }
    }, [data]);

    useEffect(() => {
        setTabOpen(false);
        setDocumentTabOpen(false);
        setSelectedRowIndex(0);
    }, [pagination.currentPage, search]);

    const handleSort = (sortType, index) => {
        const dataFieldName = columns[index]?.dataField[0];
        if (sortType === 'ASC') {
            setSortedData(sortingByName({ data: data, sortBy: dataFieldName, order: 0 }));
            setSort(sortType);
        } else if (sortType === 'DSC') {
            setSortedData(sortingByName({ data: data, sortBy: dataFieldName, order: 1 }));
            setSort(sortType);
        }
    };

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    const handleTabOpen = (data) => {
        if (pageName === CUSTOMER_LIST.PAGE_NAME) {
            setTabData(data.id); // Data will be a ID in this case
            setTabOpen(true);
            setSelectedRowIndex(1);
        } else if (pageName === DOCUMENTS.PAGE_NAME) {
            setTabData(data); // Data will be an object in this case
            setDocumentTabOpen(true);
            setSelectedRowIndex(1);
        }
    };

    useEffect(() => {
        setSortedData(data);
    }, [data]);

    useEffect(() => {
        if (selectedTabData && selectedTabData !== '' && !isEmpty(selectedTabData)) {
            handleTabOpen(selectedTabData);
        }
    }, [selectedTabData]);
    return (
        <>
            {!noDataFound && <PaginationBar pageName={pageName} pagination={pagination} />}
            <section id='Table' className='position-relative'>
                <div
                    onScroll={handleScrolling}
                    onMouseMove={handleScrolling}
                    className={
                        'ssc-table-area w-100' +
                        (location.pathname === ROUTES.ADMIN.ADMIN_SCORE_CARD_LISTING
                            ? ' custom-table-height-sc'
                            : '') +
                        (isScrolling ? ' scrolling' : ' not-scrolling')
                    }
                    style={tableStyle}
                    ref={tableContainerRef}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='Table'>
                            {(provided) => (
                                <table
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={`w-100 + ${tableClass}`}
                                >
                                    <TableHeader
                                        handleSort={handleSort}
                                        sort={sort}
                                        columns={columns}
                                        data={sortedData}
                                        stickyColumns={stickyColumns}
                                        isScrollableWidth={isScrollableWidth}
                                    />
                                    <TableBody
                                        pageName={pageName}
                                        columns={columns}
                                        data={sortedData}
                                        setTabData={setTabData}
                                        setTabOpen={setTabOpen}
                                        debouncedApiCall={debouncedApiCall}
                                        setDocumentTabOpen={setDocumentTabOpen}
                                        selectedRowIndex={
                                            tabOpen || tabDocumentOpen ? selectedRowIndex : 0
                                        }
                                        setSelectedRowIndex={setSelectedRowIndex}
                                        stickyColumns={stickyColumns}
                                        isScrollableWidth={isScrollableWidth}
                                        isDraggable={isDraggable}
                                    />
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {noDataFound && (
                        <div
                            className='mt-4 vh-50 d-flex justify-content-center'
                            style={{
                                fontSize: '16px',
                            }}
                        >
                            {INFO.NO_DATA_AVAILABLE}
                        </div>
                    )}
                </div>
                {tabOpen && showDetailTabs && (
                    <CustomerDetailsTab
                        tabOpen={tabOpen}
                        setTabOpen={setTabOpen}
                        setSelectedRowIndex={setSelectedRowIndex}
                        tabData={tabData}
                        debouncedApiCallFromParent={debouncedApiCall}
                    />
                )}
                {tabDocumentOpen && showDetailTabs && (
                    <DocumentDetailsTab
                        setDocumentTabOpen={setDocumentTabOpen}
                        setSelectedRowIndex={setSelectedRowIndex}
                        tabData={tabData}
                        debouncedApiCallFromParent={debouncedApiCall}
                    />
                )}
            </section>
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
        dataLength: PropTypes.number.isRequired,
        dataCount: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        itemsPerPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        handlePrevPage: PropTypes.func.isRequired,
        handleNextPage: PropTypes.func.isRequired,
    }).isRequired,
    pageName: PropTypes.string.isRequired,
    debouncedApiCall: PropTypes.func,
    onDragEnd: PropTypes.func,
    search: PropTypes.string,
    selectedTabData: PropTypes.object,
    tableStyle: PropTypes.object,
};
