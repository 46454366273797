import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ASCSortIcon, DESCSortIcon, Swap } from '../../../assets/svg';

export const TableHeader = ({
    handleSort,
    columns,
    data,
    stickyColumns = [],
    isScrollableWidth,
}) => {
    const [sortStates, setSortStates] = useState({});

    const handleClick = (index) => {
        const currentSort = sortStates[index] || 'DEFAULT';
        let newSort;
        if (currentSort === 'DEFAULT') {
            newSort = 'ASC';
        } else if (currentSort === 'ASC') {
            newSort = 'DSC';
        } else {
            newSort = 'ASC';
        }

        setSortStates({
            ...Object.keys(sortStates).reduce((acc, key) => {
                acc[key] = 'DEFAULT';
                return acc;
            }, {}),
            [index]: newSort,
        });

        handleSort(newSort, index);
    };

    return (
        <thead>
            <tr>
                {columns.length > 0 &&
                    columns.map((column, index) => {
                        if (!column?.hideColumn)
                            return column?.sort ? (
                                <th
                                    key={index}
                                    className={`${column.classNameTH} ${isScrollableWidth && (stickyColumns.includes(column.name) || stickyColumns.includes(index)) ? ' sticky-th-left ' : ''}`}
                                >
                                    <span className='me-1'>
                                        {column.customHeader
                                            ? column.customHeader(data)
                                            : column.name}
                                    </span>
                                    {sortStates[index] === 'DEFAULT' || !sortStates[index] ? (
                                        <Swap handleClick={() => handleClick(index)} />
                                    ) : sortStates[index] === 'ASC' ? (
                                        <ASCSortIcon handleClick={() => handleClick(index)} />
                                    ) : (
                                        <DESCSortIcon handleClick={() => handleClick(index)} />
                                    )}
                                </th>
                            ) : (
                                <th
                                    key={index}
                                    className={`${column.classNameTH} ${isScrollableWidth && (stickyColumns.includes(column.name) || stickyColumns.includes(index)) ? ' sticky-th-left ' : ''}`}
                                >
                                    {column.customHeader ? column.customHeader(data) : column.name}
                                </th>
                            );
                    })}
            </tr>
        </thead>
    );
};

TableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    handleSort: PropTypes.func,
};
