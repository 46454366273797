import React from 'react';
import PropTypes from 'prop-types';

const FormInput = ({
    field,
    form: { touched, errors, setTouched, handleChange },
    label,
    placeholder,
    isFormFocused,
    maxLength,
    className = '',
    col = 6,
    width = '356px',
    isRequired = false,
    isDisabled = false,
}) => {
    return (
        <div
            className={`col-${col} input-set-area mb-2 ` + className}
            style={{ width: `${width}` }}
        >
            <label htmlFor={field.name}>{label}</label>
            <input
                maxLength={maxLength}
                type='text'
                name={field.name}
                value={field.value || ''}
                onChange={(e) => {
                    if (!touched[field.name]) {
                        setTouched({ ...touched, [field.name]: true });
                    }
                    handleChange(e);
                }}
                onBlur={field.onBlur}
                className={`form-control ${
                    isFormFocused && errors[field.name] && touched[field.name] && errors[field.name]
                        ? 'border border-danger-subtle border-1'
                        : ''
                }`}
                placeholder={placeholder}
                required={isRequired}
                disabled={isDisabled}
            />
            {isFormFocused && touched[field.name] && errors[field.name] && (
                <p className='error-message'>{errors[field.name]}</p>
            )}
        </div>
    );
};

FormInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    isFormFocused: PropTypes.bool,
    className: PropTypes.string,
    col: PropTypes.number,
    width: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default FormInput;
