import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setLoaderVisibility } from '../../../redux';
import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { SCORECARD_REPORT, SCORE_CARD_COLUMNS_ADMIN, ROUTES, ERROR } from '../../../constants';
import { Table } from '../../shared';
import { usePagination, useDebounce } from '../../../hooks';
import { getAllScoreCardDetails, popup } from '../../../utils';
import EditScorecardModal from './ScoreCardModal';
import { EmptyImg } from '../../../assets/svg';

export const ScoreCardReportAdmin = () => {
    const location = useLocation();
    const { state } = location;
    const dispatch = useDispatch();

    const [response, setResponse] = useState({ data: [], count: 0 });
    const [accountDetails] = useState(state?.accountUserDetails);
    const [search, setSearch] = useState('');
    const pagination = usePagination(response.data.length, response.count);
    const navigate = useNavigate();
    const [openEditModal, setOpenEditModal] = useState(false);

    const handleAddClick = () => {
        setOpenEditModal(true);
    };

    const platformDetails = {
        id: accountDetails?.platform_id,
    };

    const debouncedApiCall = useDebounce(() => {
        getAllScoreCardDetails({
            account_id: accountDetails?.id,
            offset: pagination.offset,
            limit: pagination.limit,
            search: search,
            selectedPlatform: platformDetails,
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res.status === 'error') {
                    if (res.statusCode === ERROR.INTERNAL_SERVER_ERROR) {
                        navigate(ROUTES.SERVER_ERROR);
                    }
                    popup('error', res.message);
                } else if (res?.data) setResponse({ data: res.data, count: res.count });
            })
            .catch((error) => {
                if (error.status === 'error' && error.statusCode === ERROR.INTERNAL_SERVER_ERROR) {
                    navigate(ROUTES.SERVER_ERROR);
                }
                dispatch(setLoaderVisibility(false));
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        return () => {
            setResponse({ data: [], count: 0 });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search]);

    return (
        <>
            <AdminPageHeader
                accountUserDetails={accountDetails}
                heading={SCORECARD_REPORT.PAGE_HEADING}
                buttonText={SCORECARD_REPORT.BUTTON_TEXT}
                searchBarPlaceholder={SCORECARD_REPORT.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                showAddButton
                adminScoreCard
                backbutton
                setResponse={setResponse}
                debouncedApiCall={debouncedApiCall}
                handleAddClick={handleAddClick}
                AddModal={
                    openEditModal ? (
                        <EditScorecardModal
                            accountDetails={accountDetails}
                            selectedPlatform={platformDetails}
                            openEditModal={openEditModal}
                            setOpenEditModal={setOpenEditModal}
                            isEdit={false}
                            setResponse={setResponse}
                            debouncedApiCall={debouncedApiCall}
                            scorecards={response?.data}
                        />
                    ) : (
                        ''
                    )
                }
                setCurrentPage={pagination.setCurrentPage}
                showGradeButton
            />

            {response.data.length ? (
                <Table
                    columns={SCORE_CARD_COLUMNS_ADMIN}
                    data={response.data}
                    pagination={pagination}
                    pageName={SCORECARD_REPORT.PAGE_NAME}
                    debouncedApiCall={debouncedApiCall}
                    stickyColumns={['Hiring Client']}
                />
            ) : (
                <div className='pt-5 pt-3 empty-set d-flex align-items-center justify-content-center flex-column'>
                    <EmptyImg />
                    <p>{SCORECARD_REPORT.NO_DATA_FOUND}</p>
                    <button
                        className='w-auto ssc-primary-green-btn'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal-add'
                        onClick={handleAddClick}
                    >
                        {SCORECARD_REPORT.BUTTON_TEXT}
                    </button>
                </div>
            )}
        </>
    );
};
