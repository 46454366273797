import {
    getHiringClients,
    getAllAccounts,
    updateScoreCardDetails,
    createScoreCardDetails,
    popup,
    deleteScoreCardDetails,
} from '../../../utils';
import { ERROR } from '../../../constants';

export const handleTPASelectFn =
    ({
        setSearchQuery = null,
        setTpaValue,
        setIsTPAOpen,
        setIsSaveClicked,
        setGrade,
        setSelectedTpa,
    }) =>
    (item) => {
        setIsSaveClicked && setIsSaveClicked(false);
        setSearchQuery && setSearchQuery('');
        setGrade && setGrade(item.grade);
        item.id !== 'all' && setTpaValue(item);
        setIsTPAOpen && setIsTPAOpen(false);
        item.id !== 'all' && setSelectedTpa(item);
    };
export const handleGradeSelectFn =
    ({ setSearchQuery = null, setGrade, setShowGradeDropDown }) =>
    (item) => {
        setSearchQuery && setSearchQuery('');
        item !== 'all' ? setGrade(item) : '';
        setShowGradeDropDown && setShowGradeDropDown(false);
    };

export const handleNotesChangeFn = (setNotes) => (e) => {
    setNotes(e.target.value);
};

export const getHiringClientsById = async ({
    account_id,
    platform_id,
    setHiringClients,
    searchQuery,
    scorecards,
}) => {
    try {
        const res = await getHiringClients({
            account_id: account_id,
            platform_id,
            search: searchQuery,
        });
        if (res.status === 'error') {
            popup('error', res.message);
        } else if (res?.data) {
            const ids = new Set(scorecards.map((item) => item.hiring_client_id));
            const filteredHC = res.data.filter((item) => {
                return !ids.has(item.id);
            });
            setHiringClients(filteredHC);
        }
    } catch (error) {
        console.error(error);
    }
};

export const getAllCustomerApi = async ({ setCustomers, searchQuery, id, role }) => {
    try {
        const res = await getAllAccounts({ search: searchQuery, id, role });
        if (res.status === 'error') {
            popup('error', res.message);
        } else if (res?.data) {
            setCustomers([]);
            res.data.map((item) => {
                item?.platforms.length && setCustomers((prevState) => [...prevState, item]);
            });
        }
    } catch (error) {
        console.error(error);
    }
};

const checkHiringClientIdExists = (scorecards, data) => {
    return scorecards.some((scorecard) => scorecard.hiring_client_id === data.hiring_client_id);
};

export const handleSaveFn = async ({
    isEdit,
    selectedPlatform,
    selectedHiringClient,
    notes,
    selectedData,
    accountId,
    scorecards,
    selectedHiringClientGrade,
}) => {
    try {
        if (selectedHiringClient.id) {
            if (isEdit) {
                const data = {
                    ...(selectedHiringClient.id && { hiring_client_id: selectedHiringClient.id }),
                    id: selectedData.id,
                    platform_id: selectedData?.platform_id,
                    account_id: accountId,
                    ...(notes && { notes }),
                    hiring_client_grade: selectedHiringClientGrade
                        ? selectedHiringClientGrade
                        : null,
                };

                const res = await updateScoreCardDetails({ updatedData: data });
                if (res.status === 'success') {
                    return { success: true, type: 'update' };
                }
                popup('error', res.message);
                return { success: false };
            } else {
                const data = {
                    ...(selectedHiringClient?.id && { hiring_client_id: selectedHiringClient.id }),
                    platform_id: selectedPlatform?.id,
                    account_id: accountId,
                    ...(notes && { notes }),
                    hiring_client_grade: selectedHiringClientGrade
                        ? selectedHiringClientGrade
                        : null,
                };

                const isHiringClientExists = checkHiringClientIdExists(scorecards, data);

                if (!isHiringClientExists) {
                    const res = await createScoreCardDetails({ data });

                    if (res.status === 'success') {
                        return {
                            success: true,
                            type: 'create',
                        };
                    }

                    popup('error', res.message);
                    return { success: false };
                } else {
                    popup('error', ERROR.HIRING_CLIENT_ALREADY_EXISTS);
                    return { sucess: false };
                }
            }
        } else {
            popup('error', ERROR.NO_HIRING_CLIENT_SELECTED);
            return { sucess: false };
        }
    } catch (error) {
        console.error(error);
        popup('error', error.message);
        return { success: false };
    }
};
export const handleDeleteFn = async (id) => {
    const res = await deleteScoreCardDetails({ id: id });
    if (res.status === 'success') {
        return true;
    }
    popup('error', res.message);
    return false;
};
