import { INDUSTRIES, LANGUAGES } from '../constants/selectOptions';

export const MAX_RETRIES = 3;
export const CONCURRENCY_LIMIT = 5;

const LANGUAGE_MAP = {
    cc: LANGUAGES.CANADA,
    cr: LANGUAGES.CANADA,
    cm: LANGUAGES.CANADA,
    sc: LANGUAGES.SPANISH,
    sr: LANGUAGES.SPANISH,
    sm: LANGUAGES.SPANISH,
};

const TYPE_MAP = {
    c: INDUSTRIES.CONSTRUCTION,
    m: INDUSTRIES.MANUFACTURING,
    r: INDUSTRIES.RESTAURANT,
};

export const concurrencyQueue = async (tasks, limit = CONCURRENCY_LIMIT) => {
    const queue = [];
    for (const task of tasks) {
        const promise = task().finally(() => {
            queue.splice(queue.indexOf(promise), 1);
        });
        queue.push(promise);
        if (queue.length >= limit) {
            await Promise.race(queue);
        }
    }
    await Promise.all(queue);
};

export const getFileInfoFromFileName = (fileName) => {
    if (!fileName) return { type: INDUSTRIES.OTHER, language: LANGUAGES.ENGLISH, isQuiz: false };
    const lowerCaseName = fileName.toLowerCase();
    const letter = isNaN(parseInt(lowerCaseName[1])) ? lowerCaseName[1] : lowerCaseName[0];
    const firstTwoLetters = lowerCaseName.slice(0, 2);
    const lastTwoLettersOfCode = lowerCaseName.slice(4, 6);
    return {
        type: TYPE_MAP[letter] || INDUSTRIES.OTHER,
        language: LANGUAGE_MAP[firstTwoLetters] || LANGUAGES.ENGLISH,
        isQuiz:
            (isNaN(parseInt(lastTwoLettersOfCode[0])) && lastTwoLettersOfCode[0] === 'q') ||
            (!isNaN(parseInt(lastTwoLettersOfCode[0])) && lastTwoLettersOfCode[1] === 'q'),
    };
};

export const downloadFileWithRetry = async (url, retries = MAX_RETRIES) => {
    while (retries > 0) {
        try {
            const response = await fetch(url);
            if (response.status === 200) return await response.arrayBuffer();
            throw new Error(`Fetch failed with status ${response.status}`);
        } catch (error) {
            retries -= 1;
            if (retries === 0) throw error;
        }
    }
};

export const uploadFileWithRetry = async (
    fileArrayBuffer,
    preSignedUrl,
    contentType,
    file,
    retries = MAX_RETRIES,
) => {
    while (retries > 0) {
        try {
            const response = await fetch(preSignedUrl, {
                method: 'PUT',
                body: fileArrayBuffer,
                headers: {
                    'Content-Type': contentType,
                },
            });
            if (response.status === 200) return true;
            throw new Error(`Upload failed for ${file} with code ${response.status}`);
        } catch (error) {
            retries -= 1;
            if (retries === 0) throw error;
        }
    }
};
