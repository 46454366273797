import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useDebounce, usePagination } from '../../../hooks';
import { getAllNewsFeed, popup } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';
import { CUSTOMER_NEWS_FEED_COLUMNS } from '../../../constants';
import { AdminPageHeader } from '../../Admin';
import { isEmpty } from 'lodash';
import EmptyImage from '../../../assets/img/icons/empty-img-doc.svg';
import { Table } from '../../shared';

export const AllNewsFeedCustomer = () => {
    const accountId = useSelector((state) => state.accountArray.selectedAccount);
    const selectedAccountName = useSelector((state) => state.accountArray.selectedAccountName);
    const dispatch = useDispatch();
    const [search, setSearch] = useState(null);
    const [response, setResponse] = useState({ data: [], count: 0 });
    const pagination = usePagination(response.data.length, response.count);

    const debouncedApiCall = useDebounce(() => {
        getAllNewsFeed({
            account_id: accountId,
            limit: pagination.limit,
            offset: pagination.offset,
            search,
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) {
                    setResponse({ data: res.data, count: res.count });
                }
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, pagination.limit, pagination.offset, search]);

    return (
        <>
            <AdminPageHeader
                heading={'All Activities'}
                backbutton
                showSearchBar={true}
                searchBarPlaceholder={'Search feed'}
                setSearch={setSearch}
                count={pagination.dataCount}
            />
            <div className='ssc-newsfeed-box'>
                <div className='p-3 name-box-set d-flex align-items-center justify-content-start'>
                    <div>
                        <h6 className='name m-0'>{selectedAccountName}</h6>
                    </div>
                </div>
            </div>
            {!isEmpty(response?.data) ? (
                <div className='mt-3'>
                    <Table
                        columns={CUSTOMER_NEWS_FEED_COLUMNS}
                        data={response?.data}
                        pagination={pagination}
                        pageName={'Feeds'}
                        stickyColumns={['News Feed']}
                        tableStyle={{
                            height: 'calc(100vh - 400px)',
                            overflow: 'auto',
                        }}
                    />
                </div>
            ) : (
                <div className='d-flex flex-column w-100 h-75 justify-content-center align-items-center gap-3'>
                    <img src={EmptyImage} alt='img' width={176} height={138} />
                    <p>There are no news feed for this client</p>
                </div>
            )}
        </>
    );
};
