import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AdminPageHeader } from '../../Admin/Common/AdminPagesHeader';
import { setLoaderVisibility } from '../../../redux';
import { DOC_CUSTOMER, DOC_CUSTOMER_COLUMNS } from '../../../constants';
import { getAllDocumentRequestByAccount, popup } from '../../../utils';
import { usePagination, useDebounce } from '../../../hooks';
import { Table } from '../../shared';

export const DocumentListing = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [response, setResponse] = useState({ data: [], count: 0 });
    const [filter, setFilter] = useState({ priority: '', status: '' });
    const [selectedId, setSelectedId] = useState({
        priorityId: '1',
        statusId: '1',
    });
    const [search, setSearch] = useState('');

    const accountId = useSelector((state) => state.accountArray.selectedAccount);
    const pagination = usePagination(response.data.length, response.count);
    const [hiringClientId] = useState(location?.state?.hiringClientId);
    const requestID = location?.state?.requestID;

    const filterConfig = [
        {
            name: 'priorityFilter',
            value: filter.priority,
            selectedId: selectedId.priorityId,
            handleFunction: (value, id) => {
                setFilter({ ...filter, priority: value });
                setSelectedId({ ...selectedId, priorityId: id });
            },
            options: [
                { id: '1', name: 'All Priorities', value: '' },
                { id: '2', name: 'High', value: 'high' },
                { id: '3', name: 'Low', value: 'low' },
            ],
        },
        {
            name: 'statusFilter',
            value: filter.status,
            selectedId: selectedId.statusId,
            handleFunction: (value, id) => {
                setFilter({ ...filter, status: value });
                setSelectedId({ ...selectedId, statusId: id });
            },
            options: [
                { id: '1', name: 'All Statuses', value: '' },
                { id: '2', name: 'Approved', value: 'approved' },
                { id: '3', name: 'Rejected', value: 'rejected' },
                { id: '4', name: 'Pending', value: 'pending' },
                { id: '5', name: 'Requested', value: 'requested' },
            ],
        },
    ];

    // TODO: Replace testData with API data
    const debouncedApiCall = useDebounce(() => {
        getAllDocumentRequestByAccount({
            hiring_client_id: hiringClientId,
            account_id: accountId,
            offset: pagination.offset,
            limit: pagination.limit,
            search,
            filter: requestID ? { id: requestID } : filter,
        })
            .then((res) => {
                if (res?.data && res?.count !== undefined)
                    setResponse({ data: res.data, count: res.count });
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                popup('error', error.message);
                dispatch(setLoaderVisibility(false));
            });
    }, 500);

    useEffect(() => {
        if (accountId) {
            !search && dispatch(setLoaderVisibility(true));
            debouncedApiCall();
        }

        if (location.state) {
            // setHiringClientId(location.state.hiringClientId); //TODO
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset, search, accountId, filter, location.state]);

    return (
        <>
            <AdminPageHeader
                heading={DOC_CUSTOMER.PAGE_HEADING}
                searchBarPlaceholder={DOC_CUSTOMER.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                filterConfig={filterConfig}
                backbutton
            />
            <Table
                columns={DOC_CUSTOMER_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={DOC_CUSTOMER.PAGE_HEADING}
                selectedTabData={requestID ? response.data[0] : {}}
                stickyColumns={['Documents']}
            />
        </>
    );
};
