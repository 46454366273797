import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { REGULATORY_ISSUE_COLUMNS, REGULATORY_ISSUE, ROLES } from '../../../constants';
import { RegulatoryAddEdit, Table } from '../../shared';
import { useDebounce, usePagination } from '../../../hooks';
import { addNewRegulatoryIssues, getAllRegulatoryIssues, popup } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';
import { useSelector } from 'react-redux';

export const RegulatoryIssue = () => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({ data: [], count: 0 });
    const pagination = usePagination(response.data.length, response.count);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const role = useSelector((s) => s.user.role);

    const handleAddClick = async ({ broadcast, regulatoryIssue }) => {
        try {
            const body = {
                broadcast,
                title: regulatoryIssue.title,
                content: regulatoryIssue.content,
            };
            dispatch(setLoaderVisibility(true));
            await addNewRegulatoryIssues({ body });
            setIsAddModalOpen(false);
            debouncedApiCall();
            popup('success', REGULATORY_ISSUE.CREATED_MESSAGE);
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    const debouncedApiCall = useDebounce(async () => {
        try {
            const res = await getAllRegulatoryIssues({
                limit: pagination.limit,
                offset: pagination.offset,
            });
            if (res?.data) setResponse({ data: res.data, count: res.count });
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    }, 500);

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.limit, pagination.offset]);
    return (
        <>
            <AdminPageHeader
                showAddButton={ROLES.SALES_AGENT !== role}
                heading={REGULATORY_ISSUE.PAGE_HEADING}
                buttonText={REGULATORY_ISSUE.ADD_BUTTON_TEXT}
                handleAddClick={() => setIsAddModalOpen(true)}
                backbutton
            />

            <Table
                columns={
                    ROLES.SALES_AGENT !== role
                        ? REGULATORY_ISSUE_COLUMNS
                        : REGULATORY_ISSUE_COLUMNS.filter((column) => column.name !== 'Action')
                }
                data={response.data}
                pagination={pagination}
                pageName={REGULATORY_ISSUE.PAGE_HEADING}
                debouncedApiCall={debouncedApiCall}
                stickyColumns={['Regulatory Issue']}
            />

            {isAddModalOpen && (
                <RegulatoryAddEdit
                    isModalOpen={isAddModalOpen}
                    setIsModalOpen={setIsAddModalOpen}
                    debouncedApiCall={debouncedApiCall}
                    handleEditClick={handleAddClick}
                    modalType='add'
                />
            )}
        </>
    );
};
