import React, { useEffect, useState } from 'react';
import DocViewer, { BMPRenderer, DocViewerRenderers, GIFRenderer } from '@cyntler/react-doc-viewer';
import { useDispatch, useSelector } from 'react-redux';

import { setLoaderVisibility, setFileViewModalDetails } from '../../../redux';
import { popup } from '../../../utils';
import { FILE_VIEWER } from '../../../constants';
import { Modal } from 'react-bootstrap';

export const FileViewerModal = () => {
    // declarations

    const dispatch = useDispatch();
    const signedURL = useSelector((state) => state.fileViewerModal.signedURL);
    const docs = useSelector((state) => state.fileViewerModal.docs);
    const openFileViewerModal = useSelector((state) => state.fileViewerModal.openFileViewerModal);
    const fileName = useSelector((state) => state.fileViewerModal.fileName);
    const [blobURL, setBlobURL] = useState('');
    const [extn, setExtn] = useState('');
    const [googleViewerURL, setGoogleViewerURL] = useState('');
    const [officeViewerURL, setOfficeViewerURL] = useState('');

    // functions

    const fetchWithRetry = async (url, retries = 3) => {
        for (let i = 0; i < retries; i++) {
            try {
                const response = await fetch(url);
                if (response.ok) {
                    return await response.blob();
                } else if (!response.ok && response.status === 404) {
                    throw new Error('File Not Found');
                } else {
                    throw new Error('Something went wrong while fetching the file');
                }
            } catch (error) {
                if (i === retries - 1) {
                    console.error(`Failed to fetch after ${retries} attempts`, error);
                    throw new Error(error?.message || error); // Propagate the error
                }
            }
        }
    };

    const getBlobFromSignedUrl = async (signedUrl) => {
        dispatch(setLoaderVisibility(true));
        try {
            const blob = await fetchWithRetry(signedUrl);
            const blobURI = URL.createObjectURL(blob);
            return blobURI;
        } catch (error) {
            console.error(FILE_VIEWER.FILE_FETCH_ERROR, error);
            throw new Error(error?.message || error);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const getFileExtension = (filename) => {
        const match = filename.match(/\.([^.?]+)(?:\?|$)/);
        return match ? match[1].toLowerCase() : '';
    };

    const getRendererForFileType = (extension) => {
        switch (extension) {
            case 'bmp':
                return BMPRenderer;
            case 'gif':
                return GIFRenderer;
            default:
                return null;
        }
    };

    const renderViewer = () => {
        // for -> 'mp4', 'gif', 'png', 'bmp', 'html', 'jpg', 'jpeg'
        const Renderer = getRendererForFileType(extn);
        const config = {
            header: {
                disableHeader: true,
            },
        };
        if (blobURL) {
            return (
                <>
                    {Renderer ? (
                        <DocViewer
                            documents={[{ uri: blobURL }]}
                            pluginRenderers={[Renderer]}
                            config={config}
                            className='file-viewer-doc-height'
                        />
                    ) : (
                        <DocViewer // pluginRenders are not required for these file formats -> for html, jpeg, jpg, mp4, png
                            documents={[{ uri: blobURL }]}
                            config={config}
                            className='file-viewer-doc-height'
                        />
                    )}
                </>
            );
        } else if (googleViewerURL || officeViewerURL) {
            const officeRenderURL =
                FILE_VIEWER.OFFICE_VIEWER_URL + encodeURIComponent(officeViewerURL); // for -> 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'
            const googleRenderURL =
                FILE_VIEWER.GOOGLE_VIEWER_URL +
                encodeURIComponent(googleViewerURL) +
                FILE_VIEWER.EMBEDED; // for -> 'tif', 'tiff', 'csv', 'pdf', 'txt'

            const renderURL = googleViewerURL ? googleRenderURL : officeRenderURL;

            return (
                <>
                    <object data={renderURL} className='external-viewer-url' />
                </>
            );
        } else if (docs?.length > 0) {
            const fileType = docs[0]?.fileType;
            if (
                fileType.startsWith('image/') ||
                fileType.startsWith('video/') ||
                fileType.startsWith('text/') ||
                fileType === 'application/vnd.ms-excel' ||
                fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                fileType === 'application/msword' ||
                fileType ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ) {
                return (
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={docs}
                        config={config}
                        className='file-viewer-doc-height'
                    />
                );
            } else if (fileType === 'application/pdf') {
                return <iframe src={docs[0].uri} width='100%' height='90%' />;
            } else {
                return <p>Cannot preview this file type. Download to view.</p>;
            }
        } else {
            return <div>{FILE_VIEWER.LOADING}</div>;
        }
    };

    // Initial state
    const processSignedURL = async () => {
        const extension = getFileExtension(signedURL);
        setExtn(extension);

        try {
            if (FILE_VIEWER.AVAILABLE_DOC_VIEWER_EXTNS.includes(extension)) {
                const blobURI = await getBlobFromSignedUrl(signedURL);
                setBlobURL('blobURI', blobURI);
            } else if (FILE_VIEWER.AVAILABLE_GOOGLE_VIEWER_EXTNS.includes(extension)) {
                setGoogleViewerURL(signedURL);
            } else {
                setOfficeViewerURL(signedURL);
            }
        } catch (error) {
            dispatch(
                setFileViewModalDetails({
                    openFileViewerModal: false,
                    signedURL: '',
                    fileName: '',
                }),
            );
            console.error(`Error processing signed URL: ${error.message}`);
            popup('error', error?.message || FILE_VIEWER.FILE_FETCH_ERROR);
        }
    };

    useEffect(() => {
        if (signedURL) processSignedURL();
        return () => {
            URL.revokeObjectURL(blobURL);
            setBlobURL('');
            setExtn('');
            setGoogleViewerURL('');
            setOfficeViewerURL('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blobURL, signedURL]);

    return (
        <Modal
            show={openFileViewerModal}
            onHide={() => {
                dispatch(
                    setFileViewModalDetails({
                        openFileViewerModal: false,
                        signedURL: '',
                        fileName: '',
                    }),
                );
                URL.revokeObjectURL(blobURL);
                setBlobURL('');
                setExtn('');
                setGoogleViewerURL('');
                setOfficeViewerURL('');
            }}
            size='lg'
            dialogClassName=''
            fullscreen='lg-down'
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px' }} className='me-2 text-'>
                    {fileName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='file-viewer-height-75vh'>{renderViewer()}</div>
            </Modal.Body>
        </Modal>
    );
};
